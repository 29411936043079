import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography } from "@mui/material";

import api from "../../../api/api";

//Components
import UserListComponent from "./UserListComponent";
import Spinner from "../../layout/Spinner";

const ProfileFollowingModal = ({ userid, auth: { user, isAuthenticated }, handleClose = () => {} }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [following, setFollowing] = useState([]);

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        const res = await api.get(`/profile/following/${userid}`);
        setFollowing(res.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    fetchFollowing();
  }, []);

  const empty = (
    <div className="flex justify-center items-center min-h-[5rem]">
      <Typography variant="h6" className="text-secondaryText">
        No following
      </Typography>
    </div>
  );

  return (
    <div className="w-full h-full">
      {loading ? (
        <Spinner />
      ) : error ? (
        <Typography>Error...</Typography>
      ) : following && following.length > 0 ? (
        following.map((follower) => (
          <UserListComponent
            key={follower.id}
            user={follower}
            showFollow={isAuthenticated && follower.id.toString() !== user._id.toString() ? true : false}
            handleModalClose={handleClose}
          />
        ))
      ) : (
        empty
      )}
    </div>
  );
};
ProfileFollowingModal.propTypes = {
  userid: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileFollowingModal);
