import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import csv from 'csvtojson';
import Webcam from 'react-webcam';

import Button from '@mui/material/Button';
import { Container, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Spinner from '../layout/Spinner';

import { createWorker } from 'tesseract.js';

const videoConstraints = {
    width: 400,
    height: 600,
    facingMode: "user"
  };
  

const Test = ({}) => {
    let worker;
    const [items, setItems] = useState([]);
    const [imgstr, setImgstr] = useState(null);
    const [imageUpload, setImageUpload] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [showWebcam, setShowWebcam] = useState(false);
    const initFunc = async () => {
        worker = await createWorker();
        await worker.loadLanguage('eng');
        await worker.initialize('eng');
    }
    
    const tessCleanup = async () => {
        await worker.terminate();
    }
    
    const imageClick = () => {

    }
     const tessOCR = async (imagestring) => {
        setLoading(true);
        console.log(imagestring)
        const { data: { text } } = await worker.recognize(imagestring);
        console.log(text);
        const res = await axios.post('http://localhost:5000/api/demo/receipt', {ocr: text});
        
        let itemsArr = [];
        let csvString = res.data.choices[0].message.content;
        
        const lines = csvString.split('\n');
const headers = lines[0].split(',');
const jsonArray = [];

for (let i = 1; i < lines.length; i++) {
  const obj = {};
  const currentLine = lines[i].split(',');

  for (let j = 0; j < headers.length; j++) {
    obj[headers[j]] = currentLine[j];
  }

  jsonArray.push(obj);
}

const jsonString = JSON.stringify(jsonArray);

console.log(jsonArray);
console.log(jsonString);

setItems(jsonArray);
        //Display in table
        setLoading(false);
        //setItems(itemsArr[0]);
        setLoaded(true);
        //Take image
        //Upload image
     }
    
     useEffect(() => {
        initFunc();
     }, []);

     const onclick = () => {
        tessOCR();
     }

     const buildHeader = () => {
        const headerItems = items[0];
        console.log(items);
        console.log(items[0]);
        console.log(headerItems);
        return (
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Product
                    </TableCell>
                    <TableCell>
                        Brand
                    </TableCell>
                    <TableCell>
                        Price
                    </TableCell>
                    <TableCell>
                        Estimated Expiry
                    </TableCell>
                    <TableCell>
                        Storage
                    </TableCell>
                    </TableRow>
            </TableHead>
        )
     }

     /*

            <Button onClick={() => setShowWebcam(true)}>Take Image</Button>
{setShowWebcam && (
            <Webcam
            audio={false}
            height={720}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <button
                onClick={() => {
                  const imageSrc = getScreenshot()
                  console.log(imageSrc);
                  tessOCR(imageSrc);
                }}
                style={{position: 'absolute', left: 0}}
              >
                Capture photo
              </button>
            )}
          </Webcam>
        )}
     */
    return ( 
        <div>
            <Button>Upload Receipt</Button>
            {imageUpload && (
        <div>
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(imageUpload)}
          />
          <br />
          <button onClick={() => setImageUpload(null)}>Remove</button>
        </div>
      )}

<input
        type="file"
        name="myImage"
        onChange={(event) => {
          console.log(event.target.files[0]);
          setImageUpload(event.target.files[0]);
          tessOCR(event.target.files[0]);
        }}
      />
                {loading && <Spinner />}
                {loaded && items && (
                    <Table>
                        {buildHeader()}
                        <TableBody>
                            {items.map((item, i) => {
                                if(item['Product']) {
                                    return (
                                        <TableRow>
                                            <TableCell>{item['Name']}</TableCell>
                                            <TableCell>{item['Product']}</TableCell>
                                            <TableCell>{item['Brand']}</TableCell>
                                            <TableCell>{item['Price']}</TableCell>
                                            <TableCell>{item['Expiry']}</TableCell>
                                            <TableCell>{item['Storage']}</TableCell>
                                        </TableRow>
                                    )
                                }
                                    
                            })}
                        </TableBody>
                    </Table>
                    
                )}
            <div>

            </div>
        </div>
    )
}

export default Test;