import api from "../api/api";

import store, { history } from "../store";
import { setAlert } from "./alert";

//Redux types
import {
  IMAGE_UPLOAD_ADD,
  IMAGE_UPLOAD_REMOVE,
  IMAGE_UPLOAD_ERROR,
  IMAGE_UPLOAD_RESET,
  IMAGE_UPLOAD_CROP,
  IMAGE_UPLOAD_OPEN_CROP,
  IMAGE_UPLOAD_CLOSE_CROP,
  IMAGE_UPLOAD_CROP_ERROR,
  IMAGE_UPLOAD_REORDER,
} from "./types";

export const addImageToUpload = (image) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_ADD,
    payload: image,
  });
};

export const removeImageFromUpload = (index) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_REMOVE,
    payload: index,
  });
  dispatch({
    type: IMAGE_UPLOAD_CLOSE_CROP,
  });
  dispatch(setAlert("Image removed", "success"));
};

export const cropImageInUpload = (index, croppedImageData) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_CROP,
    payload: { index, croppedImageData },
  });
};

export const reorderImagesInUpload = (images) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_REORDER,
    payload: images,
  });
};

export const imageUploadReset = () => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_RESET,
  });
};

export const openImageCropModal = (index) => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_OPEN_CROP,
    payload: index,
  });
};

export const closeImageCropModal = () => (dispatch) => {
  dispatch({
    type: IMAGE_UPLOAD_CLOSE_CROP,
  });
};

export const uploadImages = (images) => async (dispatch, getState) => {
  try {
  } catch (error) {}
};

const uploadImageToS3 = (file) => async (dispatch, getState) => {
  try {
    // Request a signed URL from your backend
    const signedUrlResponse = await fetch("/api/get-signed-url", {
      method: "POST",
      body: JSON.stringify({ filename: file.name, filetype: file.type }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { url, filePath } = await signedUrlResponse.json();

    // Use the signed URL to upload the file to S3
    const s3UploadResponse = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (s3UploadResponse.ok) {
      // Notify your backend about the successful upload if necessary
      // Update Redux state to reflect the upload success
      dispatch({ type: "UPLOAD_SUCCESS", payload: filePath });
    } else {
      dispatch({ type: "UPLOAD_FAILURE", error: "Failed to upload to S3" });
    }
  } catch (error) {
    dispatch({ type: "UPLOAD_FAILURE", error: error.message });
  }
};
