//Utility functions for recipes

//Convert ingredient quantity (decimal) to fractional string
export const decimalToFraction = (decimal) => {
  if (typeof decimal === "string" && /^\d{1,3}\/\d{1,3}$/.test(decimal)) {
    return decimal;
  }

  decimal = Number(decimal);
  if (isNaN(decimal)) {
    return "1";
  }
  const tolerance = 1.0e-6; // Tolerance level for approximation
  let bestNumerator = 1;
  let bestDenominator = 1;
  let minError = Math.abs(decimal - bestNumerator / bestDenominator);

  for (let denominator = 1; denominator <= 100; ++denominator) {
    for (let numerator = 1; numerator <= denominator; ++numerator) {
      let error = Math.abs(decimal - numerator / denominator);
      if (error < minError) {
        minError = error;
        bestNumerator = numerator;
        bestDenominator = denominator;
      }
    }
  }

  if (minError < tolerance) {
    if (bestNumerator === bestDenominator) {
      return bestNumerator.toString();
    } else {
      return bestNumerator + "/" + bestDenominator;
    }
  } else {
    // For decimals that are common repeating fractions in recipes
    const commonRepeatingFractions = {
      0.333: "1/3",
      0.666: "2/3",
      0.166: "1/6",
      0.833: "5/6",
      // Add more mappings as needed
    };
    return commonRepeatingFractions[decimal.toFixed(3)] || decimal.toString();
  }
};

export const convertToTitleCase = (str) => {
  var i, j, lowers, uppers;
  str = str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv", "Bbq"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};
