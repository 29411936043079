import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//UI
import { Typography, Grid, Paper, Button } from "@mui/material";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../ui/card";

//Components
import ItemsList from "./ItemsList";

//Actions
import { getMyIngredients, resetAddPantry } from "../../actions/pantry";

//Modals
import CustomModal from "../modal/CustomModal";
import Initial from "./modals/add_items/Initial";

const PantryHome = ({ pantry: { items }, getMyIngredients, resetAddPantry }) => {
  //Get pantry items
  useEffect(() => {
    getMyIngredients();
  }, []);

  //Modal State
  const [showAddItem, setShowAddItem] = useState(false);

  const handleExit = () => {
    setShowAddItem(false);
    resetAddPantry();
  };

  return (
    <div>
      <CustomModal open={showAddItem} handleClose={() => handleExit()}>
        <Initial />
      </CustomModal>
      <div className="py-[4rem] md:py-0 md:pl-[5rem]">
        <div className="p-4 md:p-8">
          <div className="flex justify-between items-end">
            <div>
              <Typography variant="h5">My Ingredients</Typography>
              <div className="flex flex-row gap-2 mt-2">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => setShowAddItem(true)}
                  size="small"
                >
                  + Add Items
                </Button>
                <Link to="/cookbook">
                  <Button variant="outlined" disableElevation size="small">
                    Recipes
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 mt-8 lg:gap-4 xl:gap-8">
            <div className="md:border md:border-separator md:rounded-lg md:p-4 md:shadow-sm">
              <div>
                <Typography variant="h6" className="font-normal">
                  Refrigerator
                </Typography>

                <Typography variant="subtitle1" className="text-secondaryText leading-none">
                  $241.32 in items
                </Typography>
                <Typography variant="subtitle2" className="text-mainGreen font-normal">
                  1 item expiring today
                </Typography>
              </div>

              <div>
                <ItemsList location={"fridge"} />
              </div>
            </div>

            <div className="md:border md:border-separator md:rounded-lg md:p-4 md:shadow-sm">
              <div>
                <Typography variant="h6" className="font-normal">
                  Pantry
                </Typography>

                <Typography variant="subtitle1" className="text-secondaryText leading-none">
                  $241.32 in items
                </Typography>
                <Typography variant="subtitle2" className="text-mainGreen font-normal">
                  1 item expiring today
                </Typography>
              </div>

              <div>
                <ItemsList location={"pantry"} />
              </div>
            </div>

            <div className="md:border md:border-separator md:rounded-lg md:p-4 md:shadow-sm">
              <div>
                <Typography variant="h6" className="font-normal">
                  Freezer
                </Typography>

                <Typography variant="subtitle1" className="text-secondaryText leading-none">
                  $241.32 in items
                </Typography>
                <Typography variant="subtitle2" className="text-mainGreen font-normal">
                  1 item expiring today
                </Typography>
              </div>

              <div>
                <ItemsList location={"freezer"} />
              </div>
            </div>

            <div className="md:border md:border-separator md:rounded-lg md:p-4 md:shadow-sm">
              <div>
                <Typography variant="h6" className="font-normal">
                  Spice Rack
                </Typography>

                <Typography variant="subtitle1" className="text-secondaryText leading-none">
                  $241.32 in items
                </Typography>
                <Typography variant="subtitle2" className="text-mainGreen font-normal">
                  1 item expiring today
                </Typography>
              </div>

              <div>
                <ItemsList location={"spicerack"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PantryHome.propTypes = {
  pantry: PropTypes.object.isRequired,
  getMyIngredients: PropTypes.func.isRequired,
  resetAddPantry: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps, { getMyIngredients, resetAddPantry })(PantryHome);
