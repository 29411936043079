import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

//API
import api from "../../../../../api/api";

//Actions
import { setAlert } from "../../../../../actions/alert";

//Icons
import { ReactComponent as ReportIcon } from "../../../../../assets/icons/svg/report.svg";
import { ReactComponent as TrashIcon } from "../../../../../assets/icons/svg/trash.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/svg/edit.svg";
import { ReactComponent as ShareIcon } from "../../../../../assets/icons/svg/share.svg";
import { Button, Typography } from "@mui/material";

const RecipeOptionsModal = ({ recipe, auth: { user, isAuthenticated }, setAlert, handleClose }) => {
  const navigate = useNavigate();
  const isMyRecipe = isAuthenticated ? recipe.user.id === user._id : false;

  const [deleteAYS, setDeleteAYS] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  //TO DO:
  //Add copy link to both options

  const handleReport = () => {
    //TO DO: Report post
    setAlert("Recipe reported", "success");
    handleClose();
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await api.delete(`/recipes/${recipe._id}`);
      setAlert("Recipe deleted", "success");
      setDeleteLoading(false);
      navigate("/");
      handleClose();
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
      setDeleteAYS(false);
      setAlert("Error deleting comment, please refresh and try again.", "error");
    }
  };

  const handleCopyLink = () => {
    const recipeLink = `/${recipe.user.username}/${recipe && recipe.url ? recipe.url : "unknown-recipe"}`;
    navigator.clipboard.writeText(window.location.origin + recipeLink);
    setAlert("Recipe link copied to clipboard", "success");
    handleClose();
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        //Format share data
        await navigator.share({
          title: `${recipe.name} by @${recipe.user.username}`,
          text: `${recipe.name} by @${recipe.user.username}`,
          url: `${window.location.origin}/${recipe.user.username}/${recipe.url}`,
        });
      } catch (error) {
        handleCopyLink();
      }
    } else {
      handleCopyLink();
    }
  };

  const myRecipe = (
    <div className="w-full border-t border-separator">
      <Link to={`/create/recipe/${recipe._id}`}>
        <div className="w-full border-t border-separator">
          <Button
            className=" w-full h-16 rounded-none justify-start pl-8"
            startIcon={
              <div className="w-8 flex justify-start">
                <EditIcon className="h-5" />
              </div>
            }
          >
            <Typography variant="subtitle1" className="font-normal">
              Edit Recipe
            </Typography>
          </Button>
        </div>
      </Link>

      {deleteAYS ? (
        <div className="w-full border-t border-separator">
          <Button
            className=" w-full h-16 rounded-none justify-start pl-8"
            startIcon={
              <div className="w-8 flex justify-start">
                <TrashIcon className="h-5 fill-red" />
              </div>
            }
            onClick={handleDelete}
            disabled={deleteLoading}
          >
            <Typography variant="subtitle1" className="text-red font-normal">
              Yes, Delete Recipe
            </Typography>
          </Button>
        </div>
      ) : (
        <div className="w-full border-t border-separator">
          <Button
            className=" w-full h-16 rounded-none justify-start pl-8 border-t"
            startIcon={
              <div className="w-8 flex justify-start">
                <TrashIcon className="h-5 fill-red" />
              </div>
            }
            onClick={() => setDeleteAYS(true)}
          >
            <Typography variant="subtitle1" className="text-red font-normal">
              Delete Recipe
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );

  const notMyRecipe = (
    <>
      <div className="w-full border-t border-separator">
        <Button
          className=" w-full h-16 rounded-none justify-start pl-8"
          startIcon={
            <div className="w-8 flex justify-start">
              <ShareIcon className="h-5" />
            </div>
          }
          onClick={() => handleShare()}
        >
          <Typography variant="subtitle1" className="font-normal">
            Share Recipe
          </Typography>
        </Button>
      </div>
      {isAuthenticated && (
        <div className="w-full border-t border-separator">
          <Button
            className=" w-full h-16 rounded-none justify-start pl-8"
            startIcon={
              <div className="w-8 flex justify-start">
                <ReportIcon className="h-5 fill-red" />
              </div>
            }
            onClick={handleReport}
          >
            <Typography variant="subtitle1" className="text-red font-normal">
              Report
            </Typography>
          </Button>
        </div>
      )}
    </>
  );

  const determineOptions = () => {
    return isMyRecipe ? myRecipe : notMyRecipe;
  };

  return <div className="flex flex-col items-center justify-center">{determineOptions()}</div>;
};
//post.user.id !== user._id
RecipeOptionsModal.propTypes = {
  recipe: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  deleteRecipe: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(RecipeOptionsModal);
