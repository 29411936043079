import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

// Import your navigation components
import GeneralAuth from "./sections/GeneralAuth";
import EarlySignup from "./sections/EarlySignup";
import Static from "./sections/Static";
import NoAuth from "./sections/NoAuth";
import Search from "./sections/Search";
import Landing from "./sections/Landing";

const Navigation = ({ auth: { loading, isAuthenticated, user } }) => {
  const location = useLocation();

  // Determine which navigation to display based on the location
  const determineNavigation = () => {
    if (loading) {
      if (location.pathname === "/") {
        return null;
      }
      return <Static />;
    }
    if (isAuthenticated) {
      if (user && user.earlyAccess && user.earlyAccess.access) {
        // Check for profile and settings paths TO DO: Add list of sections to use default nav for instead of profile
        if (location.pathname.startsWith("/search")) {
          return <Search />;
        } else {
          return <GeneralAuth />;
        }
      } else {
        return <EarlySignup />;
      }
    } else {
      if (location.pathname === "/") {
        return <Landing />;
      }
      // Default for non-authenticated users
      return <NoAuth />;
    }
  };

  return determineNavigation();
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Navigation);
