import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import Spinner from "../../layout/Spinner";

import { setTokensFromCallback } from "../../../actions/auth";

import { useParams, useNavigate } from "react-router-dom";

const OAuthFetchUser = ({ setTokensFromCallback }) => {
  const { token, type } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.post("https://www.thispantry.com/api/auth/oauth", { token, type });
        setTokensFromCallback(res.data.token, res.data.refreshToken, navigate);
      } catch (error) {
        console.log("Error fetching user");
        navigate("/login");
      }
    };

    fetchUser();
  }, [token, type]);

  return (
    <div className="w-full h-full m-auto">
      <Spinner />
    </div>
  );
};

OAuthFetchUser.propTypes = {
  setTokensFromCallback: PropTypes.func.isRequired,
};

export default connect(null, { setTokensFromCallback })(OAuthFetchUser);
