import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Divider, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { setAlert } from "../../actions/alert";

//shadcn
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../ui/carousel";

//Images
//Plus
import PlusCardImage from "./res/plus-card.jpg";
import PlusCard2Image from "./res/plus-card-2.jpg";
//Insights

import InsightsScreenshot1 from "../landing/img/savings/dashboard.png";
import InsightsScreenshot2 from "../landing/img/savings/highlight.png";
//API
import api from "../../api/api";

//Icons
import { ReactComponent as ChefIcon } from "../../assets/icons/svg/chef.svg";

const Contact = ({ auth: { user, isAuthenticated }, setAlert }) => {
  const navigate = useNavigate();

  const plusFeatures = (
    <div className="my-8 w-full">
      <div className="flex flex-row gap-2 items-center mb-4">
        <div className="h-[2.75rem] w-[2.75rem] rounded-[50%] bg-mainGreen flex items-center justify-center mr-2">
          <ChefIcon className="w-[1.5rem] fill-white" />
        </div>
        <div className="flex flex-col">
          <Typography variant="h5">Plus Features</Typography>
          <Typography variant="subtitle2" className="text-secondaryText font-normal leading-none">
            {" "}
            Get more from your kitchen
          </Typography>
        </div>
      </div>
      <Divider />
    </div>
  );

  const insights = (
    <div className="my-8">
      <div className="max-w-[10vw]">
        <Carousel opts={{ loop: true }}>
          <CarouselContent>
            <CarouselItem>
              <img src={InsightsScreenshot1} className="w-full object-cover" />
            </CarouselItem>
            <CarouselItem>
              <img src={InsightsScreenshot2} className="w-full object-cover" />
            </CarouselItem>
            <CarouselItem>
              <img src={InsightsScreenshot1} className="w-full object-cover" />
            </CarouselItem>
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>

      <Typography>Coming Summer 2024 - Insights</Typography>
    </div>
  );

  const pantry = (
    <div className="my-8">
      <Typography>Coming Summer 2024 - Pantry</Typography>
    </div>
  );

  return (
    <div className="w-full px-8 py-8">
      <div className="w-full xl:max-w-[70%] m-auto">
        <div className="flex flex-row justify-between gap-4">
          <div className="w-1/2  aspect-[5/4] m-auto border border-separator rounded-[16px] relative">
            <div>
              <img
                src={PlusCard2Image}
                alt="Dinner party"
                className="object-cover rounded-[16px] w-full aspect-[5/4]"
              ></img>
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black/60 to-white/30 rounded-xl z-[2] pointer-events-none" />
            <div className="absolute left-0 bottom-0 p-4 z-[3]">
              <div className="flex flex-row gap-2 items-end">
                <Typography variant="h2" component="p" className="text-white leading-none font-normal font-serif">
                  $15 <span className="text-xl italic text-white">per month</span>
                </Typography>
                <Typography variant="h6" className="italic text-white font-normal leading-none"></Typography>
              </div>
              <div className="mx-4 my-2">
                <ul className="list-inside text-white">
                  <li className="text-xl w-full">Generate Recipes</li>
                  <li className="text-xl w-full">Early access to Insights*</li>
                  <li className="text-xl">Early access to Pantry*</li>
                </ul>
                <div className="mx-5">
                  <Typography variant="subtitle2" className="text-white font-normal">
                    * Coming Summer 2024
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 aspect-[5/4] m-auto border border-separator rounded-[16px] relative">
            <div>
              <img
                src={PlusCardImage}
                alt="Dinner party"
                className="object-cover rounded-[16px] w-full aspect-[5/4]"
              ></img>
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black/60 to-white/30 rounded-xl z-[2] pointer-events-none" />
            <div className="absolute left-0 bottom-0 p-4 z-[3]">
              <div className="flex flex-row gap-2 items-end">
                <Typography variant="h2" component="p" className="text-white leading-none font-normal font-serif">
                  $125 <span className="text-xl italic text-white">30% off (annual)</span>
                </Typography>
                <Typography variant="h6" className="italic text-white font-normal leading-none"></Typography>
              </div>
            </div>
          </div>
        </div>
        {plusFeatures}
        {insights}
        {pantry}
      </div>
    </div>
  );
};

Contact.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { setAlert })(Contact);
