import React, { useState, Fragment } from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { pantrySelectItemById } from "../../actions/pantry";

import { Typography, Checkbox, Button, IconButton } from "@mui/material";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faEllipsis } from "@fortawesome/free-solid-svg-icons";

//Components
import CustomItemModal from "./modals/pantry_item/CustomItemModal";
import PantryItemModal from "./PantryItemModal";

const PREFIX = 'PantryItem';

const classes = {
  root: `${PREFIX}-root`,
  titleRoot: `${PREFIX}-titleRoot`,
  amountRoot: `${PREFIX}-amountRoot`,
  body: `${PREFIX}-body`,
  linkIcon: `${PREFIX}-linkIcon`,
  left: `${PREFIX}-left`,
  right: `${PREFIX}-right`,
  openModalButton: `${PREFIX}-openModalButton`,
  checkbox: `${PREFIX}-checkbox`,
  itemName: `${PREFIX}-itemName`,
  expiryText: `${PREFIX}-expiryText`,
  expiryText2: `${PREFIX}-expiryText2`,
  onHandValue: `${PREFIX}-onHandValue`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.titleRoot}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.amountRoot}`]: {
    marginTop: theme.spacing(0.25),
  },

  [`& .${classes.body}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.linkIcon}`]: {
    marginLeft: theme.spacing(1),
    height: "0.75em",
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.left}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.right}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "fit-content",
  },

  [`& .${classes.openModalButton}`]: {
    padding: 0,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.checkbox}`]: {
    padding: 0,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.itemName}`]: {
    lineHeight: 1,
  },

  [`& .${classes.expiryText}`]: {
    lineHeight: 1,
    //color: "#FF5F1F",
    color: theme.palette.secondaryTextOnWhite,
    fontWeight: 500,
  },

  [`& .${classes.expiryText2}`]: {
    lineHeight: 1,
    color: "#FF5F1F",
    //color: theme.palette.secondaryTextOnWhite,
    fontWeight: 500,
  },

  [`& .${classes.onHandValue}`]: {
    fontWeight: 200,
  }
}));

//Utility Functions
String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

const PantryItem = ({ item, location, pantrySelectItemById }) => {


  const [showModal, setShowModal] = useState(false);

  const formatItemName = () => {
    let name = item.name;
    let quantity = "";

    if (item.amount && item.amount.unit) {
      if (item.amount.unit.type === "self" || item.amount.unit.type === "count") {
        // Show quantity and unit
        quantity = item.amount.quantity + " ";
      }
    }

    // Pluralize name if quantity is greater than 1
    if (quantity > 1) {
      name += "s";
    }

    return `${quantity}${name.toTitleCase()}`.trim();
  };

  console.log(item);
  return (
    <Root>
      <CustomItemModal open={showModal} handleClose={() => setShowModal(false)} showExit={false}>
        <PantryItemModal item={item} location={location} />
      </CustomItemModal>
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.left}>
            <div className={classes.checkboxRoot}>
              <Checkbox
                value={item.selected}
                className={classes.checkbox}
                onClick={() => pantrySelectItemById(item.pantryId, location)}
                color="primary"
                size="small"
              />
            </div>
            <div className={classes.itemLeft}>
              <Button className={classes.openModalButton} disableRipple onClick={() => setShowModal(true)}>
                <div className={classes.titleRoot}>
                  <Typography variant="subtitle1" className={classes.itemName}>
                    {formatItemName()}
                  </Typography>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={classes.linkIcon} />
                </div>
              </Button>

              <div className={classes.amountRoot}>
                {item && item.amount && item.amount.unit && item.amount.unit.type === "count" ? (
                  <div></div>
                ) : (
                  <div>
                    <Typography variant="subtitle2">
                      {`${item.amount.unitMagnitude} ${
                        item.amount.unit.shortName ? item.amount.unit.shortName : item.amount.unit.displayName
                      } (${item.amount.percentage}%)`}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.right}>
            {item && item.expiry && item.expiry.daysUntil === 1 ? (
              <div className={classes.expiry}>
                <Typography variant="subtitle1" className={classes.expiryText}>
                  {`${item.expiry.daysUntil} Day`}
                </Typography>
              </div>
            ) : (
              <div className={classes.expiry}>
                <Typography variant="subtitle1" className={classes.expiryTextUrgen}>
                  {`${item.expiry.daysUntil} Days`}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </Root>
  );
};

PantryItem.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired,
  pantrySelectItemById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { pantrySelectItemById })(PantryItem);
