import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../ui/chart";
const chartData = [
  { month: "February", groceries: 305, restaurants: 200 },
  { month: "March", groceries: 237, restaurants: 120 },
  { month: "April", groceries: 73, restaurants: 190 },
  { month: "May", groceries: 209, restaurants: 130 },
  { month: "June", groceries: 214, restaurants: 140 },
  { month: "July (so far)", groceries: 186, restaurants: 80 },
];

const chartConfig = {
  groceries: {
    label: "Groceries",
    color: "hsl(var(--chart-5))",
  },
  restaurants: {
    label: "Restaurants",
    color: "hsl(var(--chart-1))",
  },
};

const MultiAreaChart = () => {
  return (
    <ChartContainer config={chartConfig}>
      <AreaChart
        accessibilityLayer
        data={chartData}
        margin={{
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="dot" />} />
        <Area
          dataKey="restaurants"
          type="natural"
          fill="var(--color-restaurants)"
          fillOpacity={1}
          stroke="var(--color-restaurants)"
          stackId="a"
        />
        <Area
          dataKey="groceries"
          type="natural"
          fill="var(--color-groceries)"
          fillOpacity={1}
          stroke="var(--color-groceries)"
          stackId="a"
        />
      </AreaChart>
    </ChartContainer>
  );
};

export default MultiAreaChart;
