import React, { useState, useEffect } from "react";

import { styled } from '@mui/material/styles';

import api from "../../api/api";

//MUI Components
import { Typography, IconButton, Button } from "@mui/material";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare, faClock } from "@fortawesome/free-regular-svg-icons";

//Other icons
import { ReactComponent as CartIcon } from "../../assets/icons/svg/shopping-cart.svg";
import { ReactComponent as CarrotIcon } from "../../assets/icons/svg/carrot.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/svg/trash.svg";

//Chart component
import AreaLineChart from "../graphs/AreaLineChart";
import VisxLineChart from "../graphs/VisxLineChart";

const PREFIX = 'PantryItemModal';

const classes = {
  root: `${PREFIX}-root`,
  nav: `${PREFIX}-nav`,
  navLeft: `${PREFIX}-navLeft`,
  itemNameRoot: `${PREFIX}-itemNameRoot`,
  itemName: `${PREFIX}-itemName`,
  itemBrand: `${PREFIX}-itemBrand`,
  circularButton: `${PREFIX}-circularButton`,
  trashButton: `${PREFIX}-trashButton`,
  quickBar: `${PREFIX}-quickBar`,
  quickUseBy: `${PREFIX}-quickUseBy`,
  quickClock: `${PREFIX}-quickClock`,
  cardRoot: `${PREFIX}-cardRoot`,
  onHandCard: `${PREFIX}-onHandCard`,
  svgIcon: `${PREFIX}-svgIcon`,
  svgInButton: `${PREFIX}-svgInButton`,
  purchasesWhere: `${PREFIX}-purchasesWhere`,
  purchasesLocation: `${PREFIX}-purchasesLocation`,
  purchasesDate: `${PREFIX}-purchasesDate`,
  purchasesAmountRoot: `${PREFIX}-purchasesAmountRoot`,
  purchasesAmount: `${PREFIX}-purchasesAmount`,
  purchasesChange: `${PREFIX}-purchasesChange`,
  purchasesChangeIcon: `${PREFIX}-purchasesChangeIcon`,
  purchasesTop: `${PREFIX}-purchasesTop`,
  fromLastPurchase: `${PREFIX}-fromLastPurchase`,
  changeHistory: `${PREFIX}-changeHistory`,
  changeHistoryButton: `${PREFIX}-changeHistoryButton`,
  onHandAmount: `${PREFIX}-onHandAmount`,
  onHandPercent: `${PREFIX}-onHandPercent`,
  onHandValue: `${PREFIX}-onHandValue`,
  onHandAdded: `${PREFIX}-onHandAdded`,
  onHandOpened: `${PREFIX}-onHandOpened`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.nav}`]: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.separator}`,
  },

  [`& .${classes.navLeft}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.itemNameRoot}`]: {
    marginLeft: theme.spacing(4),
  },

  [`& .${classes.itemName}`]: {},

  [`& .${classes.itemBrand}`]: {
    color: theme.palette.mainGreen,
    fontWeight: 400,
  },

  [`& .${classes.circularButton}`]: {
    borderRadius: "50%",
    width: theme.spacing(4), // Or fixed size, e.g., 48
    height: theme.spacing(4), // Or fixed size, e.g., 48
    border: `1px solid ${theme.palette.separator}`,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.textOnWhite,
  },

  [`& .${classes.trashButton}`]: {
    borderRadius: "50%",
    width: theme.spacing(4), // Or fixed size, e.g., 48
    height: theme.spacing(4), // Or fixed size, e.g., 48
    border: `1px solid ${theme.palette.separator}`,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.textOnWhite,
    "& .MuiIconButton-label": {
      width: "inherit",
    },
  },

  [`& .${classes.quickBar}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
    borderTop: `1px solid ${theme.palette.separator}`,
    //margin: theme.spacing(1, 10.25), //10.25 is with of back button + spacing + border on button to align with title
  },

  [`& .${classes.quickUseBy}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.quickClock}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.cardRoot}`]: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
    //backgroundColor: theme.palette.secondaryGrey,
    borderRadius: 8,
    border: `1px solid ${theme.palette.separator}`,
  },

  [`& .${classes.onHandCard}`]: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
    paddingBottom: 0,
    //backgroundColor: theme.palette.secondaryGrey,
    borderRadius: 8,
    border: `1px solid ${theme.palette.separator}`,
  },

  [`& .${classes.svgIcon}`]: {
    height: "1.5rem",
    marginRight: theme.spacing(1),
  },

  [`& .${classes.svgInButton}`]: {
    height: "1.125rem",
    width: "1.125rem",
    fill: theme.palette.textOnWhite,
  },

  [`& .${classes.purchasesWhere}`]: {
    fontWeight: 400,
    marginTop: theme.spacing(1),
  },

  [`& .${classes.purchasesLocation}`]: {
    fontWeight: 500,
  },

  [`& .${classes.purchasesDate}`]: {
    color: theme.palette.mainGreen,
    fontStyle: "italic",
  },

  [`& .${classes.purchasesAmountRoot}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.purchasesAmount}`]: {
    fontWeight: 600,
    color: theme.palette.mainGreen,
  },

  [`& .${classes.purchasesChange}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.purchasesChangeIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.purchasesTop}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.fromLastPurchase}`]: {
    color: theme.palette.secondaryTextOnWhite,
    fontStyle: "italic",
  },

  [`& .${classes.changeHistory}`]: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: theme.spacing(1, 0),
    borderTop: `1px solid ${theme.palette.separator}`,
  },

  [`& .${classes.changeHistoryButton}`]: {
    textTransform: "none",
    padding: theme.spacing(1, 0),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.onHandAmount}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: theme.spacing(1),
  },

  [`& .${classes.onHandPercent}`]: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondaryTextOnWhite,
    fontStyle: "italic",
  },

  [`& .${classes.onHandValue}`]: {
    fontWeight: 200,
    color: theme.palette.mainGreen,
  },

  [`& .${classes.onHandAdded}`]: {
    color: theme.palette.secondaryTextOnWhite,
    marginTop: theme.spacing(2),
  },

  [`& .${classes.onHandOpened}`]: {
    color: theme.palette.secondaryTextOnWhite,
    marginBottom: theme.spacing(1),
  }
}));

//Utility Functions
String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

/*Info to show in on hand
Amount -> Absolute and percentage remaining
Location + Sublocation
Expiry Date
Expand to show change history
+ Waste percentage, add to shopping list
*/
const PantryItemModal = ({ item, location }) => {


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  //Get data
  const getData = async () => {
    try {
      const res = await api.get(`/pantry/item/${item.pantryId}`);
      setData(res.data);
      setLoading(false);
      setError(false);
    } catch (err) {
      setError(true);
      setData(null);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  console.log(item);

  return (
    <Root className={classes.root}>
      <div className={classes.nav}>
        <div className={classes.navLeft}>
          <IconButton size="small" className={classes.circularButton}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>

          <div className={classes.itemNameRoot}>
            <Typography className={classes.itemName} variant="h5">
              {item.name.toTitleCase()}
            </Typography>
            <Typography className={classes.itemBrand} variant="subtitle1">
              Safeway
            </Typography>
          </div>
        </div>

        <IconButton size="small" className={classes.trashButton}>
          <TrashIcon className={classes.svgInButton} />
        </IconButton>
      </div>

      <div className={classes.onHandCard}>
        <CarrotIcon className={classes.svgIcon} />
        <div className={classes.onHandAmount}>
          <Typography variant="h3" className={classes.onHandValue}>
            500 g
          </Typography>
          <Typography variant="subtitle1" className={classes.onHandPercent}>
            (100%)
          </Typography>
        </div>
        <div className={classes.onHandAdded}>
          <Typography variant="subtitle2">Added: Jan 25, 2024</Typography>
        </div>
        <div className={classes.onHandOpened}>
          <Typography variant="subtitle2">Opened: Feb 2, 2024</Typography>
        </div>
        <div className={classes.quickBar}>
          <div className={classes.quickLocation}>
            <Typography variant="subtitle1">Fridge - Door</Typography>
          </div>
          <div className={classes.quickUseBy}>
            <FontAwesomeIcon icon={faClock} className={classes.quickClock} />
            <Typography variant="subtitle1">Use in: 3 days</Typography>
          </div>
        </div>
        <div className={classes.changeHistory}>
          <Button className={classes.changeHistoryButton} disableRipple>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: "8px" }} />
              Show Item History
            </Typography>
          </Button>
        </div>
      </div>
      <div className={classes.cardRoot}>
        <div className={classes.purchasesTop}>
          <CartIcon className={classes.svgIcon} />
        </div>
        <div>
          <AreaLineChart />
        </div>
        <div>
          <Typography variant="h6" className={classes.purchasesWhere}>
            <span className={classes.purchasesLocation}>Safeway</span>
            <span> on </span>
            <span className={classes.purchasesDate}>Jan 24, 2024</span>
          </Typography>
        </div>
        <div className={classes.purchasesAmountRoot}>
          <Typography variant="h4" className={classes.purchasesAmount}>
            $5.47
          </Typography>
        </div>
        <div className={classes.purchasesChange}>
          <FontAwesomeIcon icon={faChevronUp} className={classes.purchasesChangeIcon} />
          <Typography variant="body1">
            $0.15 (+2.8%) <span className={classes.fromLastPurchase}>from last purchase</span>
          </Typography>
        </div>
      </div>
    </Root>
  );
};

export default PantryItemModal;
