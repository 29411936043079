import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//DOM imports
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//MUI
import { Button, IconButton, Typography, Divider, TextField, Chip } from "@mui/material";
import { Badge } from "../../ui/badge";

//Default PFP
import DefaultPFP from "../../../assets/default/profile_picture.svg";

//Icons
import { ReactComponent as ChevronLeft } from "../../../assets/icons/svg/chevron-left.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ChevronRight } from "../../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as TitleIcon } from "../../../assets/icons/svg/user-light.svg";
import profile from "../../../reducers/profile";

const Policies = ({ profile: { currentProfile }, auth: { user } }) => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [email, setEmail] = useState(user.email);

  const handlePassword1Change = (value) => {
    setPassword1(value);
  };

  const handlePassword2Change = (value) => {
    setPassword2(value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>

      <div className="my-[4rem] md:my-0 md:mt-6">
        <div className="w-full md:ml-[5rem]">
          <div className="px-4 py-3 md:px-8 md:py-6 md:mx-auto w-full md:max-w-[600px] md:border md:border-separator md:rounded-[16px]">
            <div className="flex flex-col">
              <div className="my-4 md:mt-0">
                <Link className="hover:bg-transparent text-secondaryText p-0 justify-start" to="/settings">
                  <Typography variant="body2" className="body1 leading-none">{`< Go Back`}</Typography>
                </Link>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                  <div className="mr-4">
                    <TitleIcon className="h-[1.25rem] fill-primaryText"></TitleIcon>
                  </div>
                  <div className="mb-4">
                    <Typography variant="h6" className="font-normal leading-none mb-1">
                      Account Settings
                    </Typography>
                    <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
                      Authentication and Account Information
                    </Typography>
                  </div>
                </div>
                <Link to="/settings/delete">
                  <Typography variant="subtitle2" className="font-normal text-rose-700">
                    Delete
                  </Typography>
                </Link>
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-4 my-8">
                <Link to={`/${currentProfile.username}`}>
                  <img
                    src={
                      currentProfile && currentProfile.picture && currentProfile.picture !== ""
                        ? currentProfile.picture
                        : DefaultPFP
                    }
                    alt="Your profile"
                    className="h-[4rem] w-[4rem] object-cover rounded-[50%] "
                  />
                </Link>
                <Link to={`/${currentProfile.username}`}>
                  <Typography variant="subtitle1" className="font-normal">
                    {currentProfile && currentProfile.name}
                  </Typography>
                  <Typography variant="subtitle1" className="text-mainGreen leading-tight font-medium">
                    {currentProfile && currentProfile.username && `@${currentProfile.username}`}
                  </Typography>
                </Link>
              </div>
              <div className="my-8 flex flex-col justify-between">
                <Link to="/editprofile">
                  <Typography variant="subtitle2" className="font-normal text-secondaryText">
                    Edit Profile
                  </Typography>
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-4 my-4">
              <TextField value={email} onChange={(e) => handleEmailChange(e.target.value)} fullWidth label="Email" />
            </div>
            <Divider />
            <div className="flex flex-col gap-4 my-2">
              <div className="mb-4 mt-2">
                <Typography variant="subtitle1" className="leading-none text-primaryText font-normal">
                  Change Password
                </Typography>
              </div>

              <TextField
                value={password1}
                onChange={(e) => handlePassword1Change(e.target.value)}
                type="password"
                fullWidth
                label="Current Password"
              />
              <TextField
                value={password1}
                onChange={(e) => handlePassword1Change(e.target.value)}
                type="password"
                fullWidth
                label="New Password 1"
              />
              <TextField
                value={password2}
                onChange={(e) => handlePassword2Change(e.target.value)}
                type="password"
                fullWidth
                label="New Password 2"
              />
              <Button variant="contained" color="primary" disableElevation>
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Policies.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Policies);
