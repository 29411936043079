import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Box,
  OutlinedInput,
  CircularProgress,
  Chip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import api from "../../../api/api";
import { setAlert } from "../../../actions/alert";

const Searchable = ({
  label = "Search",
  helperText = "",
  multiple = true,
  maxItems = 10,
  freeSolo = true,
  unique = true,
  onItemChange,
  apiUrl,
  fullWidth = true,
  setAlert,
  selectedItemsFromParent, //Prefill value
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [lastFiredInputValue, setLastFiredInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const searchFunction = async (inputValue) => {
    try {
      if (inputValue === "") {
        return [];
      }

      const res = await api.get(`${apiUrl}/${inputValue}`);
      return res.data;
    } catch (err) {
      return [];
    }
  };

  const handleInputChange = (e, value) => {
    if (value) {
      setInputValue(value);
    } else {
      setInputValue("");
    }
  };

  const handleBlur = () => {
    setInputFocused(false);

    // If multiple is false and there's an input value, submit it as an item
    if (!multiple && inputValue && inputValue !== lastFiredInputValue.trim()) {
      const newItem = {
        value: inputValue.trim(),
        name: inputValue.trim(),
      };

      setSelectedItems(newItem);
      // Fire onItemChange with the new item
      if (onItemChange) {
        onItemChange(newItem);
        setLastFiredInputValue(inputValue.trim());
      }
    }
  };

  useEffect(() => {
    if (selectedItemsFromParent && selectedItemsFromParent !== selectedItems) {
      setSelectedItems(selectedItemsFromParent);
      setInputValue(selectedItemsFromParent.name);
    }
  }, [selectedItemsFromParent]);

  useEffect(() => {
    setLoading(true);
    searchFunction(inputValue)
      .then((newOptions) => {
        setOptions(newOptions);
        setLoading(false);
      })
      .catch(() => {
        setOptions([]);
        setLoading(false);
      });
  }, [inputValue]);

  return (
    <div className="mb-2">
      <Autocomplete
        freeSolo={freeSolo}
        multiple={multiple}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          } else if (option && option.name) {
            return option.name;
          } else {
            return "";
          }
        }}
        fullWidth={fullWidth}
        onInputChange={handleInputChange}
        disabled={disabled}
        onChange={(event, newValue) => {
          // If multiple is true, newValue is an array
          if (multiple) {
            newValue = newValue
              .map((item) => {
                if (typeof item === "string") {
                  item = item.trim();
                  if (item === "") {
                    return null;
                  }
                  return { value: item, name: item };
                }
                return item;
              })
              .filter((item) => item !== null);

            // Clamp the newValue to maxItems
            if (newValue.length > maxItems) {
              setAlert(`You can only select up to ${maxItems} items.`, "error");
              newValue = newValue.slice(0, maxItems);
            }
          } else {
            // If multiple is false, newValue is a single value
            if (typeof newValue === "string") {
              newValue = newValue.trim();
              if (newValue !== "") {
                newValue = { value: newValue, name: newValue };
              } else {
                newValue = null;
              }
            }
          }

          // Update the state
          setSelectedItems(newValue);

          // Call the onChange function passed as a prop
          onItemChange(newValue);
        }}
        noOptionsText={`Search for an item...`}
        classes={{
          paper: "bg-white",
        }}
        filterOptions={(options, { inputValue }) => {
          let isExactMatch = options.some((option) => option.name.toLowerCase() === inputValue.toLowerCase());

          // Filter out the already selected options
          if (unique && multiple) {
            options = options.filter((option) => {
              return !selectedItems.some((selectedItem) => selectedItem.value === option.value);
            });
          }

          if (inputValue !== "" && !isExactMatch) {
            options = [
              ...options,
              {
                value: `${inputValue}`,
                name: `${inputValue}`,
              },
            ];
          }

          return options;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={options.key} className="cursor-pointer mx-4 my-2">
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              {option.name}
            </Typography>
          </li>
        )}
        renderTags={(value, getTagProps) =>
          multiple
            ? value.slice(0, maxItems).map((option, index) => {
                // Convert strings to objects
                if (typeof option === "string") {
                  option = { value: option, name: option };
                }

                return <Chip variant="outlined" label={option.name} key={option.key} {...getTagProps({ index })} />;
              })
            : value.map((option, index) => {
                // Convert strings to objects
                if (typeof option === "string") {
                  option = { value: option, name: option };
                }

                return <Chip variant="outlined" label={option.name} key={option.key} {...getTagProps({ index })} />;
              })
        }
        renderInput={(params) => (
          <FormControl fullWidth variant="outlined">
            <TextField
              {...params}
              id="custom-autocomplete"
              helperText={helperText}
              variant="outlined"
              onFocus={() => setInputFocused(true)}
              onBlur={() => handleBlur()}
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                value: inputValue,
              }}
            />
          </FormControl>
        )}
        value={selectedItems}
      />
    </div>
  );
};

Searchable.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  searchFunction: PropTypes.func.isRequired,
  selectFunction: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  maxItems: PropTypes.number,
  freeSolo: PropTypes.bool,
  unique: PropTypes.bool,
  apiUrl: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  setAlert: PropTypes.func.isRequired,
  selectedItemsFromParent: PropTypes.array,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setAlert })(Searchable);
