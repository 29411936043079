import React, { useEffect, useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";

//API
import api from "../../api/api";

//MUI
import { TextField, Button, Typography, Slider } from "@mui/material";

//Default Profile Picture
import { ReactComponent as DefaultPFP } from "../../assets/default/profile_picture.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok, faYoutube, faFacebook, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

//Cropper
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../utils/image";

//Modal
import CustomModal from "../modal/CustomModal";

//Actions
import { updateProfileSuccess } from "../../actions/profile";
const PREFIX = 'EditProfile';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  form: `${PREFIX}-form`,
  image: `${PREFIX}-image`,
  image_container: `${PREFIX}-image_container`,
  image_edit: `${PREFIX}-image_edit`,
  image_remove: `${PREFIX}-image_remove`,
  update: `${PREFIX}-update`,
  section_title: `${PREFIX}-section_title`,
  section_title_item: `${PREFIX}-section_title_item`,
  section_item: `${PREFIX}-section_item`,
  social_icon: `${PREFIX}-social_icon`,
  social_icon_container: `${PREFIX}-social_icon_container`,
  cropperRoot: `${PREFIX}-cropperRoot`,
  cropperContainer: `${PREFIX}-cropperContainer`,
  cropper: `${PREFIX}-cropper`,
  controls: `${PREFIX}-controls`,
  cropButton: `${PREFIX}-cropButton`,
  controlsContainer: `${PREFIX}-controlsContainer`,
  slider: `${PREFIX}-slider`,
  vertStack: `${PREFIX}-vertStack`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
      paddingTop: "4rem",
    },
  },

  [`& .${classes.content}`]: {
    paddingTop: theme.spacing(4),
    width: "100%",
    maxWidth: "600px",
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 2),
    },
  },

  [`& .${classes.form}`]: {},

  [`& .${classes.image}`]: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50%",
    border: `1px solid ${theme.palette.textOnWhite}`,
  },

  [`& .${classes.image_container}`]: {
    width: "100%",
    display: "flex",
    marginBottom: theme.spacing(2),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },

  [`& .${classes.image_edit}`]: {
    textTransform: "none",
    color: theme.palette.mainGreen,
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "none",
    },
  },

  [`& .${classes.image_remove}`]: {
    textTransform: "none",
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: "none",
    },
  },

  [`& .${classes.update}`]: {
    textTransform: "none",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.section_title}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.section_title_item}`]: {
    fontWeight: 500,
    display: "inline",
  },

  [`& .${classes.section_item}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.social_icon}`]: {
    height: "1.75rem",
    color: theme.palette.textOnWhite + "B3", //70%,
  },

  [`& .${classes.social_icon_container}`]: {
    marginRight: theme.spacing(2),
    minWidth: "2rem",
  },

  //Cropper
  [`& .${classes.cropperRoot}`]: {
    margin: theme.spacing(4),
  },

  [`& .${classes.cropperContainer}`]: {
    position: "relative",
    width: "100%",
    height: "400px", // Adjust as needed
    backgroundColor: "#f4f4f4",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  },

  [`& .${classes.cropper}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  [`& .${classes.controls}`]: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },

  [`& .${classes.cropButton}`]: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  [`& .${classes.controlsContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },

  [`& .${classes.slider}`]: {
    width: "80%", // Adjust as needed
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.vertStack}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
}));

const EditProfile = ({ profile: { currentProfile }, updateProfileSuccess, history }) => {


  const [formData, setFormData] = useState({
    username: currentProfile && currentProfile.username ? currentProfile.username : "",
    picture: currentProfile && currentProfile.picture ? currentProfile.picture : "",
    name: currentProfile && currentProfile.name ? currentProfile.name : "",
    bio: currentProfile && currentProfile.bio ? currentProfile.bio : "",
    link: currentProfile && currentProfile.link ? currentProfile.link : "",
  });

  const [imagePreview, setImagePreview] = useState();

  const [profilePicFile, setProfilePicFile] = useState(null);

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the image preview for cropping
        setProfilePicFile(file); // Store the file for uploading
        setShowCropper(true); // Open the cropper modal
        setCrop({ x: 0, y: 0 }); // Reset crop position
        setZoom(1); // Reset zoom
      };
      reader.readAsDataURL(file); // Read the file for preview
      //Crop to square as default
      defaultCrop(file);
    } else {
      setShowCropper(false);
    }
  };

  const handleRemoveProfilePicture = () => {
    setImagePreview(""); // Clear the image preview
    setProfilePicFile(null); // Clear the file
  };
  //Error reporting
  const [errors, setErrors] = useState({
    username: "",
    name: "",
    bio: "",
    link: "",
  });

  //Cropping

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(imagePreview, croppedAreaPixels);
      if (croppedImageBlob instanceof Blob) {
        const croppedFile = new File([croppedImageBlob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        setProfilePicFile(croppedFile);
        setImagePreview(URL.createObjectURL(croppedImageBlob)); // Update the preview with the cropped image
      } else {
        console.error("The cropped image is not a Blob");
      }
      setShowCropper(false); // Close the cropper modal
    } catch (e) {
      console.error(e);
    }
  };

  const defaultCrop = async (file) => {
    // Create a new image object
    const img = new Image();
    img.src = file;
    await new Promise((resolve) => {
      img.onload = resolve;
    });

    // Get the dimensions of the original image
    const originalWidth = img.width;
    const originalHeight = img.height;

    // Calculate the size and position of the cropped image
    const cropSize = Math.min(originalWidth, originalHeight);
    const crop = {
      x: (originalWidth - cropSize) / 2,
      y: (originalHeight - cropSize) / 2,
    };

    // Use your getCroppedImage function to crop the image
    const croppedImage = await getCroppedImg(file, {
      unit: "px",
      width: cropSize,
      height: cropSize,
      x: crop.x,
      y: crop.y,
    });

    setCrop(crop);
    setCroppedAreaPixels({ width: cropSize, height: cropSize, x: crop.x, y: crop.y });
    setImagePreview(croppedImage);
  };
  //Update state if currentProfile changes (usually first load)
  useEffect(() => {
    setFormData({
      username: currentProfile && currentProfile.username ? currentProfile.username : "",
      picture: currentProfile && currentProfile.picture ? currentProfile.picture : "",
      name: currentProfile && currentProfile.name ? currentProfile.name : "",
      bio: currentProfile && currentProfile.bio ? currentProfile.bio : "",
      link: currentProfile && currentProfile.link ? currentProfile.link : "",
    });
    setImagePreview(currentProfile && currentProfile.picture);
  }, [currentProfile]);

  useEffect(() => {
    // Cleanup function to revoke the object URL
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);
  /*
        TO DO:
        Image handling -> Upload/remove -> Open modal to crop image, send to S3 on form send
        Send form to backend and update profile (only if things were changed)
        Fetch profile, prefill values
        Use react-easy-crop to crop images to square?
        Edit Links Modal
        Pre-fill social links if signed up with a third party social?
        Error Reporting -> Username taken, etc
        Google WebRisk API for website links
        Wrap Picture in button to handle edit click on image
        Private Profile
        */

  const handleSubmit = async (e) => {
    e.preventDefault();

    //Verification
    //Username
    const usernamePattern = /^[a-zA-Z0-9._-]{3,20}$/;
    if (!usernamePattern.test(formData.username)) {
      setErrors({ ...errors, username: "Invalid username" });
      return;
    }

    //Name
    if (formData.name.length > 100) {
      setErrors({ ...errors, name: "Invalid name" });
      return;
    }

    //Bio
    if (formData.bio.length > 256) {
      setErrors({ ...errors, bio: "Invalid bio" });
      return;
    }

    //Link
    if (formData.link.length > 256) {
      setErrors({ ...errors, link: "Invalid link" });
      return;
    }

    const submissionData = new FormData();
    submissionData.append("username", formData.username);
    submissionData.append("name", formData.name);
    submissionData.append("bio", formData.bio);
    submissionData.append("link", formData.link);

    if (profilePicFile) {
      submissionData.append("picture", profilePicFile); // Append the file for upload
    }

    if (imagePreview === "") {
      submissionData.append("picture", "");
    }

    try {
      //Catch and read 400 error to display to user
      const res = await api
        .post("/profile/edit", submissionData, {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure proper content type for file upload
          },
        })
        .catch((err) => {
          console.log(err.response.status === 400);
          if (err.response.status === 400) {
            console.log("fuck");
            console.log(err.response.data.errors);
            setErrors({
              username: err.response.data.errors.username ? err.response.data.errors.username : "",
              name: err.response.data.errors.name ? err.response.data.errors.name : "",
              bio: err.response.data.errors.bio ? err.response.data.errors.bio : "",
              link: err.response.data.errors.link ? err.response.data.errors.link : "",
            });
          } else {
            return;
          }
        });

      // Handle response
      updateProfileSuccess(res.data);
      history.push(`/${res.data.username}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUsername = (e) => {
    //Trim and remove new lines
    if (e.target.value.length < 20) {
      setFormData({ ...formData, username: e.target.value });
    } else {
      setErrors({
        ...errors,
        username: "Username must be less than 20 characters",
      });
    }
  };

  const handleName = (e) => {
    if (e.target.value.length < 100) {
      setFormData({ ...formData, name: e.target.value });
    } else {
      setErrors({ ...errors, name: "Name must be less than 100 characters" });
    }
  };

  const handleBio = (e) => {
    const trimmed = e.target.value.replace(/(\n{3,})/g, "\n");
    if (trimmed.length < 256) {
      setFormData({ ...formData, bio: trimmed });
      setErrors({ ...errors, bio: "" });
    } else {
      setErrors({ ...errors, bio: "Bio must be less than 256 characters" });
    }
  };

  const handleLink = (e) => {
    const trimmed = e.target.value.trim().replace(/\n/g, "");
    if (trimmed.length < 256) {
      setFormData({ ...formData, link: trimmed });
    } else {
      setErrors({ ...errors, link: "Link must be less than 256 characters" });
    }
  };

  return (
    <Root>
      <CustomModal open={showCropper} handleClose={() => setShowCropper(false)} showExit={false}>
        <div className={classes.cropperRoot}>
          <div className={classes.cropperContainer}>
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              className={classes.cropper}
              showGrid={false}
            />
          </div>
        </div>

        <div className={classes.controlsContainer}>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, newZoom) => setZoom(newZoom)}
            className={classes.slider}
          />
          <Button className={classes.cropButton} onClick={handleCrop} size="large">
            Crop Image
          </Button>
        </div>
      </CustomModal>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.image_container}>
            {imagePreview === "" ? (
              <DefaultPFP className={classes.image} />
            ) : (
              <img alt={`${formData.username}'s profile picture`} src={imagePreview} className={classes.image}></img>
            )}

            <div className={classes.vertStack}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleProfilePicChange}
              />
              <label htmlFor="raised-button-file">
                <Button component="span" className={classes.image_edit} disableRipple>
                  Edit Profile Picture
                </Button>
              </label>
              {imagePreview !== "" && (
                <Button disableRipple onClick={handleRemoveProfilePicture} className={classes.image_remove}>
                  Remove Profile Picture
                </Button>
              )}
            </div>
          </div>
          <form className={classes.form} onSubmit={handleSubmit} method="post" enctype="multipart/form-data">
            <div className={classes.core}>
              <div className={classes.section_item}>
                <TextField
                  label="Username"
                  fullWidth
                  value={formData.username}
                  onChange={(e) => handleUsername(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.username !== ""}
                  helperText={errors.username}
                />
              </div>
              <div className={classes.section_item}>
                <TextField
                  label="Name"
                  fullWidth
                  value={formData.name}
                  onChange={(e) => handleName(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.name !== ""}
                  helperText={errors.name}
                />
              </div>
              <div className={classes.section_item}>
                <TextField
                  label="Bio"
                  fullWidth
                  multiline
                  value={formData.bio}
                  onChange={(e) => handleBio(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.bio !== ""}
                  helperText={errors.bio}
                />
              </div>
              <div className={classes.section_item}>
                <TextField
                  label="Link"
                  fullWidth
                  value={formData.link}
                  onChange={(e) => handleLink(e)}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  error={errors.link !== ""}
                  helperText={errors.link}
                />
              </div>
            </div>
          </form>

          <Button
            type="submit"
            variant="contained"
            disableElevation
            color="primary"
            className={classes.update}
            onClick={handleSubmit}
          >
            Update Profile
          </Button>
        </div>
      </div>
    </Root>
  );
};

EditProfile.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { updateProfileSuccess })(EditProfile);
