import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

import Button from "@mui/material/Button";

//Actions
import { showLoginModal, showRegisterModal } from "../../../actions/auth";

//Scroll shadow to show icons? Eg. Fade to top

const Landing = ({ showLoginModal, showRegisterModal }) => {
  return (
    <nav className="z-50 bg-mainGreen-900 bg-opacity-80 backdrop-blur-sm w-full h-[4rem] fixed top-0 px-8 py-4 flex flex-row justify-between align-center ">
      <div className="text-background">
        <Logo className="fill-background h-full w-auto" />
      </div>
      <div className="flex flex-row align-center justify-center">
        <Button
          variant="outlined"
          className="text-background border-2 hover:border-2 font-normal"
          onClick={() => showLoginModal()}
        >
          Log In
        </Button>
        <Button variant="contained" disableElevation className="ml-2" onClick={() => showRegisterModal()}>
          + Sign Up
        </Button>
      </div>
    </nav>
  );
};

Landing.propTypes = {
  showLoginModal: PropTypes.func.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { showLoginModal, showRegisterModal })(Landing);
