//Create a MUI v4 modal template that matches the style of the LoginModal
import React from "react";
import { styled } from '@mui/material/styles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const PREFIX = 'CustomItemModal';

const classes = {
  root: `${PREFIX}-root`,
  background: `${PREFIX}-background`,
  modalContent: `${PREFIX}-modalContent`,
  nav: `${PREFIX}-nav`,
  nav_button: `${PREFIX}-nav_button`,
  nav_text: `${PREFIX}-nav_text`,
  content: `${PREFIX}-content`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  [`& .${classes.background}`]: {
    backgroundColor: theme.palette.background,
    borderRadius: 16,
    border: "1px solid #fff",
  },

  [`& .${classes.modalContent}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    marginLeft: "auto",
    marginRight: "auto",
  },

  [`& .${classes.nav}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    top: 0,
    padding: theme.spacing(1, 2),
    position: "fixed",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 4),
      paddingRight: theme.spacing(2.75),
    },
  },

  [`& .${classes.nav_button}`]: {
    textTransform: "none",
    color: theme.palette.textOnWhite,
  },

  [`& .${classes.nav_text}`]: {
    fontWeight: 500,
  },

  [`& .${classes.content}`]: {
    minHeight: "10vh",
    maxHeight: "85vh",
    width: "30vw",
    minWidth: "600px",
    overflowY: "auto",
    [theme.breakpoints.down('md')]: {
      width: "90vw",
      minWidth: "inherit",
      maxWidth: "450px",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.secondaryTextOnWhite,

      borderRadius: "10px",
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.textOnWhite,
    },
  }
}));

export default function CustomItemModal(props) {

  const { open, handleClose, children, showExit = true } = props; //destructure props
  return (
    <Root>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.root}>
            <Paper className={classes.modalContent} elevation={0}>
              <div className={classes.background}>
                {showExit && (
                  <div className={classes.nav}>
                    <div></div>

                    <IconButton
                      aria-label="exit"
                      onClick={() => handleClose()}
                      className={classes.nav_button}
                      size="large">
                      <FontAwesomeIcon icon={faXmark} />
                    </IconButton>
                  </div>
                )}

                <div className={classes.content}>{children}</div>
              </div>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </Root>
  );
}

CustomItemModal.defaultProps = {
  open: false,
  handleClose: () => {},
  children: null,
};

CustomItemModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.node,
};
