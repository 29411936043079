import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

import { ReactComponent as DefaultPFP } from "../../../../assets/default/profile_picture.svg";

import FollowButton from "../../../profile/FollowButton";

const ProfileSearchObject = ({ profile, auth: { user } }) => {
  //TO DO: Add top recipe image as background with see through white backdrop? Move text around? Swipable through the images, showcase the recipes on swipe?
  return (
    <div className="w-full border border-separator rounded-lg mt-[2.5rem] relative">
      <div className="absolute top-0 left-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-background rounded-[50%]">
        {profile.picture !== "" ? (
          <Link to={`/${profile.username}`}>
            <img
              src={profile.picture}
              alt={`${profile.username}`}
              className="h-[5rem] w-[5rem] rounded-[50%] border border-separator object-cover"
            />
          </Link>
        ) : (
          <Link to={`/${profile.username}`}>
            <DefaultPFP className="h-[5rem] w-[5rem] rounded-[50%] border border-separator object-cover" />
          </Link>
        )}
      </div>
      <div className="h-full flex flex-col justify-between">
        <div className="flex flex-col p-4 items-center mt-[2.5rem]">
          <Link to={`/${profile.username}`}>
            <Typography variant="subtitle1" className="font-normal text-center leading-tight">
              {profile.name}
            </Typography>
            <Typography
              variant="subtitle1"
              className="text-mainGreen text-center font-medium"
            >{`@${profile.username}`}</Typography>
          </Link>

          <Typography
            variant="caption"
            className="text-secondaryText mt-2 text-center overflow-hidden text-ellipsis break-words w-full"
            sx={{ display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2 }}
          >
            {profile.bio}
          </Typography>
        </div>
        <div className="flex flex-row items-center justify-center mb-4 gap-4">
          <Link to={`/${profile.username}`}>
            <Button variant="outlined" color="secondary" disableElevation>
              View Profile
            </Button>
          </Link>

          {user._id !== profile._id && <FollowButton isFollowing={profile.isFollowing} userid={profile._id} />}
        </div>
      </div>
    </div>
  );
};

ProfileSearchObject.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProfileSearchObject);
