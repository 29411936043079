import React from "react";
import { Typography } from "@mui/material";
import { Badge } from "../../../../ui/badge";

const RecipeAllergens = ({ allergens }) => {
  //To DO:
  //- allergen disclaimer, always check product labels and verify ingredients for allergens
  //- if list empty, say "none listed"

  return (
    <div>
      <div>
        <Typography variant="h6" className="text-primaryText font-sans font-normal leading-tight">
          Allergens
        </Typography>
      </div>
      <div className="my-2">
        {allergens.contains.length > 0 && (
          <div className="mb-2">
            <Typography variant="subtitle2" className="italic mb-2 leading-tight font-normal">
              Contains
            </Typography>
            <div>
              {allergens.contains.map((allergen, i) => {
                return (
                  <Badge
                    variant="primary"
                    className="font-normal mr-[0.25rem] mb-[0.25rem] py-1 px-3 shadow-sm cursor-pointer"
                  >
                    <Typography variant="body2" className="text-xs">
                      {allergen.text}
                    </Typography>
                  </Badge>
                );
              })}
            </div>
          </div>
        )}
        {allergens.mayContain.length > 0 && (
          <div className="mb-2">
            <Typography variant="subtitle2" className="italic mb-2 leading-tight font-normal">
              May Contain
            </Typography>
            <div>
              {allergens.mayContain.map((allergen, i) => {
                return (
                  <Badge
                    variant="primary"
                    className="font-normal mr-[0.25rem] mb-[0.25rem] py-1 px-3 shadow-sm cursor-pointer"
                  >
                    <Typography variant="body2" className="text-xs">
                      {allergen.text}
                    </Typography>
                  </Badge>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="mb-2">
        <Typography variant="caption" className="font-normal text-secondaryText italic">
          * Always check product labels and verify ingredients for allergens.
        </Typography>
      </div>
    </div>
  );
};

export default RecipeAllergens;
