import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import CustomSearchAutocomplete from "./CustomSearchAutocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import PropTypes from "prop-types";
import { connect } from "react-redux";

//FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const PREFIX = "AddIndividualItem";

const classes = {
  form: `${PREFIX}-form`,
  field: `${PREFIX}-field`,
  option: `${PREFIX}-option`,
  submitButton: `${PREFIX}-submitButton`,
  paper: `${PREFIX}-paper`,
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  optionalButton: `${PREFIX}-optionalButton`,
  sublocationButton: `${PREFIX}-sublocationButton`,
  expiryRoot: `${PREFIX}-expiryRoot`,
  bestBeforeToggle: `${PREFIX}-bestBeforeToggle`,
  navButton: `${PREFIX}-navButton`,
  navRoot: `${PREFIX}-navRoot`,
  itemTypeButton: `${PREFIX}-itemTypeButton`,
  itemTypeSelected: `${PREFIX}-itemTypeSelected`,
  itemTypeSecondary: `${PREFIX}-itemTypeSecondary`,
  itemTypeSeparator: `${PREFIX}-itemTypeSeparator`,
  itemTypeNav: `${PREFIX}-itemTypeNav`,
  searchContainer: `${PREFIX}-searchContainer`,
  selectedTitle: `${PREFIX}-selectedTitle`,
  selected: `${PREFIX}-selected`,
  selectedTitleText: `${PREFIX}-selectedTitleText`,
  selectedAdding: `${PREFIX}-selectedAdding`,
  removeSelected: `${PREFIX}-removeSelected`,
  selectedVerifiedRoot: `${PREFIX}-selectedVerifiedRoot`,
  selectedVerified: `${PREFIX}-selectedVerified`,
  amountRoot: `${PREFIX}-amountRoot`,
  amountSection: `${PREFIX}-amountSection`,
  amountQuantity: `${PREFIX}-amountQuantity`,
  amountItem: `${PREFIX}-amountItem`,
  amountVerifiedRoot: `${PREFIX}-amountVerifiedRoot`,
  infoItem: `${PREFIX}-infoItem`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.form}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.field}`]: {
    width: "100%",
  },

  [`& .${classes.option}`]: {
    backgroundColor: "#f9f9f9",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
    padding: theme.spacing(1),
  },

  [`& .${classes.submitButton}`]: {
    marginTop: theme.spacing(4),
    textTransform: "none",
  },

  [`& .${classes.paper}`]: {
    backgroundColor: "white",
  },

  [`&.${classes.section}`]: {
    marginTop: theme.spacing(2),
    width: "100%",
  },

  [`& .${classes.sectionTitle}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.optionalButton}`]: {
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.63)",
  },

  [`& .${classes.sublocationButton}`]: {
    textTransform: "none",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    color: "rgba(0, 0, 0, 0.63)",
  },

  [`& .${classes.expiryRoot}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.bestBeforeToggle}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.navButton}`]: {
    textTransform: "none",
  },

  [`& .${classes.navRoot}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.itemTypeButton}`]: {
    textTransform: "none",
    padding: 0,
  },

  [`& .${classes.itemTypeSelected}`]: {
    color: theme.palette.textOnWhite,
  },

  [`& .${classes.itemTypeSecondary}`]: {
    color: theme.palette.secondaryTextOnWhite,
  },

  [`& .${classes.itemTypeSeparator}`]: {
    width: "1px",
    backgroundColor: theme.palette.separator,
    margin: theme.spacing(0, 2),
  },

  [`& .${classes.itemTypeNav}`]: {
    height: "100%",
    display: "flex",
    alignItems: "stretch",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.searchContainer}`]: {
    margin: theme.spacing(0),
  },

  [`& .${classes.selectedTitle}`]: {
    margin: theme.spacing(1, 4),
    display: "flex",
    flexDirection: "row",
    marginBottom: 0,
  },

  [`& .${classes.selected}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.selectedTitleText}`]: {
    fontWeight: 400,
  },

  [`& .${classes.selectedAdding}`]: {
    fontStyle: "italic",
  },

  [`& .${classes.removeSelected}`]: {
    padding: 0,
    color: "red",
    fontSize: "1rem",
    marginLeft: theme.spacing(2),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  [`& .${classes.selectedVerifiedRoot}`]: {
    margin: theme.spacing(0, 4),
    color: theme.palette.secondaryTextOnWhite,
    fontStyle: "italic",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.selectedVerified}`]: {
    color: theme.palette.mainGreen,
    marginRight: theme.spacing(1),
  },

  [`& .${classes.amountRoot}`]: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },

  [`& .${classes.amountSection}`]: {
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.amountQuantity}`]: {
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.amountItem}`]: {
    marginLeft: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.amountVerifiedRoot}`]: {
    color: theme.palette.secondaryTextOnWhite,
    fontStyle: "italic",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  [`& .${classes.infoItem}`]: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}));

//Utility Function
String.prototype.toTitleCase = function () {
  var i, j, str, lowers, uppers;
  str = this.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
      return txt.toLowerCase();
    });

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp("\\b" + uppers[i] + "\\b", "g"), uppers[i].toUpperCase());

  return str;
};

//TO DO: Split into 3 parts -> Item search -> Required Fields -> Optional Fields
//TO DO: After submitting, allow user to choose to add another item, reset values, and go again (for easy mass import)
const IndividualItem = ({ pantry: { addToPantry } }) => {
  //Local State
  //General
  const [section, setSection] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the form submission here
    console.log(ingredient);
  };
  //Search Section
  const [ingredient, setIngredient] = useState("");

  //Amount Section
  const [selectedUnit, setSelectedUnit] = useState("");

  const [quantity, setQuantity] = useState(1);
  const [quantityError, setQuantityError] = useState({
    error: false,
    message: "",
  });

  const handleQuantityChange = (value) => {
    //Ensure the value is a number between 1 and 100
    if (value < 0 || value > 100) {
      setQuantityError({
        error: true,
        message: "Quantity must be between 1 and 100",
      });
      return;
    }
    setQuantityError({ error: false, message: "" });
    setQuantity(value);
  };

  const [size, setSize] = useState("");
  const [sizeError, setSizeError] = useState({
    error: false,
    message: "",
  });

  const handleSizeChange = (value) => {
    if (value.length < 50) {
      setSizeError({
        error: false,
        message: "",
      });
      setSize(value);
    } else {
      setSizeError({
        error: true,
        message: "Size must be less than 50 characters",
      });
    }
  };

  const handleAmountNext = () => {};
  //Info Section
  const [storageLocation, setStorageLocation] = useState("");
  const [showSublocation, setShowSublocation] = useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [noExpiryDate, setNoExpiryDate] = useState(false);
  //Final Section
  const [showPurchaseInfo, setShowPurchaseInfo] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  //Show optional fields

  const onHidePurchaseInfo = () => {
    //TODO: Clear purchase info fields
    setShowPurchaseInfo(false);
  };

  //Fields to add:
  //Date added to location
  //Expiry Date
  //State?

  const [itemType, setItemType] = useState(0);
  const searchSection = (
    <Root className={classes.section}>
      <div className={classes.itemTypeNav}>
        <Button className={classes.itemTypeButton} onClick={() => setItemType(0)}>
          <Typography variant="h6" className={itemType === 0 ? classes.itemTypeSelected : classes.itemTypeSecondary}>
            Ingredient
          </Typography>
        </Button>
      </div>
      <div className={classes.searchContainer}>
        {itemType === 0 && (
          <CustomSearchAutocomplete label={"Search for an Ingredient"} onChange={(e, v) => setIngredient(v)} />
        )}
        {itemType === 1 && (
          <CustomSearchAutocomplete label={"Search for a Product"} onChange={(e, v) => setIngredient(v)} />
        )}
      </div>
      {addToPantry.selected && (
        <div className={classes.selected}>
          <div>
            <Typography className={classes.selectedAdding}>Adding:</Typography>
          </div>
          <div className={classes.selectedTitle}>
            <Typography variant="h6" className={classes.selectedTitleText}>
              {addToPantry.selected.name.toTitleCase()}
            </Typography>
            <IconButton className={classes.removeSelected} size="large">
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </div>
          <div className={classes.selectedVerifiedRoot}>
            {addToPantry.selected.verified ? (
              <Fragment>
                <FontAwesomeIcon icon={faCircleCheck} className={classes.selectedVerified} />
                <Typography variant="body2">Verified Ingredient</Typography>
              </Fragment>
            ) : (
              <Typography variant="body2" className={classes.selectedNotverified}>
                Unverified
              </Typography>
            )}
          </div>
        </div>
      )}
    </Root>
  );

  const itemInfoSection = (
    <Fragment>
      <div className={classes.section}>
        {addToPantry.selected && (
          <div className={classes.infoItem}>
            <Typography variant="h6" className={classes.selectedTitleText}>
              {addToPantry.selected.name.toTitleCase()}
            </Typography>
            <div className={classes.amountVerifiedRoot}>
              {addToPantry.selected.verified ? (
                <Fragment>
                  <FontAwesomeIcon icon={faCircleCheck} className={classes.selectedVerified} />
                  <Typography variant="body2">Verified Ingredient</Typography>
                </Fragment>
              ) : (
                <Typography variant="body2" className={classes.selectedNotverified}>
                  Unverified
                </Typography>
              )}
            </div>
          </div>
        )}

        <div className={classes.expiryRoot}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Use By:
          </Typography>
          <DatePicker
            value={expiryDate}
            onChange={(date) => setExpiryDate(date)}
            format="MM/dd/yyyy"
            disablePast
            animateYearScrolling
            disabled={noExpiryDate}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={noExpiryDate}
                onChange={(event) => setNoExpiryDate(event.target.checked)}
                color="primary"
              />
            }
            label="No Use By Date"
            className={classes.bestBeforeToggle}
          />
        </div>
      </div>

      <div className={classes.section}>
        <FormControl variant="outlined" className={classes.field}>
          <InputLabel id="storage-location-label">Storage Location</InputLabel>
          <Select
            labelId="storage-location-label"
            id="storage-location"
            label="Storage Location"
            value={storageLocation}
            onChange={(e) => setStorageLocation(e.target.value)}
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "white",
                },
              },
            }}
          >
            <MenuItem value={"refrigerator"}>Refrigerator</MenuItem>
            <MenuItem value={"freezer"}>Freezer</MenuItem>
            <MenuItem value={"pantry"}>Pantry</MenuItem>
            <MenuItem value={"spicerack"}>Spice Rack</MenuItem>
          </Select>
        </FormControl>
        <Button fullWidth variant="outlined" className={classes.sublocationButton}>
          Add a Sub-location
        </Button>
      </div>
    </Fragment>
  );

  const amountSection = (
    <div className={classes.section}>
      <div className={classes.amountRoot}>
        <div className={classes.amountQuantity}>
          <TextField
            variant="outlined"
            label="Quantity"
            error={quantityError.error}
            type="number"
            value={quantity}
            onChange={(e) => handleQuantityChange(e.target.value)}
            inputProps={{
              min: 0,
              max: 100,
            }}
            InputProps={{
              endAdornment: <FontAwesomeIcon icon={faTimes} />,
            }}
          />
          {addToPantry.selected && (
            <div className={classes.amountItem}>
              <Typography variant="h6" className={classes.selectedTitleText}>
                {addToPantry.selected.name.toTitleCase()}
              </Typography>
              <div className={classes.amountVerifiedRoot}>
                {addToPantry.selected.verified ? (
                  <Fragment>
                    <FontAwesomeIcon icon={faCircleCheck} className={classes.selectedVerified} />
                    <Typography variant="body2">Verified Ingredient</Typography>
                  </Fragment>
                ) : (
                  <Typography variant="body2" className={classes.selectedNotverified}>
                    Unverified
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={classes.amountSection}>
          <div className={classes.sectionTitle}>
            <Typography variant="subtitle1">Each item:</Typography>
          </div>
          <TextField
            variant="outlined"
            label="Amount"
            fullWidth
            helperText="Specify the size of one unit (e.g., 500 for a 500 ml bottle of oil)"
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="unit-label">Unit</InputLabel>
            <Select
              labelId="unit-label"
              id="unit"
              value={selectedUnit}
              onChange={(e) => setSelectedUnit(e.target.value)}
              label="Unit"
            >
              <MenuItem value="cup">Cup</MenuItem>
              <MenuItem value="grams">Grams</MenuItem>
              <MenuItem value="pieces">Pieces</MenuItem>
            </Select>
            <FormHelperText>
              Choose the unit that corresponds to the Amount (e.g., 'g' for grams, 'fl oz' for fluid ounces).
            </FormHelperText>
          </FormControl>
        </div>
      </div>
    </div>
  );

  /*
          <TextField
            variant="outlined"
            label="Size"
            helperText={
              sizeError.error
                ? sizeError.message
                : "Optional: Add a descriptive size if applicable (e.g., 'large' for eggs, 'medium' for onions)."
            }
            fullWidth
            className={classes.amountSize}
            value={size}
            onChange={(e) => handleSizeChange(e.target.value)}
            error={sizeError.error}
          />

  */

  const finalSection = (
    <Fragment>
      <div className={classes.optionalSection}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Optional:
        </Typography>
      </div>
      <div>
        {showPurchaseInfo ? (
          <div>
            <Typography>Purchase Info</Typography>
            <IconButton onClick={() => setShowPurchaseInfo(false)} size="large">
              X
            </IconButton>
          </div>
        ) : (
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className={classes.optionalButton}
            onClick={() => setShowPurchaseInfo(true)}
          >
            Add Purchase Info
          </Button>
        )}
      </div>

      <div className={classes.section}>
        <Button fullWidth variant="contained" disableElevation className={classes.optionalButton}>
          Add Notes
        </Button>
      </div>
    </Fragment>
  );

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit}>
        {section === 0 && searchSection}
        {section === 1 && amountSection}
        {section === 2 && itemInfoSection}

        {section === 3 && finalSection}

        <div className={classes.navRoot}>
          {section !== 0 && (
            <Button
              onClick={() => setSection(section - 1)}
              variant="outlined"
              disableElevation
              className={classes.navButton}
            >
              Back
            </Button>
          )}
          {section === 0 && (
            <Fragment>
              <div></div>
              <Button
                onClick={() => setSection(section + 1)}
                variant="contained"
                color="primary"
                disableElevation
                className={classes.navButton}
                disabled={!addToPantry.selected}
              >
                Next
              </Button>
            </Fragment>
          )}

          {(section === 1 || section === 2) && (
            <Button
              onClick={() => setSection(section + 1)}
              variant="contained"
              color="primary"
              disableElevation
              className={classes.navButton}
            >
              Next
            </Button>
          )}
          {section === 3 && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              className={classes.submitButton}
              onClick={() => handleSubmit()}
            >
              Add to Pantry
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

IndividualItem.propTypes = {
  pantry: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps, {})(IndividualItem);
