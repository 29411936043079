import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography } from "@mui/material";
import { askNotificationPerms } from "../../sw";

//Actions
import { getAllNotifications, resetAllNotifications } from "../../actions/notifications";

//Components
import NotificationManager from "./NotificationManager";
import Spinner from "../layout/Spinner";

const NotificationsPage = ({
  notifications: { unread, notifications, error, loading },
  getAllNotifications,
  resetAllNotifications,
}) => {
  useEffect(() => {
    askNotificationPerms();
    getAllNotifications();

    return () => {
      resetAllNotifications();
    };
  }, []);

  //Get notifications
  return (
    <div className="w-full mt-[5rem] mb-[4rem] md:w-[calc(100%-5rem)] md:ml-[5rem] md:mt-0">
      <div className="m-auto mt-8 max-w-[750px]">
        <div className="mx-4 md:mx-0">
          <Typography variant="h5" className="font-normal">
            Notifications
          </Typography>
          {unread && unread > 0 ? (
            <Typography
              variant="subtitle1"
              className="text-secondaryText italic leading-none font-normal mt-1"
            >{`${unread.toLocaleString()} unread`}</Typography>
          ) : null}
        </div>
        <div className="border border-separator md:rounded-[16px] mt-8">
          {loading ? (
            <Spinner />
          ) : error ? (
            <div className="h-full flex justify-center items-center text-center py-8 px-4">
              <Typography variant="subtitle1">An error has occurred. Please refresh the page and try again.</Typography>
            </div>
          ) : (
            <>
              {notifications.length > 0 ? (
                notifications.map((notification, i) => (
                  <NotificationManager
                    key={notification._id}
                    notification={notification}
                    index={i}
                    isLast={i === notifications.length - 1 ? true : false}
                  />
                ))
              ) : (
                <div>No notifications</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

NotificationsPage.propTypes = {
  notifications: PropTypes.object.isRequired,
  getAllNotifications: PropTypes.func.isRequired,
  resetAllNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps, { getAllNotifications, resetAllNotifications })(NotificationsPage);
