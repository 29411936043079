import React, { useEffect, useState, Fragment } from "react";

import { styled } from "@mui/material/styles";

// FA Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

//MUI Components
import IconButton from "@mui/material/IconButton";

//Redux actions
import { showRegisterModal } from "../../actions/auth";
import { setSignupStage } from "../../actions/register";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

//Compoents
import Initial from "./register/Initial";
import BasicInfoStandalone from "./register/BasicInfoStandalone";

const PREFIX = "Register";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  nav: `${PREFIX}-nav`,
  navButton: `${PREFIX}-navButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.container}`]: {
    maxWidth: "50vw",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100vw",
    },
  },

  [`& .${classes.nav}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.navButton}`]: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const Register = ({ showRegisterModal, setSignupStage, auth: { isAuthenticated }, register: { stage } }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <Root>
      {isAuthenticated ? (
        <Navigate to="/"></Navigate>
      ) : (
        <div className={classes.root}>
          <div className={classes.container}>
            <form onSubmit={handleSubmit}>
              {stage !== -1 && (
                <div className={classes.nav}>
                  <IconButton className={classes.navButton} disableRipple size="large">
                    <FontAwesomeIcon icon={faChevronLeft} onClick={() => setSignupStage(stage - 1)} />
                  </IconButton>
                </div>
              )}
              {stage === -1 && <Initial />}
              {stage === 0 && <BasicInfoStandalone />}
            </form>
          </div>
        </div>
      )}
    </Root>
  );
};

Register.propTypes = {
  showRegisterModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  setSignupStage: PropTypes.func.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  auth: state.auth,
  register: state.register,
});

export default connect(mapStateToProps, { showRegisterModal, setSignupStage })(Register);
