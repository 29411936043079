import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Button, TextField } from "@mui/material";

//Actions
import { deleteSection, editSectionTitle } from "../../../../actions/createrecipe";

const RecipeEditSectionModal = ({ section, sectionIndex, deleteSection, editSectionTitle, handleClose }) => {
  const [title, setTitle] = useState(section.title);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const [deleteAreYouSure, setDeleteAreYouSure] = useState(false);

  const handleDelete = () => {
    deleteSection(sectionIndex);
    handleClose();
  };

  const handleSave = (e) => {
    //Stop higher forms from firing
    e.stopPropagation();
    e.preventDefault();
    editSectionTitle(sectionIndex, title.trim());
    handleClose();
  };

  return (
    <div className="mx-4 mb-4">
      <form onSubmit={(e) => handleSave(e)}>
        <div>
          <Typography variant="h6">Edit Section</Typography>
        </div>
        <div className="my-4">
          <TextField value={title} onChange={(e) => handleTitleChange(e)} variant="outlined" fullWidth label="Title" />
        </div>
        <div className="flex flex-row justify-between mt-2">
          {deleteAreYouSure ? (
            <Button variant="outlined" color="danger" onClick={() => handleDelete()}>
              <span className="text-red">Yes, Delete Section</span>
            </Button>
          ) : (
            <Button variant="outlined" color="primary" onClick={() => setDeleteAreYouSure(true)}>
              Delete Section
            </Button>
          )}

          <Button variant="contained" color="primary" disableElevation type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

RecipeEditSectionModal.propTypes = {
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  deleteSection: PropTypes.func.isRequired,
  editSectionTitle: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { deleteSection, editSectionTitle })(RecipeEditSectionModal);
