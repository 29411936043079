import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { Link } from "react-router-dom";

import { Typography, IconButton, Button, Tooltip, Divider } from "@mui/material";

//Shadcn
import { Badge } from "../../../ui/badge";

//Components
//import ImageGallery from "react-image-gallery";
import useEmblaCarousel from "embla-carousel-react";
//Icons

import { ReactComponent as EllipsisIcon } from "../../../../assets/icons/svg/ellipsis.svg";
import { ReactComponent as StarSolidIcon } from "../../../../assets/icons/svg/star-solid.svg";
//import { ReactComponent as FilledBookmarkIcon } from "../../../../assets/icons/svg/bookmark-solid.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

//Sections
import RecipeIngredients from "./subsections/RecipeIngredients";
import RecipeSteps from "./subsections/RecipeSteps";
import RecipeQuickNutrition from "./subsections/RecipeQuickNutrition";
import RecipeMacros from "./subsections/RecipeMacros";
import RecipeAllergens from "./subsections/RecipeAllergens";
import RecipeDiets from "./subsections/RecipeDiets";

//Components
import RecipeTag from "./subsections/RecipeTag";
import StandalonePostInteraction from "../../../layout/postinteraction/standalone/StandalonePostInteraction";
import CustomModal from "../../../modal/CustomModal";
import MaxLinesTextBlock from "../../../layout/maxlinestextblock/MaxLinesTextBlock";

//Icons
import { ReactComponent as StarIcon } from "../../../../assets/icons/svg/star-solid.svg";
//import { ReactComponent as ChefIcon } from "../../../../assets/icons/svg/utensils-light.svg";
import { ChefHat as ChefIcon } from "lucide-react";

//Default PFP
import ProfileImg from "../../../../assets/default/user.jpg";

//Actions
import { showRegisterModal } from "../../../../actions/auth";

//Utils
import { preventWidows } from "../../../../utils/text";
import { shortInteractionCount } from "../../../../utils/post";
import RecipeReviewsComments from "./subsections/RecipeReviewsComments";

//Modals
import RecipeOptionsModal from "./subsections/RecipeOptionsModal";
import RecipePageAd from "../../../ads/recipe/standalone/RecipePageAd";

//Utility Functions
function timeSince(isoDate) {
  const date = new Date(isoDate);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (isNaN(seconds)) {
    return "Invalid date";
  }

  if (seconds < 60) {
    return "Just now";
  }

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval + " year" + (interval > 1 ? "s" : "") + " ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + " month" + (interval > 1 ? "s" : "") + " ago";
  }

  interval = Math.floor(seconds / 604800);
  if (interval >= 1) {
    return interval + " week" + (interval > 1 ? "s" : "") + " ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + " day" + (interval > 1 ? "s" : "") + " ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + " hour" + (interval > 1 ? "s" : "") + " ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + " minute" + (interval > 1 ? "s" : "") + " ago";
  }

  return seconds + " seconds ago";
}

const RecipeStandalone = ({
  recipe,
  auth: { isAuthenticated, user },
  showRegisterModal,
  margin = "",
  rounded = true,
}) => {
  const [emblaRef] = useEmblaCarousel({ loop: true });

  const [showNutrition, setShowNutrition] = useState(false);

  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const checkShowNutrition = () => {
    if (recipe && recipe?.nutrition) {
      let macros = false;
      let allergens = false;
      let friendlyDiets = false;

      if (recipe.nutrition.macros) {
        //Loop over each key, if one is not null set to true and return
        for (let key in recipe.nutrition.macros) {
          if (recipe.nutrition.macros[key] !== null) {
            macros = true;
            break;
          }
        }
      }

      if (recipe.nutrition.allergens) {
        if (recipe.nutrition.allergens.contains.length > 0 || recipe.nutrition.allergens.mayContain.length > 0) {
          allergens = true;
        }
      }

      if (recipe.nutrition.friendlyDiets.length > 0) {
        friendlyDiets = true;
      }

      if (macros || allergens || friendlyDiets) {
        setShowNutrition(true);
      }
    } else {
      setShowNutrition(false);
    }
  };

  useEffect(() => {
    checkShowNutrition();
  }, []);

  console.log(recipe);

  return (
    <div className={`flex flex-col w-full md:my-10 md:mx-auto md:max-w-[600px] lg:max-w-full ${margin}`}>
      <Helmet>
        <title>{`${recipe.name} by @${recipe.user.username}`}</title>
      </Helmet>
      <CustomModal
        open={showOptionsModal}
        handleClose={() => setShowOptionsModal(false)}
        contentStyleOverride={{ marginBottom: 0, minHeight: 0 }}
      >
        <RecipeOptionsModal recipe={recipe} handleClose={() => setShowOptionsModal(false)} />
      </CustomModal>

      <div className="flex flex-col w-full md:max-w-[600px] lg:max-w-full lg:flex-row md:justify-center lg:gap-4">
        <div
          className={`flex flex-col w-full lg:w-1/2 lg:min-w-[450px] lg:max-w-[30vw] xl:max-w-[] md:mb-4 md:border md:border-separator lg:h-fit${
            rounded && " md:rounded-[16px]"
          }`}
        >
          {recipe && recipe.data && recipe.data.length > 0 && (
            <>
              {recipe.data.length === 1 ? (
                <div className="w-full">
                  <img
                    src={recipe.data[0].url}
                    alt={`${recipe.name}`}
                    className={`w-full h-full object-cover ${rounded && " md:rounded-[16px]"}`}
                  />
                </div>
              ) : (
                <div className="flex flex-col h-auto w-full justify-start">
                  <div className={`w-full mx-auto overflow-hidden relative ${rounded && " md:rounded-[16px]"}`}>
                    <div className="overflow-hidden" ref={emblaRef}>
                      <div className="flex">
                        {recipe.data.map((image, index) => (
                          <div className="flex-none w-full min-w-0" key={index}>
                            <img
                              src={image.url}
                              alt={`${recipe.name} picture ${index + 1}`}
                              className="w-full h-full object-cover"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="flex-grow flex flex-col justify-between w-full lg:justify-normal lg:flex-grow-0">
            <div className="p-4 h-full flex flex-col justify-between md:p-8 lg:h-fit">
              <div className="flex flex-row justify-between mb-4 min-h-12">
                <div>
                  <Typography variant="h5" className="font-normal font-sans leading-tight w-[70%]">
                    {preventWidows(recipe && recipe.name ? recipe.name : "")}
                  </Typography>
                  <div className="flex flex-row gap-4 mt-3">
                    {recipe && recipe.interactions && recipe.interactions.reviewsData && (
                      <>
                        <Tooltip
                          title={
                            recipe.interactions.reviewsData.aggregatedRating !== -1
                              ? `This recipe has been rated ${
                                  recipe.interactions.reviewsData.aggregatedRating
                                } stars with ${shortInteractionCount(
                                  recipe.interactions.reviewsData.reviews.total
                                )} review${recipe.interactions.reviewsData.reviews.total !== 1 ? "s" : ""}`
                              : "This recipe has not been rated yet"
                          }
                          placement="top-start"
                          arrow
                        >
                          <div className="flex flex-row items-center cursor-pointer select-none">
                            <div className="w-full">
                              <StarIcon className="h-[1rem] fill-rating mr-1" />
                            </div>
                            <div className="w-full flex justify-center">
                              <Typography
                                variant="body2"
                                className="font-normal break-keep whitespace-nowrap select-none text-secondaryText leading-none"
                              >
                                {recipe.interactions.reviewsData.aggregatedRating !== -1
                                  ? recipe.interactions.reviewsData.aggregatedRating
                                  : "-"}
                              </Typography>
                            </div>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={`This recipe has been made ${shortInteractionCount(
                            recipe.interactions.reviewsData.timesCooked
                          )} time${recipe.interactions.reviewsData.timesCooked !== 1 && "s"}`}
                          placement="top-start"
                          arrow
                        >
                          <div className="flex flex-row items-center justify-between cursor-pointer select-none">
                            <div className="w-full">
                              <ChefIcon className="h-[1rem] text-primaryText mr-1" />
                            </div>

                            <div className="w-full flex justify-center">
                              <Typography
                                variant="body2"
                                className="font-normal select-none text-secondaryText leading-none"
                              >
                                {recipe.interactions.reviewsData.timesCooked > 0
                                  ? shortInteractionCount(recipe.interactions.reviewsData.timesCooked)
                                  : "-"}
                              </Typography>
                            </div>
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>

                <div className="flex flex-col items-end justify-between">
                  <IconButton
                    className="pr-0 hover:bg-transparent"
                    disableRipple
                    size="large"
                    onClick={() => setShowOptionsModal(true)}
                  >
                    <EllipsisIcon className="w-4 fill:secondaryText hover:fill-primaryText/70" />
                  </IconButton>
                </div>
              </div>
              <div>
                <div className="flex flex-row justify-between items-end mt-4">
                  <div className="flex flex-row items-center gap-2">
                    {recipe && recipe.user && recipe.user.picture !== "" ? (
                      <img
                        src={recipe.user.picture}
                        alt={`${recipe.user.username}'s profile picture`}
                        className="h-[1.5rem] w-[1.5rem] object-cover border border-primaryText rounded-[50%]"
                      ></img>
                    ) : (
                      <img
                        src={ProfileImg}
                        alt={`${recipe && recipe.user.username}'s profile picture`}
                        className="h-[1.5rem] w-[1.5rem] object-cover border border-primaryText rounded-[50%]"
                      ></img>
                    )}
                    <Typography variant="body1" className="text-primaryText font-normal leading-none">
                      <Link to={`/${recipe.user.username}`}>{`@${recipe.user.username}`}</Link>
                    </Typography>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="flex flex-col items-end w-full h-full justify-end">
                      {isAuthenticated ? (
                        <Link to={`/cookbook/${recipe._id}`} className="w-full">
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            disableRipple
                          >
                            Cook Now
                          </Button>
                        </Link>
                      ) : (
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          disableElevation
                          disableRipple
                          onClick={() => showRegisterModal()}
                        >
                          Cook Now
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-b-2 border-separator md:border-none">
              {recipe.postData && <StandalonePostInteraction postData={recipe.postData} recipeID={recipe._id} />}
            </div>
          </div>
          {false && (
            <div className="hidden md:block h-full">
              <RecipePageAd />
            </div>
          )}
        </div>
        <div className="md:flex md:flex-col md:gap-4 w-full lg:w-1/2 lg:max-w-[50vw] xl:max-w-[40vw] flex-grow">
          <div className="px-4 py-8 border-t-0 md:p-8 md:border md:border-separator md:rounded-[16px]">
            <Typography variant="h6" className="font-normal font-sans leading-tight">
              About the Recipe:
            </Typography>

            {recipe.description && recipe.description.length > 0 && (
              <div className="my-4">
                <MaxLinesTextBlock
                  text={recipe.description}
                  maxLines={5}
                  variant="body1"
                  buttonVariant="body2"
                  textStyles="font-normal leading-tight"
                />
              </div>
            )}

            <div className="flex flex-row items-center w-full my-2">
              {recipe && recipe?.cuisine && (
                <div className="w-1/2">
                  <Typography variant="subtitle1" className="font-normal ">
                    Cuisine:
                  </Typography>
                  <Typography variant="body1" component="p" className="text-secondaryText font-normal">
                    {recipe.cuisine.name}
                  </Typography>
                </div>
              )}
              {recipe && recipe?.course && (
                <div className="w-1/2">
                  <Typography variant="subtitle1" className="font-normal ">
                    Course:
                  </Typography>
                  <Typography variant="body1" component="p" className="text-secondaryText font-normal">
                    {recipe.course.name}
                  </Typography>
                </div>
              )}
            </div>

            <div className="flex flex-row items-start w-full my-4">
              <div className="flex flex-col w-1/2">
                <Typography variant="subtitle1" className="font-normal ">
                  Timing:
                </Typography>
                {recipe && recipe.timing && recipe.timing.prep && (
                  <Typography variant="body1" component="p" className="text-secondaryText font-normal">
                    {`Prep: ${recipe.timing.prep.hours > 0 ? `${recipe.timing.prep.hours} hrs ` : ""}${
                      recipe.timing.prep.minutes
                    } mins`}
                  </Typography>
                )}
                {recipe && recipe.timing && recipe.timing.cook && (
                  <Typography variant="body1" component="p" className="text-secondaryText font-normal">
                    {`Cook: ${recipe.timing.cook.hours > 0 ? `${recipe.timing.cook.hours} hrs ` : ""}${
                      recipe.timing.cook.minutes
                    } mins`}
                  </Typography>
                )}
                {recipe && recipe.timing && recipe.timing.total && (
                  <Typography variant="body1" component="p" className="text-secondaryText font-normal">
                    {`Total: ${recipe.timing.total.hours > 0 ? `${recipe.timing.total.hours} hrs ` : ""}${
                      recipe.timing.total.minutes
                    } mins`}
                  </Typography>
                )}
              </div>
              {recipe.servings ? (
                <div className="w-1/2">
                  <Typography variant="subtitle1" className="font-normal ">
                    Servings:
                  </Typography>
                  <Typography variant="body1" component="p" className="text-secondaryText font-normal">
                    {recipe.servings}
                  </Typography>
                </div>
              ) : null}
            </div>
            {recipe.tags && recipe.tags.length > 0 && (
              <div className="mt-4">
                <Typography variant="subtitle1" className="font-normal">
                  Tags:
                </Typography>
                <div className="mt-2">
                  {recipe.tags.map((tag, i) => {
                    return <RecipeTag tag={tag} key={i} />;
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="px-4 py-8 border-t md:border border-separator md:p-8 md:rounded-[16px]">
            <Typography variant="h6" className="font-normal font-sans leading-tight">
              Ingredients:
            </Typography>
            <RecipeIngredients recipe={recipe} />
          </div>
          <div className="px-4 py-8 border-t md:border border-separator md:p-8 md:rounded-[16px]">
            <Typography variant="h6" className="font-normal font-sans leading-tight">
              Instructions:
            </Typography>
            <RecipeSteps recipe={recipe} />
          </div>
          {showNutrition &&
            recipe.nutrition &&
            (recipe.nutrition?.allergens || recipe.nutrition?.friendlyDiets || recipe.nutrition?.macros) && (
              <div className="px-4 py-8 flex flex-col gap-4 border-t-4 md:border border-separator md:p-8 md:rounded-[16px]">
                {recipe.nutrition?.macros && (
                  <RecipeMacros macros={recipe.nutrition.macros} username={recipe.user.username} />
                )}
                {recipe.nutrition?.allergens &&
                  (recipe.nutrition.allergens.contains.length > 0 ||
                    recipe.nutrition.allergens.mayContain.length > 0) && (
                    <RecipeAllergens allergens={recipe.nutrition.allergens} username={recipe.user.username} />
                  )}
                {recipe.nutrition?.friendlyDiets && recipe.nutrition.friendlyDiets.length > 0 && (
                  <RecipeDiets diets={recipe.nutrition.friendlyDiets} username={recipe.user.username} />
                )}
              </div>
            )}
          <div className="mb-[4rem] md:mb-0">
            {recipe.interactions && recipe.interactions.reviewsData && (
              <RecipeReviewsComments
                recipe={recipe}
                reviewsData={{ ...recipe.interactions.reviewsData, hasCooked: recipe.interactions.hasCooked }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

RecipeStandalone.propTypes = {
  auth: PropTypes.object.isRequired,
  recipe: PropTypes.object.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { showRegisterModal })(RecipeStandalone);
