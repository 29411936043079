import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//MUI
import { Typography, Button, TextField, Divider } from "@mui/material";

//Components
import RecipeCard from "../recipes/card/RecipeCard";
import RecipeCardSkeleton from "../recipes/card/RecipeCardSkeleton";
import Spinner from "../layout/Spinner";
import MainSection from "./components/main/MainSection";
import SelectableButtonGroup from "../layout/SelectableButtonGroup";
import CustomModal from "../modal/CustomModal";
import FiltersModal from "./components/modal/FiltersModal";

//Placeholder resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";

//Actions
import { getCookbook, recipeSearch, setCookbookQuery } from "../../actions/cookbook";
//TO DO:
//Fetch recipes by section from DB
//Display recipes in each section
//Standalone section pages?
const Cookbook = ({ cookbook, getCookbook, recipeSearch, setCookbookQuery }) => {
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState("search");
  useEffect(() => {
    window.scrollTo(0, 0);
    getCookbook();
    setCookbookQuery("Search for a recipe...");
    return () => {
      if (cookbook.search.query === "Search for a recipe..." || cookbook.search.query === "Generate a recipe...") {
        setCookbookQuery("");
      }
    };
  }, []);

  const handleSearch = () => {
    //Catch if user has not entered a search query, reset state
    if (cookbook.search.query === "Search for a recipe..." || cookbook.search.query === "Generate a recipe...") {
      setCookbookQuery("");
      recipeSearch("", cookbook.search.filters);
    } else {
      recipeSearch(cookbook.search.query, cookbook.search.filters);
    }
    navigate("/cookbook/search");
  };

  //Search Button Options
  const searchButtonOptions = [
    {
      label: "Search",
      onClick: () => {
        handleSearch();
      },
      onSelect: () => {
        setCookbookQuery("Search for a recipe...");
        setSearchType("search");
      },
    },
    {
      label: "Generate",
      onClick: () => {
        navigate(`/create/recipe?type=generate?prompt=${cookbook.search.query}`);
      },
      onSelect: () => {
        setCookbookQuery("Generate a recipe...");
        setSearchType("generate");
      },
    },
  ];

  const [showFilterModal, setShowFilterModal] = useState(false);

  const toggleFilterModal = (state) => {
    setShowFilterModal(state);
  };

  const handleSearchFocus = () => {
    if (cookbook.search.query === "Search for a recipe..." || cookbook.search.query === "Generate a recipe...") {
      setCookbookQuery("");
    }
  };

  const handleSearchBlur = () => {
    if (cookbook.search.query === "") {
      if (searchType === "search") {
        setCookbookQuery("Search for a recipe...");
      } else {
        setCookbookQuery("Generate a recipe...");
      }
    }
  };

  //State
  return (
    <div className="w-full my-[5rem] pl-4 mr-0 md:my-8 md:pl-[7rem]">
      <CustomModal open={showFilterModal} handleClose={() => toggleFilterModal(false)}>
        <FiltersModal handleClose={() => toggleFilterModal(false)} />
      </CustomModal>
      <div className="p-4 md:p-8 rounded-lg border border-separator min-h-[150px] flex flex-col justify-between mr-4 md:mr-8 w-[calc(100%-1rem)] md:w-[calc(100%-2rem)] mb-8 shadow-sm">
        <TextField
          InputProps={{
            disableUnderline: true,
            classes: {
              input: "font-bold text-3xl md:text-4xl min-h-[50px] leading-tight",
            },
          }}
          value={cookbook.search.query}
          onChange={(e) => setCookbookQuery(e.target.value)}
          multiline
          maxRows={4}
          minRows={4}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          variant="standard"
          className="w-full h-full scrollbar"
        />
        <div className="flex flex-row justify-between items-end">
          <Button
            disableRipple
            className="hover:bg-transparent p-0 text-secondaryText leading-none"
            onClick={() => toggleFilterModal(true)}
          >
            Edit Search
          </Button>
          <div className="md:ml-16">
            <SelectableButtonGroup options={searchButtonOptions} buttonSize="large" buttonTextClasses="font-medium" />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-8">
        {false && cookbook.results.onHand && <MainSection reference="onHand" title="On-Hand" standaloneURL="onhand" />}
        {cookbook.results.recentlyCooked && (
          <MainSection reference="recentlyCooked" title="Recently Cooked" standaloneURL="recent" />
        )}
        {cookbook.results.myRecipes && (
          <MainSection reference="myRecipes" title="My Recipes" standaloneURL="myrecipes" />
        )}
        {cookbook.results.bookmarked && (
          <MainSection reference="bookmarked" title="Bookmarked" standaloneURL="bookmarked" />
        )}
        {cookbook.results.lovedByFriends && (
          <MainSection reference="lovedByFriends" title="Loved By Friends" standaloneURL="loved" />
        )}
        {cookbook.results.trending && <MainSection reference="trending" title="Trending" standaloneURL="trending" />}
        {cookbook.results.favourites && (
          <MainSection reference="favourites" title="Favourites" standaloneURL="favourites" />
        )}
      </div>
    </div>
  );
};

Cookbook.propTypes = {
  getCookbook: PropTypes.func.isRequired,
  cookbook: PropTypes.object.isRequired,
  recipeSearch: PropTypes.func.isRequired,
  setCookbookQuery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cookbook: state.cookbook,
});

export default connect(mapStateToProps, { getCookbook, recipeSearch, setCookbookQuery })(Cookbook);
