import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  List,
  Divider,
  IconButton,
} from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronDown, faChevronUp, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as EditIcon } from "../../../../assets/icons/svg/edit.svg";

//Components
import CustomModal from "../../../modal/CustomModal";
import RecipeSectionIngredient from "./RecipeSectionIngredient";
import RecipeSectionStep from "./RecipeSectionStep";
import RecipeEditSectionModal from "./RecipeEditSectionModal";

//Actions
import { processIngredients, addStep } from "../../../../actions/createrecipe";
import { setAlert } from "../../../../actions/alert";

const RecipeSection = ({ processIngredients, addStep, section, sectionIndex, recipe, setAlert }) => {
  //Ingredient Input State
  const [ingredientInput, setIngredientInput] = useState("");

  //Focus state
  const [highlightIngredient, setHighlightIngredient] = useState(false);
  const [highlightStep, setHighlightStep] = useState(false);

  const handleIngredientsSubmit = (ingredientText, sectionIndex) => {
    // Split the text into lines and process each line
    var lines = ingredientText
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0 && !/^[\s\p{P}]*$/.test(line));

    const added = processIngredients(lines, sectionIndex);

    if (added) {
      setIngredientInput("");
      setHighlightIngredient(false);
    }
  };

  const handleIngredientsChange = (e) => {
    setIngredientInput(e.target.value);
  };

  //Steps Input State
  const [stepInput, setStepInput] = useState("");

  const handleStepSubmit = (index) => {
    //Trim text
    let trimmed = stepInput.trim();

    if (trimmed === "") {
      setAlert("Step cannot be empty", "warning");
      return;
    }

    addStep(index, trimmed);

    setStepInput("");
    setHighlightStep(false);
  };

  const handleStepChange = (e) => {
    setStepInput(e.target.value);
  };

  const [expanded, setExpanded] = useState(true);
  const [titleHover, setTitleHover] = useState(false);

  //Modal state
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);

  const handleShowEditSectionModal = () => {
    setShowEditSectionModal(true);
  };

  const handleCloseEditSectionModal = () => {
    setShowEditSectionModal(false);
  };

  const handleTitleMouseEnter = () => {
    setTitleHover(true);
  };

  const handleTitleMouseLeave = () => {
    setTitleHover(false);
  };

  return (
    <div key={sectionIndex}>
      <CustomModal open={showEditSectionModal} handleClose={handleCloseEditSectionModal}>
        <RecipeEditSectionModal
          section={section}
          sectionIndex={sectionIndex}
          handleClose={handleCloseEditSectionModal}
        />
      </CustomModal>
      <div className="flex flex-row justify-between mt-4">
        <div className="flex flex-row items-center mb-4">
          <Typography
            variant="h5"
            className="text-primaryText cursor-pointer"
            onClick={handleShowEditSectionModal}
            onMouseEnter={handleTitleMouseEnter}
            onMouseLeave={handleTitleMouseLeave}
          >
            {section.title}
          </Typography>
          {titleHover && <EditIcon className="h-4 fill-secondaryText ml-2" />}
        </div>

        {expanded ? (
          <IconButton size="small" disableRipple className="hover:bg-transparent" onClick={() => setExpanded(false)}>
            <FontAwesomeIcon icon={faChevronDown} />
          </IconButton>
        ) : (
          <IconButton size="small" disableRipple className="hover:bg-transparent" onClick={() => setExpanded(true)}>
            <FontAwesomeIcon icon={faChevronUp} />
          </IconButton>
        )}
      </div>

      {section &&
        section?.source &&
        (section.source?.type ? (
          <div>
            <Typography variant="" className="text-secondaryText">
              {section.source.type}:
            </Typography>
            <Typography className="text-primaryText">{section.source.text}</Typography>
            {section.source.url && (
              <div className="flex flex-row items-center w-full">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-secondaryText h-[0.75em] mr-2" />
                <a href={section.source.url} target="_blank" rel="noopener noreferrer" className="truncate">
                  <Typography
                    variant="subtitle2"
                    className="font-normal text-mainGreen hover:underline hover:font-medium hover:italic"
                  >
                    {section.source.url}
                  </Typography>
                </a>
              </div>
            )}
          </div>
        ) : null)}

      <Divider className="py-2" />

      {expanded && (
        <div className="mt-2 mb-4">
          <div className="mt-4">
            <div className="text-primaryText">
              <Typography variant="subtitle1">Ingredients:</Typography>
            </div>
            <div>
              <List className="list-disc pl-4">
                {section.ingredients.map((ingredient, ingredientIndex) => (
                  <RecipeSectionIngredient
                    key={ingredientIndex}
                    ingredient={ingredient}
                    ingredientIndex={ingredientIndex}
                    sectionIndex={sectionIndex}
                  />
                ))}
              </List>
            </div>
            <div className="my-2">
              <TextField
                fullWidth
                label="Add Ingredient"
                variant="outlined"
                value={ingredientInput}
                onChange={(e) => handleIngredientsChange(e)}
                onFocus={() => setHighlightIngredient(true)}
                onBlur={() => setHighlightIngredient(ingredientInput.length > 0)}
                InputProps={{
                  startAdornment:
                    recipe.create &&
                    recipe.create.ingredientsProcessing &&
                    recipe.create.ingredientsProcessing.loading ? (
                      <InputAdornment position="start">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ) : null,
                }}
                disabled={recipe.create.ingredientsProcessing.loading}
              />
              <Button
                className="mt-2"
                variant={highlightIngredient ? "contained" : "outlined"}
                color={highlightIngredient ? "primary" : "default"}
                disableElevation
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                disableRipple
                onClick={() => handleIngredientsSubmit(ingredientInput, sectionIndex)}
                disabled={recipe.create.ingredientsProcessing.loading}
                style={{ color: highlightIngredient ? "white" : "#362e2d" }}
              >
                Add Ingredient
              </Button>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-primaryText">
              <Typography variant="subtitle1">Steps:</Typography>
            </div>
            <div>
              <List className="list-decimal pl-4">
                {section.steps.map((step, stepIndex) => (
                  <RecipeSectionStep key={stepIndex} step={step} stepIndex={stepIndex} sectionIndex={sectionIndex} />
                ))}
              </List>
            </div>
            <div className="my-2">
              <TextField
                fullWidth
                label="Add Step"
                variant="outlined"
                onFocus={() => setHighlightStep(true)}
                onBlur={() => setHighlightStep(stepInput.length > 0)}
                value={stepInput}
                onChange={(e) => handleStepChange(e)}
                multiline
              />
              <Button
                className="mt-2"
                variant={highlightStep ? "contained" : "outlined"}
                color={highlightStep ? "primary" : "default"}
                disableElevation
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                disableRipple
                onClick={() => handleStepSubmit(sectionIndex)}
                style={{ color: highlightStep ? "white" : "#362e2d" }}
              >
                Add Step
              </Button>
            </div>
          </div>
          <Divider className="py-2" />
        </div>
      )}
    </div>
  );
};

RecipeSection.propTypes = {
  processIngredients: PropTypes.func.isRequired,
  addStep: PropTypes.func.isRequired,
  section: PropTypes.object.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  recipe: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
});

export default connect(mapStateToProps, { processIngredients, addStep, setAlert })(RecipeSection);
