import React from "react";
import { Link } from "react-router-dom";

//MUI
import { Typography, Button } from "@mui/material";

const CommentHighlight = ({ loading, data }) => {
  return (
    <div className="ml-14 mr-2 cursor-pointer mb-4">
      <div className="flex flex-col items-start">
        <Typography variant="caption" className="text-secondaryText font-medium leading-tight">{`@${
          data && data.username ? data.username : "loading"
        }`}</Typography>
        <Typography variant="body2" className="break-all w-full break-words">
          {data && data.text}
        </Typography>
        <Button className="p-0 normal-case hover:bg-transparent" disableRipple>
          <Typography variant="caption" className="text-secondaryText font-normal mt-2">
            View all comments
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default CommentHighlight;
