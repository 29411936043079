import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis, Cell } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "../ui/chart";

const chartData = [
  { month: "March", efficiency: 77.3 },
  { month: "April", efficiency: 75.1 },
  { month: "May", efficiency: 82.4 },
  { month: "June", efficiency: 83.8 },
  { month: "July (so far)", efficiency: 88.1 },
];

const chartConfig = {
  efficiency: {
    label: "Efficiency",
    color: "hsl(var(--chart-1))",
  },
  label: {
    color: "hsl(var(--background))",
  },
};

const HorizontalBarChart = () => {
  return (
    <ChartContainer config={chartConfig}>
      <BarChart
        accessibilityLayer
        data={chartData}
        layout="vertical"
        margin={{
          right: 16,
        }}
      >
        <CartesianGrid horizontal={false} />
        <YAxis
          dataKey="month"
          type="category"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
          hide
        />
        <XAxis dataKey="efficiency" type="number" hide />
        <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="line" />} />

        <Bar dataKey="efficiency" layout="vertical" radius={4}>
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`hsl(var(--chart-${chartData.length - index}))`} />
          ))}
          <LabelList
            dataKey="month"
            position="insideLeft"
            offset={8}
            className="fill-[--color-label] font-medium"
            fontSize={14}
          />
          <LabelList dataKey="efficiency" position="right" offset={8} className="fill-secondaryText" fontSize={14} />
        </Bar>
      </BarChart>
    </ChartContainer>
  );
};

export default HorizontalBarChart;
