import React from "react";

import { Typography } from "@mui/material";

const RecipeSteps = ({ recipe }) => {
  return (
    <div>
      <div>
        {recipe.sections.map((section, i) => {
          if (section.steps.length === 0) return null;
          return (
            <div
              key={i}
              className={`py-4 ${i !== recipe.sections.length - 1 ? "pb-4 border-b border-b-separator" : ""}`}
            >
              <Typography variant="subtitle1" className="font-sans font-normal text-primaryText">
                {section.title}
              </Typography>
              <ol className="ml-4">
                {section.steps.map((step, j) => {
                  return (
                    <li key={j} className="mt-2 mb-4 marker:text-mainGreen marker:font-medium">
                      <Typography variant="body1" component="p" className="font-normal pl-2 leading-snug">
                        {step.text}
                      </Typography>

                      {step.tip && (
                        <div className="mt-1">
                          <Typography variant="body2" className="font-normal text-primaryText pl-2">
                            Tip: <span className="italic text-secondaryText">{step.tip}</span>
                          </Typography>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ol>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecipeSteps;
