import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//MUI
import Typography from "@mui/material/Typography";

//Images
import { ReactComponent as Icon } from "../landing/icon.svg";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = ({ auth: { isAuthenticated }, navMargin = true }) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const onNavigation = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      className={`flex flex-col md:flex-row w-full ${
        isAuthenticated && navMargin && "md:ml-[5rem] md:w-[calc(100%-5rem)]"
      } bg-stone-50 border-t border-separator px-4 py-[3.5rem] md:px-10`}
    >
      <div className="mr-[5rem]">
        <div className="inline-block">
          <Link to="/" className="h-full flex items-center no-underline" onClick={() => onNavigation()}>
            <div className="h-full flex items-center">
              <Icon className="fill-mainGreen h-[3rem]"></Icon>
            </div>

            <div className="text-mainGreen flex flex-col ml-2 h-full">
              <Typography className="font-light text-2xl leading-[0.8]">this</Typography>
              <Typography className="font-semibold text-[2rem] leading-[0.7]">Pantry</Typography>
            </div>
          </Link>
          <div className="w-full flex items-center justify-between my-4 text-primaryText">
            <a href="https://www.instagram.com/thispantry" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="h-[1.5rem] w-auto hover:text-[#E1306C]" />
            </a>
            <a href="https://www.facebook.com/thispantry" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="h-[1.5rem] w-auto hover:text-[#1877F2]" />
            </a>
            <a href="https://www.twitter.com/thispantry" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faXTwitter} className="h-[1.5rem] w-auto hover:text-[#000000]" />
            </a>
            <a href="https://youtube.com/@thispantry" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faYoutube} className="h-[1.5rem] w-auto hover:text-[#FF0000]" />
            </a>
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="caption" className="text-secondaryText leading-tight">
            Copyright &copy; 2024
          </Typography>
          <Typography variant="caption" className="text-secondaryText leading-tight">
            thisPantry Inc.
          </Typography>
        </div>
      </div>
      <div className="flex flex-col w-1/2 md:flex-row">
        <div className="my-2 text-primaryText md:mr-[5rem]">
          <Typography variant="h6" className="mb-2">
            Help
          </Typography>
          <Link to="/contact" onClick={() => onNavigation()}>
            <Typography variant="body2" className=" hover:font-medium">
              Contact Us
            </Typography>
          </Link>
        </div>
        <div className="my-2 text-primaryText md:mr-[5rem]">
          <Typography variant="h6" className="mb-2">
            Resources
          </Typography>
          <div className="flex flex-col gap-1">
            <Link to="/privacy" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Privacy
              </Typography>
            </Link>
            <Link to="/terms" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Terms
              </Typography>
            </Link>
            <Link to="/cookies" onClick={() => onNavigation()}>
              <Typography variant="body2" className=" hover:font-medium">
                Cookies
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Footer);
