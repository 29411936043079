import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { LinearProgress } from "@mui/material";

import { Link } from "react-router-dom";

import { ReactComponent as StarIcon } from "../../../../../assets/icons/svg/star-solid.svg";

//Components
import CustomModal from "../../../../modal/CustomModal";
import ReviewModal from "../../../review/ReviewModal";
import RecipeReview from "../../../review/RecipeReview";

//Actions
import { showReviewModal, hideReviewModal } from "../../../../../actions/review";

//Utils
import { shortInteractionCount } from "../../../../../utils/post";

const RecipeReviewsComments = ({
  review: { recipeReviewModal },
  auth: { isAuthenticated, user },
  recipe,
  reviewsData,
  showReviewModal,
  hideReviewModal,
}) => {
  const getDistributionFill = (distributionValue) => {
    const fill = Math.ceil(distributionValue / reviewsData.reviews.total) * 100;
    return fill; //Percentage rounded up to integer 0-100;
  };

  return (
    <div className="px-4 py-8 md:p-8 border-t-4 md:border border-separator md:rounded-[16px]">
      <CustomModal open={recipeReviewModal.open} handleClose={() => hideReviewModal()}>
        <ReviewModal recipe={recipe} reviewsData={reviewsData} handleClose={() => hideReviewModal()} />
      </CustomModal>
      <div className="flex flex-col w-full sm:flex-row">
        <div className="flex flex-col items-start justify-center w-full mb-2 sm:mb-0 sm:w-[50%] sm:items-center">
          <div>
            <div className="flex items-center">
              <StarIcon className="fill-rating h-[2rem] w-[2rem]" />
              <Typography variant="h5" className="font-normal ml-2">
                {reviewsData.aggregatedRating !== -1 ? reviewsData.aggregatedRating : "-"}
              </Typography>
            </div>
            <div className="flex flex-col items-start ml-[2.75rem]">
              <Typography variant="subtitle2" className="text-secondaryText font-normal">
                {shortInteractionCount(reviewsData.reviews.total)} rating{reviewsData.reviews.total !== 1 ? "s" : ""}
              </Typography>
              <Typography variant="subtitle2" className="text-secondaryText font-normal">
                Made {shortInteractionCount(reviewsData.timesCooked)} time
                {shortInteractionCount(reviewsData.timesCooked) !== "1" ? "s" : ""}
              </Typography>
            </div>
          </div>
        </div>
        <Divider orientation="vertical" flexItem className="hidden sm:block mx-4" />
        <div className="w-full sm:w-[50%] flex flex-row">
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <StarIcon className="h-[0.75rem] fill-rating" />
                <Typography variant="subtitle1" className="ml-1 mr-2 text-primaryText">
                  5
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText mr-2">
                {`(${shortInteractionCount(reviewsData.distribution[5])})`}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <StarIcon className="h-[0.75rem] fill-rating" />
                <Typography variant="subtitle1" className="ml-1 mr-2 text-primaryText">
                  4
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText mr-2">
                {`(${shortInteractionCount(reviewsData.distribution[4])})`}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <StarIcon className="h-[0.75rem] fill-rating" />
                <Typography variant="subtitle1" className="ml-1 mr-2 text-primaryText">
                  3
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText mr-2">
                {`(${shortInteractionCount(reviewsData.distribution[3])})`}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <StarIcon className="h-[0.75rem] fill-rating" />
                <Typography variant="subtitle1" className="ml-1 mr-2 text-primaryText">
                  2
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText mr-2">
                {`(${shortInteractionCount(reviewsData.distribution[2])})`}
              </Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center">
                <StarIcon className="h-[0.75rem] fill-rating" />
                <Typography variant="subtitle1" className="ml-1 mr-2 text-primaryText">
                  1
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText mr-2">
                {`(${shortInteractionCount(reviewsData.distribution[1])})`}
              </Typography>
            </div>
          </div>
          <div className="flex-grow flex flex-col justify-around">
            {reviewsData.distribution[5] === 0 ? (
              <div className="w-full">
                <LinearProgress variant="determinate" color="secondaryText" value={0} />
              </div>
            ) : (
              <div className="w-full">
                <LinearProgress variant="determinate" value={getDistributionFill(reviewsData.distribution[5])} />
              </div>
            )}
            {reviewsData.distribution[4] === 0 ? (
              <div className="w-full">
                <LinearProgress variant="determinate" color="secondaryText" value={0} />
              </div>
            ) : (
              <div className="w-full">
                <LinearProgress variant="determinate" value={getDistributionFill(reviewsData.distribution[4])} />
              </div>
            )}
            {reviewsData.distribution[3] === 0 ? (
              <div className="w-full">
                <LinearProgress variant="determinate" color="secondaryText" value={0} />
              </div>
            ) : (
              <div className="w-full">
                <LinearProgress variant="determinate" value={getDistributionFill(reviewsData.distribution[3])} />
              </div>
            )}
            {reviewsData.distribution[2] === 0 ? (
              <div className="w-full">
                <LinearProgress variant="determinate" color="secondaryText" value={0} />
              </div>
            ) : (
              <div className="w-full">
                <LinearProgress variant="determinate" value={getDistributionFill(reviewsData.distribution[2])} />
              </div>
            )}
            {reviewsData.distribution[1] === 0 ? (
              <div className="w-full">
                <LinearProgress variant="determinate" color="secondaryText" value={0} />
              </div>
            ) : (
              <div className="w-full">
                <LinearProgress variant="determinate" value={getDistributionFill(reviewsData.distribution[1])} />
              </div>
            )}
          </div>
        </div>
      </div>

      {recipe?.user?.id !== user?._id && (
        <div className="mt-2">
          {reviewsData.myReview ? (
            <RecipeReview review={reviewsData.myReview} />
          ) : reviewsData.hasCooked ? (
            <div className="my-2">
              <Typography variant="subtitle1">{`What did you think of ${
                recipe && recipe?.name ? recipe.name : "the recipe"
              }?`}</Typography>
              <div style={{ width: "50%" }}>
                <Typography variant="caption" className="text-secondaryText italic">
                  Ratings can be public or private and help us better tailor our recipe suggestions to you
                </Typography>
              </div>
              <Button variant="outlined" disableElevation onClick={() => showReviewModal()} className="my-2">
                + Add a review
              </Button>
            </div>
          ) : (
            <div className="my-2">
              <Typography variant="subtitle1" className="text-secondaryText">
                Cook <span className="text-primaryText italic">{recipe.name}</span>
                <br></br>before leaving a review
              </Typography>
              <Link to={`/cookbook/${recipe._id}`}>
                <Button variant="contained" color="primary" disableElevation className="my-2" size="small">
                  Cook Now
                </Button>
              </Link>
            </div>
          )}
        </div>
      )}

      {reviewsData.reviews.all.length > 0 && (
        <div className="my-2">
          <Typography variant="h6" className="font-normal font-sans leading-tight">
            Reviews:
          </Typography>
          <div className="mt-4">
            {reviewsData.reviews.all.map((review) => (
              <RecipeReview key={review._id} review={review} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

RecipeReviewsComments.propTypes = {
  review: PropTypes.object.isRequired,
  showReviewModal: PropTypes.func.isRequired,
  hideReviewModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  review: state.review,
  auth: state.auth,
});

export default connect(mapStateToProps, { showReviewModal, hideReviewModal })(RecipeReviewsComments);
