import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

//Assets
import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

const Static = () => {
  const onNavigation = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="w-full h-[4rem] bg-white sticky top-0 border-b border-separator flex items-center justify-between px-8 py-4 z-[999]">
      <div className="h-full flex items-center">
        <Link to="/" className="h-full" onClick={() => onNavigation()}>
          <Logo className="fill-mainGreen h-full"></Logo>
        </Link>
      </div>
    </div>
  );
};

export default Static;
