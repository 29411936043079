import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import api from "../../../api/api";

import { Typography, Button } from "@mui/material";

//Actions
import { setAlert } from "../../../actions/alert";

//Icons
import { ReactComponent as ShareIcon } from "../../../assets/icons/svg/share.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/svg/copy.svg";
import { ReactComponent as ReportIcon } from "../../../assets/icons/svg/report.svg";
import { ReactComponent as BlockIcon } from "../../../assets/icons/svg/block.svg";

const ProfileOptionsModal = ({
  auth: { isAuthenticated, user },
  profile: { viewedProfile, currentProfile },
  handleClose,
  setAlert,
}) => {
  const navigate = useNavigate();

  const [isMyProfile, setIsMyProfile] = useState(
    currentProfile && viewedProfile && currentProfile._id === viewedProfile.id ? true : false
  );

  /* Auth -> Not my profile
    - Block
    - Report
    - Copy link
    - Share profile
  */

  /* Auth -> My profile
    Share Profile
    Copy link
  */

  /* No auth
    Copy link
    Share profile
  */

  const handleCopyLink = () => {
    const profileLink = `/${viewedProfile && viewedProfile.username ? viewedProfile.username : ""}`;
    navigator.clipboard.writeText(window.location.origin + profileLink);
    setAlert("Link copied to clipboard", "success");
    handleClose();
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `${viewedProfile && viewedProfile.username ? `@${viewedProfile.username}` : `thisPantry`}`,
          text: `${viewedProfile && viewedProfile.username ? `@${viewedProfile.username} - thisPantry` : `thisPantry`}`,
          url: `/${viewedProfile && viewedProfile.username ? viewedProfile.username : ""}`,
        });
      } catch (error) {
        handleCopyLink();
      }
    } else {
      handleCopyLink();
    }
  };

  const handleBlock = async () => {
    try {
      //Send block request
      const res = await api.post(`/block/`, { id: viewedProfile.id });
      //Set alert
      setAlert("Profile reported", "success");
      //Navigate to home
      navigate("/");
    } catch (error) {
      setAlert("An error occurred. Refresh and try again.", "error");
      navigate("/");
    }
  };

  const handleReport = async () => {
    try {
      const res = await api.post(`/report/`, { id: viewedProfile.id });
      //Set alert
      setAlert("Profile reported", "success");
      //Close modal
      handleClose();
    } catch (error) {
      setAlert("An error occurred. Refresh and try again.", "error");
      handleClose();
    }
  };

  return (
    <div className="flex flex-col align-center justify-center">
      <div className="border-t border-separator">
        <Button
          className="w-full h-16  rounded-none justify-start pl-8"
          onClick={handleShare}
          startIcon={
            <div className="w-8 flex justify-start">
              <ShareIcon className="fill-primaryText h-5" />
            </div>
          }
        >
          <Typography variant="subtitle1" className="font-normal">
            Share Profile
          </Typography>
        </Button>
      </div>
      <div className="border-t border-separator">
        <Button
          className="w-full h-16  rounded-none justify-start pl-8"
          onClick={handleCopyLink}
          startIcon={
            <div className="w-8 flex justify-start">
              <CopyIcon className="fill-primaryText h-5" />
            </div>
          }
        >
          <Typography variant="subtitle1" className="font-normal">
            Copy Link
          </Typography>
        </Button>
      </div>

      {isAuthenticated && !isMyProfile && (
        <>
          <div className="border-t border-separator">
            <Button
              className="w-full h-16 rounded-none justify-start pl-8"
              startIcon={
                <div className="w-8 flex justify-start">
                  <ReportIcon className="fill-red h-5" />
                </div>
              }
              onClick={handleReport}
            >
              <Typography variant="subtitle1" className=" text-normal">
                Report
              </Typography>
            </Button>
          </div>
          <div className="border-t border-separator ">
            <Button
              className="w-full h-16 rounded-none justify-start pl-8"
              startIcon={
                <div className="w-8 flex justify-start">
                  <BlockIcon className="fill-red h-5" />
                </div>
              }
              onClick={handleBlock}
            >
              <Typography variant="subtitle1" className=" text-normal">
                Block
              </Typography>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

ProfileOptionsModal.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { setAlert })(ProfileOptionsModal);
