import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, IconButton } from "@mui/material";

//Components
import CustomModal from "../../../modal/CustomModal";

//Modals
import PostShareModal from "../../../feed/modals/PostShareModal";
import PostCommentModal from "../../../feed/modals/PostCommentModal";

//Icons
import { ReactComponent as HeartIcon } from "../../../../assets/icons/svg/heart.svg";
import { ReactComponent as FilledHeartIcon } from "../../../../assets/icons/svg/heart-solid.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/icons/svg/comment.svg";
import { ReactComponent as ShareIcon } from "../../../../assets/icons/svg/new-message.svg";
import { ReactComponent as CookIcon } from "../../../../assets/icons/svg/kitchen-dashboard.svg";
import { ReactComponent as BookmarkIcon } from "../../../../assets/icons/svg/bookmark.svg";
import { ReactComponent as FilledBookmarkIcon } from "../../../../assets/icons/svg/bookmark_filled.svg";

//Actions
import { showRegisterModal } from "../../../../actions/auth";
import { handleLikeRecipe, handleBookmarkRecipe } from "../../../../actions/recipe";

import { shortInteractionCount } from "../../../../utils/post";

//TO DO:
//- Refactor for regular standalone posts

const StandalonePostInteraction = ({
  postData,
  recipeID,
  auth: { isAuthenticated },
  showRegisterModal,
  handleLikeRecipe,
  handleBookmarkRecipe,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentsCount, setCommentsCount] = useState(postData.interactions.commentsCount);

  const handleLikeClick = () => {
    if (isAuthenticated) {
      if (recipeID) {
        handleLikeRecipe(postData.id);
      } else {
        //TO DO: Standalone post like
      }
    } else {
      showRegisterModal();
    }
  };

  const handleShareClick = () => {
    if (isAuthenticated) {
      setShowShareModal(true);
    } else {
      showRegisterModal();
    }
  };

  const handleBookmarkClick = () => {
    if (isAuthenticated) {
      if (recipeID) {
        handleBookmarkRecipe(recipeID);
      }
    } else {
      showRegisterModal();
    }
  };

  const handleCommentsClick = () => {
    if (isAuthenticated) {
      setShowCommentModal(true);
    } else {
      showRegisterModal();
    }
  };

  const onCommentModalClose = () => {
    setShowCommentModal(false);
  };

  return (
    <div>
      <CustomModal
        open={showShareModal}
        handleClose={() => setShowShareModal(false)}
        contentStyleOverride={{ marginBottom: 0 }}
      >
        <PostShareModal
          post={postData}
          handleClose={() => setShowShareModal(false)}
          blockRepost={postData?.blockRepost ? postData.blockRepost : false}
        />
      </CustomModal>
      <CustomModal open={showCommentModal} handleClose={onCommentModalClose} type="bottom">
        <PostCommentModal
          postid={postData.id}
          handleClose={onCommentModalClose}
          commentCount={postData.interactions.commentsCount}
          setCommentCount={setCommentsCount}
        />
      </CustomModal>
      <div className="w-full flex flex-row justify-between border-t border-separator p-4 md:px-8">
        <div className="flex flex-row">
          <div className="flex flex-col items-center justify-between gap-1 mr-4">
            <div>
              <IconButton
                className="p-0 hover:bg-transparent"
                disableRipple
                onClick={() => handleLikeClick()}
                size="large"
              >
                {postData.interactions.hasLiked ? (
                  <FilledHeartIcon className="h-[1.5rem] fill-mainGreen hover:fill-secondaryText" />
                ) : (
                  <HeartIcon className="h-[1.5rem] hover:fill-secondaryText" />
                )}
              </IconButton>
            </div>

            <Typography variant="body2" className="text-mainGreen">
              {shortInteractionCount(postData.interactions.likesCount)}
            </Typography>
          </div>
          <div className="flex flex-col items-center justify-between gap-1 mr-4">
            <div>
              <IconButton
                className="p-0 hover:bg-transparent"
                disableRipple
                onClick={() => handleCommentsClick()}
                size="large"
              >
                {postData.interactions.hasCommented ? (
                  <CommentIcon className="h-[1.5rem] fill-mainGreen hover:fill-secondaryText" />
                ) : (
                  <CommentIcon className="h-[1.5rem] hover:fill-secondaryText" />
                )}
              </IconButton>
            </div>

            <Typography variant="body2" className="text-mainGreen">
              {shortInteractionCount(commentsCount)}
            </Typography>
          </div>
          <div className="flex flex-col items-center justify-between gap-1 mr-4">
            <div>
              <IconButton
                className="p-0 hover:bg-transparent"
                disableRipple
                onClick={() => handleShareClick()}
                size="large"
              >
                <ShareIcon className="h-[1.5rem] hover:fill-secondaryText" />
              </IconButton>
            </div>

            <Typography variant="body2" className="text-mainGreen">
              {shortInteractionCount(postData.interactions.repostsCount)}
            </Typography>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex flex-col items-center justify-between gap-1 mr-4" style={{ marginRight: 0 }}>
            <div>
              <IconButton
                className="p-0 hover:bg-transparent"
                disableRipple
                onClick={() => handleBookmarkClick()}
                size="large"
              >
                {postData.interactions.hasBookmarked ? (
                  <FilledBookmarkIcon className="h-[1.5rem] fill-mainGreen hover:fill-secondaryText" />
                ) : (
                  <BookmarkIcon className="h-[1.5rem] hover:fill-secondaryText" />
                )}
              </IconButton>
            </div>

            <Typography variant="body2" className="text-mainGreen">
              {shortInteractionCount(postData.interactions.bookmarksCount)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

StandalonePostInteraction.propTypes = {
  postData: PropTypes.object.isRequired,
  recipeID: PropTypes.string,
  commentsRef: PropTypes.object,
  auth: PropTypes.object.isRequired,
  showRegisterModal: PropTypes.func.isRequired,
  handleLikeRecipe: PropTypes.func.isRequired,
  handleBookmarkRecipe: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { showRegisterModal, handleLikeRecipe, handleBookmarkRecipe })(
  StandalonePostInteraction
);
