import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Actions
import { hideRegisterModal } from "../../actions/auth";
import { setSignupStage, abilityNext } from "../../actions/register";

//MUI Components
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

//Icons
import { ReactComponent as NewFeaturesIcon } from "../../assets/icons/svg/tag-light.svg";
import { ReactComponent as InsightsIcon } from "../../assets/icons/svg/chart-line.svg";
import { ReactComponent as PantryIcon } from "../../assets/icons/svg/carrot.svg";
import { ReactComponent as ChefIcon } from "../../assets/icons/svg/chef-sharp-light.svg";
import { ReactComponent as RewardsIcon } from "../../assets/icons/svg/cake-light.svg";
import { ReactComponent as DevIcon } from "../../assets/icons/svg/phone-light.svg";
import { ReactComponent as PriceIcon } from "../../assets/icons/svg/badge-check-light.svg";

//Images
import PlusImage from "./res/plusmodal/plusmodal4.jpg";

const PlusModal = ({ handleClose }) => {
  return (
    <div>
      <div className="w-full aspect-square rounded-lg rounded-b-3xl relative overflow-hidden">
        <div className="absolute inset-0 bg-cover bg-center " style={{ backgroundImage: `url(${PlusImage})` }}></div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
        <div className="flex flex-col items-center justify-end h-full px-8 py-12 relative z-10">
          <div></div>
          <div>
            <Typography variant="h3" className="mb-2 text-white font-medium">
              Be the first to experience the future
            </Typography>
            <Typography variant="subtitle1" className="leading-snug text-secondaryText-100">
              Unlock early access to our premium beta test for $12/month and keep the early access price forever.
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full mb-[7rem] py-8 gap-4 px-8">
        <div className="w-full flex flex-row  gap-4 py-4 items-center">
          <div>
            <ChefIcon className="w-8 fill-mainGreen" />
          </div>
          <div>
            <Typography variant="h6">Generate Custom Recipes Instantly</Typography>
            <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
              Personalized recipes that match your pantry items, helping you make the most of what you have.
            </Typography>
          </div>
        </div>

        <div className="w-full flex flex-row  gap-4 py-4 items-center">
          <div>
            <NewFeaturesIcon className="w-8 fill-mainGreen" />
          </div>
          <div>
            <Typography variant="h6">Exclusive Access to New Features</Typography>
            <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
              Get the inside scoop and be the first to try out new tools and functionalities before they're released to
              the public.
            </Typography>
          </div>
        </div>

        <div className="w-full flex flex-row  gap-4 py-4 items-center">
          <div>
            <DevIcon className="w-8 fill-mainGreen" />
          </div>
          <div>
            <Typography variant="h6">Direct Line to Developers</Typography>
            <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
              Have a say in how thisPantry evolves by giving feedback directly to the team. Your input shapes the
              features you’ll use every day.
            </Typography>
          </div>
        </div>

        <div className="w-full flex flex-row  gap-4 py-4 items-center">
          <div>
            <PriceIcon className="w-8 fill-mainGreen" />
          </div>
          <div>
            <Typography variant="h6">Lifetime Price Guarantee</Typography>
            <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
              Lock in your subscription price forever—no future price hikes, ever.
            </Typography>
          </div>
        </div>

        <div className="w-full flex flex-row  gap-4 py-4 items-center">
          <div>
            <RewardsIcon className="w-8 fill-mainGreen" />
          </div>
          <div>
            <Typography variant="h6">Earn Special Rewards</Typography>
            <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
              Receive exclusive badges, early access credits, and more, as a thank you for being part of our growing
              community.
            </Typography>
          </div>
        </div>
        <div className="my-4">
          <Divider />
        </div>
        <div className="my-4">
          <Typography variant="h5" className=" text-secondaryText font-normal">
            What's coming soon?
          </Typography>
          <div>
            <div className="w-full flex flex-row gap-4 py-8 items-start">
              <div>
                <InsightsIcon className="w-8 fill-mainGreen mt-2" />
              </div>
              <div>
                <Typography variant="h6">Insights</Typography>
                <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
                  Track your cooking habits, kitchen efficiency, and see how you’re saving money over time.
                </Typography>
                <ul className="list-disc list-inside text-secondaryText mt-4">
                  <li className="leading-tight text-sm">Easily track your grocery and restaurant spending.</li>
                  <li className="leading-tight text-sm">
                    Stay on top of your nutritional macros and see how you're doing over time.
                  </li>
                  <li className="leading-tight text-sm">
                    Monitor your food waste and save money by reducing spoilage.
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full flex flex-row gap-4 py-8 items-start">
              <div>
                <PantryIcon className="w-8 fill-mainGreen mt-2" />
              </div>
              <div>
                <Typography variant="h6">Pantry</Typography>
                <Typography variant="subtitle1" className="text-secondaryText leading-tight font-normal">
                  Your pantry, fridge, freezer, all digitized to keep you up to date in real-time. Never miss a
                  Beet!&nbsp;😜
                </Typography>
                <ul className="list-disc list-inside text-secondaryText mt-4">
                  <li className="leading-tight text-sm">Automatically track all of your expiration dates</li>
                  <li className="leading-tight text-sm">
                    Get tailored recipes based on what you have and what you like, before your ingredients expire.
                  </li>
                  <li className="leading-tight text-sm">
                    Easily add ingredients by scanning receipts, barcodes, or by voice.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full border-t border-separator bg-background p-4 flex flex-col rounded-b-lg">
        <Link to="/plus/upgrade">
          <Button size="large" variant="contained" color="primary" fullWidth disableElevation>
            Join thisPantry+ Early Access
          </Button>
        </Link>
        <Link to="/">
          <Button className="text-secondaryText hover:bg-transparent" disableElevation disableRipple>
            No thanks, I'll wait
          </Button>
        </Link>
      </div>
    </div>
  );
};

PlusModal.propTypes = {
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  abilityNext: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { hideRegisterModal, setSignupStage, abilityNext })(PlusModal);
