import axios from "axios";
import store from "../store";
import { navigateToLogin } from "../utils/navigate";
import { TOKEN_REFRESH_SUCCESS, TOKEN_REFRESH_FAIL } from "../actions/types";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:3000/", // Default to localhost if not set
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Attach token to every request if available
api.interceptors.request.use(
  (config) => {
    const { token } = store.getState().auth;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Handle token expiration and refresh
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const state = store.getState();

        //const refreshToken = localStorage.getItem('refreshToken');
        const refreshToken = state.auth.refreshToken;

        if (refreshToken) {
          //Refresh the token
          try {
            const res = await axios.post(
              "/api/auth/refresh",
              { refreshToken },
              {
                headers: {
                  Authorization: `Bearer ${state.auth.token}`,
                },
              }
            );

            if (res.status === 201) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("refreshToken", res.data.refreshToken);

              api.defaults.headers["Authorization"] = "Bearer " + res.data.token;
              originalRequest.headers["Authorization"] = "Bearer " + res.data.token;

              store.dispatch({
                type: TOKEN_REFRESH_SUCCESS,
                payload: {
                  token: res.data.token,
                  refreshToken: res.data.refreshToken,
                },
              });
              return api(originalRequest);
            }
          } catch (refreshError) {
            console.log(refreshError);
            //Return error to state
            store.dispatch();

            //Redirect to login
            navigateToLogin();
          }
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;
