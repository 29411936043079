import React, { useState, Fragment } from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
//Actions
import { hideLoginModal, login } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Sections
import LoginContent from "./LoginContent";

const PREFIX = 'LoginModal';

const classes = {
  root: `${PREFIX}-root`,
  background: `${PREFIX}-background`,
  modalContent: `${PREFIX}-modalContent`,
  nav: `${PREFIX}-nav`,
  nav_button: `${PREFIX}-nav_button`,
  nav_text: `${PREFIX}-nav_text`
};

const StyledModal = styled(Modal)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  [`& .${classes.background}`]: {
    backgroundColor: theme.palette.background,
    borderRadius: 16,
    border: "1px solid #fff",
    minHeight: "10vh",
    maxHeight: "85vh",
    width: "30vw",
    minWidth: "600px",
    overflowY: "auto",
    [theme.breakpoints.down('md')]: {
      width: "90vw",
      minWidth: "inherit",
      maxWidth: "450px",
    },
  },

  [`& .${classes.modalContent}`]: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    marginLeft: "auto",
    marginRight: "auto",
  },

  [`& .${classes.nav}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: theme.spacing(1, 2),
    position: "fixed",
  },

  [`& .${classes.nav_button}`]: {
    textTransform: "none",
    color: theme.palette.textOnWhite,
  },

  [`& .${classes.nav_text}`]: {
    fontWeight: 500,
  }
}));

//TO DO: Auto open register modal if user not finished signup flow -> Auto open if not in private beta to invite page
const LoginModal = ({ login, hideLoginModal, auth: { modals } }) => {


  const onRegisterModalClose = () => {
    hideLoginModal();
  };

  //Navigation per stage

  return (
    <StyledModal
      aria-labelledby="Login"
      aria-describedby="Login to thisPantry"
      className={classes.modal}
      open={modals.login}
      onClose={onRegisterModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modals.login}>
        <div className={classes.root}>
          <Paper className={classes.modalContent} elevation={0}>
            <div className={classes.background}>
              <div className={classes.nav}>
                <div></div>

                <IconButton
                  aria-label="exit"
                  onClick={() => hideLoginModal()}
                  className={classes.nav_button}
                  size="large">
                  <FontAwesomeIcon icon={"xmark"} />
                </IconButton>
              </div>

              <LoginContent />
            </div>
          </Paper>
        </div>
      </Fade>
    </StyledModal>
  );
};

LoginModal.propTypes = {
  register: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  hideLoginModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  register: state.register,
  auth: state.auth,
});

export default connect(mapStateToProps, { hideLoginModal, setSignupStage })(LoginModal);
