import React from "react";
import { styled } from '@mui/material/styles';
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { addImageToUpload, cropImageInUpload } from "../../../../actions/media";
import { setAlert } from "../../../../actions/alert";

import { Typography } from "@mui/material";

//Icons
import { ReactComponent as ImagesIcon } from "../../../../assets/icons/svg/images.svg";

//Utils
import { getCroppedImg } from "../../../../utils/image";

const PREFIX = 'ImageDropzone';

const classes = {
  dropzone: `${PREFIX}-dropzone`,
  dz_icon: `${PREFIX}-dz_icon`,
  dz_icon_container: `${PREFIX}-dz_icon_container`,
  dz_text_container: `${PREFIX}-dz_text_container`,
  subtitle: `${PREFIX}-subtitle`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.dropzone}`]: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 8,
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    minHeight: "200px",
    width: "100%",
    minWidth: "200px",
  },

  [`& .${classes.dz_icon}`]: {
    height: "3rem",
    fill: theme.palette.mainGreen,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.dz_icon_container}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.mainGreen,
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.dz_text_container}`]: {
    width: "80%",
  },

  [`& .${classes.subtitle}`]: {
    color: theme.palette.secondaryTextOnWhite,
  }
}));

const UploadDropzone = ({ media: { images }, addImageToUpload, onSelectImageForCrop, setAlert, cropImageInUpload }) => {


  const onDrop = async (acceptedFiles) => {
    if (images.length + acceptedFiles.length > 10) {
      setAlert("You can only upload up to 10 images.", "warning");
      return;
    }

    // For each file, create a URL and dispatch the addImage action

    for (let index = 0; index < acceptedFiles.length; index++) {
      const file = acceptedFiles[index];
      const previewUrl = URL.createObjectURL(file);
      addImageToUpload({ file, previewUrl });

      // Create a new image object
      const img = new Image();
      img.src = previewUrl;
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      // Get the dimensions of the original image
      const originalWidth = img.width;
      const originalHeight = img.height;

      // Calculate the size and position of the cropped image
      const cropSize = Math.min(originalWidth, originalHeight);
      const crop = {
        x: (originalWidth - cropSize) / 2,
        y: (originalHeight - cropSize) / 2,
      };

      // Use your getCroppedImage function to crop the image
      const croppedImage = await getCroppedImg(previewUrl, {
        unit: "px",
        width: cropSize,
        height: cropSize,
        x: crop.x,
        y: crop.y,
      });

      const zoom = 1;

      const cropIndex = images.length + index;
      // Dispatch the addImage action with the cropped image
      cropImageInUpload(cropIndex, {
        croppedImage,
        crop,
        zoom,
        croppedAreaPixels: { width: cropSize, height: cropSize, x: crop.x, y: crop.y },
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Render the dropzone UI
  return (
    <Root {...getRootProps()} className={classes.dropzone}>
      <input {...getInputProps()} />
      <div className={classes.dz_icon_container}>
        <ImagesIcon className={classes.dz_icon} />
      </div>
      <div className={classes.dz_text_container}>
        <Typography variant="body1" className={classes.subtitle}>
          Upload up to 10 images
        </Typography>
      </div>
    </Root>
  );
};

// Connect the UploadDropzone component to the Redux store'

const mapStateToProps = (state) => ({
  media: state.media,
});
export default connect(mapStateToProps, { addImageToUpload, setAlert, cropImageInUpload })(UploadDropzone);
