import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { connect } from "react-redux";
import PropTypes from "prop-types";

//Components
import Spinner from "../layout/Spinner";
import PantryItem from "./PantryItem";

import { Checkbox, TextField, Button, IconButton, Typography } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const PREFIX = 'ItemsList';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  search: `${PREFIX}-search`,
  sortButton: `${PREFIX}-sortButton`,
  sortRoot: `${PREFIX}-sortRoot`,
  itemRoot: `${PREFIX}-itemRoot`,
  itemTitleRoot: `${PREFIX}-itemTitleRoot`,
  itemRight: `${PREFIX}-itemRight`,
  quantity: `${PREFIX}-quantity`,
  emptyItemsRoot: `${PREFIX}-emptyItemsRoot`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    marginTop: theme.spacing(3),
    height: "100%",
  },

  [`& .${classes.container}`]: {
    maxHeight: "50vh",
    minHeight: "184px", //Hard set value, rework to be dynamic
    overflowY: "auto",
    width: "100%",
    border: `1px solid ${theme.palette.separator}`,
    borderRadius: 8,
    "&::-webkit-scrollbar": {
      width: "6px",
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.separator,

      borderRadius: "10px",
      cursor: "pointer",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.secondaryTextOnWhite,
    },
  },

  [`& .${classes.search}`]: {},

  [`& .${classes.sortButton}`]: {
    textTransform: "none",
  },

  [`& .${classes.sortRoot}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.itemRoot}`]: {
    borderBottom: `1px solid ${theme.palette.separator}`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    paddingLeft: 0,
  },

  [`& .${classes.itemTitleRoot}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
  },

  [`& .${classes.itemRight}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.quantity}`]: {
    color: theme.palette.secondaryTextOnWhite,
    lineHeight: 1,
  },

  [`& .${classes.emptyItemsRoot}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(8, 2),
  }
}));

function GroceryList({ pantry, location }) {


  const [searchTerm, setSearchTerm] = useState("");

  // Add sorting functions and search filter logic here

  return (
    <Root className={classes.root}>
      <TextField
        className={classes.search}
        id="search"
        label="Search..."
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="small"
      />
      <div className={classes.sortRoot}>
        <Button className={classes.sortButton}>Name</Button>
        <Button className={classes.sortButton}>Use Within</Button>
      </div>
      <div className={classes.container}>
        {pantry ? (
          pantry.items ? (
            pantry.items.loading ? (
              <Spinner />
            ) : pantry.items.error ? (
              <Typography>Error</Typography>
            ) : pantry.items[location].length > 0 ? (
              pantry.items[location].map((item) => <PantryItem key={item._id} item={item} location={location} />)
            ) : (
              <div className={classes.emptyItemsRoot}>
                <Typography variant="subtitle1">
                  No items in the {location}. Add items to get recipes, track your items
                </Typography>
              </div>
            )
          ) : (
            <Spinner />
          )
        ) : (
          <Spinner />
        )}
      </div>
    </Root>
  );
}

/*

*/

GroceryList.propTypes = {
  pantry: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pantry: state.pantry,
});

export default connect(mapStateToProps)(GroceryList);
