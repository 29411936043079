import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Button, Typography } from "@mui/material";

//Modal Pages
import ScanReceipt from "./ScanReceipt";
import ScanBarcode from "./ScanBarcode";
import AddIndividualItem from "./AddIndividualItem";
import VoiceToPantry from "./VoiceToPantry";

const PREFIX = 'Initial';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  initial: `${PREFIX}-initial`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  titleContainer: `${PREFIX}-titleContainer`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(0, 4),
    maxHeight: "90vh",
  },

  [`& .${classes.button}`]: {
    textTransform: "none",
  },

  [`&.${classes.initial}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "25vh",
  },

  [`& .${classes.buttonContainer}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.titleContainer}`]: {
    marginBottom: theme.spacing(2),
  }
}));

const Initial = ({}) => {


  const [pageToShow, setPageToShow] = useState(-1);

  const handleScanReceiptClick = () => {
    setPageToShow(0);
  };

  const handleScanBarcodeClick = () => {
    setPageToShow(1);
  };

  const handleAddIndividualItemClick = () => {
    setPageToShow(2);
  };

  const handleVoiceToPantryClick = () => {
    setPageToShow(3);
  };

  const initialHTML = (
    <Root className={classes.initial}>
      <div className={classes.titleContainer}>
        <Typography variant="h5">Add Ingredients</Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" size="large" className={classes.button} fullWidth onClick={handleScanReceiptClick}>
          + Scan Receipt
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" size="large" className={classes.button} fullWidth onClick={handleScanBarcodeClick}>
          + Scan Barcode/UPC
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          size="large"
          className={classes.button}
          fullWidth
          onClick={handleAddIndividualItemClick}
        >
          + Add Individual Item
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="outlined" size="large" className={classes.button} fullWidth onClick={handleVoiceToPantryClick}>
          + Add Items By Voice
        </Button>
      </div>
    </Root>
  );

  return (
    <div className={classes.root}>
      {pageToShow === -1 && initialHTML}
      {pageToShow === 0 && <ScanReceipt />}
      {pageToShow === 1 && <ScanBarcode />}
      {pageToShow === 2 && <AddIndividualItem />}
      {pageToShow === 3 && <VoiceToPantry />}
    </div>
  );
};

export default Initial;
