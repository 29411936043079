import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, ExpressCheckoutElement, useStripe, useElements } from "@stripe/react-stripe-js";
import api from "../../api/api";

const stripePromise = loadStripe(
  "pk_test_51PKuT2EG8mQA0pyFza3a2NTP1WHbvb8AYmvb6JI79UdmQJx0xZm1XLqkbkrgMvSiQwp1edtOvJfG2wCHGrL8xFm200OTsmaHHe"
);

const CheckoutForm = ({ paymentElementOptions, expressCheckoutOptions }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/subscription-success",
      },
    });

    if (error) {
      console.log("Error:", error);
      setError(error.message);
    }

    setProcessing(false);
  };

  const handleExpressCheckout = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000/subscription-success",
      },
    });

    if (error) {
      console.log("Error:", error);
      setError(error.message);
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <ExpressCheckoutElement options={expressCheckoutOptions} onConfirm={handleExpressCheckout} />
      <PaymentElement options={paymentElementOptions} className="mt-4" />
      <button
        disabled={!stripe || processing}
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:bg-gray-300 mt-4"
      >
        {processing ? "Processing..." : "Subscribe"}
      </button>
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </form>
  );
};
const CheckoutPage = () => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const fetchClientSecret = async () => {
      const response = await api.post("/stripe/create-setup-intent");
      setClientSecret(response.data.clientSecret);
    };

    fetchClientSecret();
  }, []);

  if (!clientSecret) {
    return "Loading...";
  }

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#598a56",
      colorBackground: "#ffffff",
      colorText: "#362e2d",
      colorDanger: "#ef4444",
      fontFamily: "Poppins, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
    },
  };

  const paymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
  };

  const expressCheckoutOptions = {
    buttonType: {
      applePay: "subscribe",
      googlePay: "subscribe",
    },
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance,
      }}
    >
      <div className="flex justify-center items-center h-screen">
        <CheckoutForm paymentElementOptions={paymentElementOptions} expressCheckoutOptions={expressCheckoutOptions} />
      </div>
    </Elements>
  );
};

export default CheckoutPage;
