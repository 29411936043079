import React from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { closeImageCropModal, reorderImagesInUpload } from "../../../../actions/media";

import CustomModal from "../../../modal/CustomModal";
import ReorderImage from "./ReorderImage";
import ImageCropModal from "./ImageCropModal";

const PREFIX = "ImageReorderPreview";

const classes = {
  root: `${PREFIX}-root`,
  reorderContainer: `${PREFIX}-reorderContainer`,
  rootMargin: `${PREFIX}-rootMargin`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: 0, //Clear margin from form_section on parent
  },

  [`& .${classes.reorderContainer}`]: {
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {},
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "darkgrey",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "grey",
      },
    },
    "&:hover": {
      "--fix": "",
    },
  },

  [`& .${classes.rootMargin}`]: {
    margin: theme.spacing(4, 0),
  },
}));

// A little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Define the ImagePreview component
const ImagePreview = ({ media: { images, modals }, reorderImagesInUpload, closeImageCropModal, className }) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedImages = reorder(images, result.source.index, result.destination.index);

    reorderImagesInUpload(reorderedImages);
  };

  //TO DO:
  //Hover to show edit icon and overlay
  //Click to open modal to crop image
  //Remove + alt text in crop modal

  return (
    <Root className={className}>
      <CustomModal open={modals.crop} handleClose={() => closeImageCropModal()}>
        <ImageCropModal />
      </CustomModal>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={classes.reorderContainer}>
              {images &&
                images.map((image, index) => (
                  <Draggable key={index} draggableId={String(index)} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <ReorderImage image={image} index={index} />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Root>
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
  media: state.media,
});

export default connect(mapStateToProps, { reorderImagesInUpload, closeImageCropModal })(ImagePreview);
