import React from "react";

import { Typography } from "@mui/material";

//Utils
import { decimalToFraction, convertToTitleCase } from "../../../../../utils/recipe";
const formatAmountUnit = (ingredient) => {
  const unit = ingredient.unit.display;
  let formattedAmount = "";
  let formattedUnit = "";

  if (unit && unit.magnitude && unit.magnitude !== "") {
    formattedAmount = decimalToFraction(unit.magnitude);
  }

  if (unit && unit.text !== "" && unit.text !== "not_exact" && unit.text !== "self" && unit.type !== "unknown") {
    formattedUnit = unit.text;
  }

  let showSpace = false;

  if (formattedAmount !== "" && formattedUnit !== "") {
    showSpace = true;
  }

  return `${formattedAmount} ${formattedUnit}${showSpace ? " " : ""}`;
};

const formatIngredient = (ingredient) => {
  //Convert ingredient data to a string for the top line
  let formattedName =
    ingredient.name && ingredient.name !== "" ? convertToTitleCase(ingredient.name) : "Unknown Ingredient";

  return `${formattedName}`;
};

const formatState = (ingredient) => {
  let formattedState = "";

  if (ingredient.state && ingredient.state !== "") {
    formattedState = ingredient.state.toTitleCase();
  }

  if (formattedState !== "") {
    return `, ${formattedState}`;
  }
};

const RecipeIngredients = ({ recipe }) => {
  return (
    <div>
      <div>
        {recipe.sections.map((section, i) => {
          if (section.ingredients.length === 0) return null;
          let showBottomBorder = true;

          if (i !== recipe.sections.length - 1) {
            //Check if in second last section, draw line if last is empty
            if (i === recipe.sections.length - 2) {
              if (recipe.sections[recipe.sections.length - 1].ingredients.length === 0) {
                showBottomBorder = false;
              }
            }
          } else {
            showBottomBorder = false;
          }
          return (
            <div key={i} className={`py-4 ${showBottomBorder ? "pb-4 border-b border-b-separator" : ""}`}>
              <div className="flex flex-row items-center">
                <Typography variant="subtitle1" className="font-sans font-normal text-primaryText">
                  {section.title}
                </Typography>
              </div>
              <ul className="ml-4 ">
                {section.ingredients.map((ingredient, j) => {
                  return (
                    <li className="mt-2 mb-4 marker:text-mainGreen">
                      <div className="flex flex-col">
                        <Typography variant="body1" className="font-medium">
                          <span className="font-normal">{formatAmountUnit(ingredient)}</span>
                          {formatIngredient(ingredient)}
                          {ingredient.state !== "" && (
                            <span className="font-normal italic text-secondaryText text-sm">
                              {formatState(ingredient)}
                            </span>
                          )}
                        </Typography>
                        {ingredient.note && ingredient.note !== "" && (
                          <Typography variant="body2" className="text-secondaryText">
                            ({ingredient.note})
                          </Typography>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecipeIngredients;
