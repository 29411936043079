import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Footer from "../layout/Footer";
import Plus from "./Plus";

const PlusPage = ({ auth: { isAuthenticated } }) => {
  return (
    <div className="">
      <Helmet>
        <title>thisPantry+ - Get more out of your kitchen</title>
      </Helmet>
      <div className="min-h-[100vh] w-full py-[4rem] md:py-0 md:pl-[5rem]">
        <Plus />
      </div>
      {!isAuthenticated && <Footer />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PlusPage);
