import React, { useEffect, useState } from "react";
//import { Button, Container, TextField, Typography, makeStyles } from "@material-ui/core";

//Redux actions
import { showLoginModal } from "../../actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
const Login = ({ showLoginModal, auth: { isAuthenticated } }) => {
  useEffect(() => {
    if (!isAuthenticated) {
      showLoginModal();
    }
  }, []);
  return <Navigate to="/"></Navigate>;
};

Login.propTypes = {
  showLoginModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { showLoginModal })(Login);
