import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//MUI Styling
import { Button } from "@mui/material";

//Actions
import { logout } from "../../../actions/auth";

//Modals
import LoginModal from "../../auth/login/LoginModal";
import RegisterModal from "../../auth/RegisterModal";

//Assets
import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

const EarlySignup = ({ logout }) => {
  const location = useLocation();
  const onNavigation = () => {
    window.scrollTo(0, 0);
  };

  if (location.pathname === "/") {
    return (
      <nav className="z-50 bg-mainGreen-900 bg-opacity-80 backdrop-blur-sm w-full h-[4rem] fixed top-0 px-8 py-4 flex flex-row justify-between align-center ">
        <div className="text-background">
          <Logo className="fill-background h-full w-auto" />
        </div>
        <div className="flex flex-row align-center justify-center">
          <Button variant="outlined" className="text-background border-2 hover:border-2 font-normal" onClick={logout}>
            Log Out
          </Button>
        </div>
      </nav>
    );
  } else {
    return (
      <>
        <nav className="w-full h-[4rem] bg-white sticky top-0 border-b border-separator flex items-center justify-between px-8 py-4 z-[999]">
          <div className="h-full flex items-center">
            <Link to="/" className="h-full" onClick={() => onNavigation()}>
              <Logo className="h-full fill-mainGreen"></Logo>
            </Link>
          </div>
          <div>
            <Button
              variant="outlined"
              disableElevation
              className="border-2 border-primaryText hover:border-2 hover:border-mainGreen"
              size="small"
              onClick={logout}
            >
              Log Out
            </Button>
          </div>
        </nav>
        <LoginModal />
        <RegisterModal />
      </>
    );
  }
};

EarlySignup.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logout })(EarlySignup);
