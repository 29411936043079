import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { signup, hideRegisterModal } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";

//MUI Components
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//MUI styling
import { useMediaQuery } from "@mui/material";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PREFIX = "BasicInfoStandalone";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  nav: `${PREFIX}-nav`,
  nav_button: `${PREFIX}-nav_button`,
  body: `${PREFIX}-body`,
  title_container: `${PREFIX}-title_container`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  create_account: `${PREFIX}-create_account`,
  input_container: `${PREFIX}-input_container`,
  goodLookin: `${PREFIX}-goodLookin`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: "100%",
    width: "100%",
  },

  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },

  [`& .${classes.nav}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "fixed",
    top: 0,
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.backgroundColor,
  },

  [`& .${classes.nav_button}`]: {
    color: theme.palette.textOnWhite,
    textTransform: "none",
  },

  [`& .${classes.body}`]: {
    textAlign: "initial",
    padding: theme.spacing(0, 10),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(2),
    },
  },

  [`& .${classes.title_container}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "inherit",
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.mainGreen,
    fontWeight: 700,
  },

  [`& .${classes.subtitle}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.create_account}`]: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.mainGreen,
    color: theme.palette.background,
    textTransform: "none",
  },

  [`& .${classes.input_container}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },

  [`& .${classes.goodLookin}`]: {
    fontWeight: 300,
    fontStyle: "italic",
  },
}));

const BasicInfoStandalone = ({ signup, hideRegisterModal, setSignupStage, register: { errors }, topMargin = true }) => {
  //Breakpoints
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  //State
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [dob, setDOB] = useState(new Date());

  const [showPasswords, setShowPasswords] = useState(false);

  //Today for max date
  const today = new Date().toISOString().split("T")[0];

  const onCreateAccount = async () => {
    const user = await signup({
      username,
      name,
      email,
      password1,
      password2,
      dob,
    });
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword1 = (e) => {
    setPassword1(e.target.value);
  };
  const handlePassword2 = (e) => {
    setPassword2(e.target.value);
  };

  //TO DO:
  //Signup progress bar?
  return (
    <Root className={classes.root}>
      <div className={topMargin ? classes.content : classes.contentNoMargin}>
        <div className={classes.body}>
          <div className={classes.title_container}>
            <Typography variant={isSmallScreen ? "h5" : "h4"} className={classes.title}>
              Let's get cookin'<br></br>
              <Typography variant={isSmallScreen ? "h6" : "h5"} className={classes.goodLookin}>
                (good lookin')
              </Typography>
            </Typography>

            <Typography variant={isSmallScreen ? "body2" : "subtitle2"} className={classes.subtitle}>
              Sign up for the private beta with some basic info
            </Typography>
          </div>

          <div className={classes.input_container}>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              value={username}
              onChange={handleUsername}
              size={isSmallScreen ? "small" : "medium"}
              inputProps={{ maxLength: 20 }}
              error={errors && !errors.initial.username.isValid}
              helperText={errors && !errors.initial.username.isValid ? errors.initial.username.error : ""}
            />
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={handleName}
              size={isSmallScreen ? "small" : "medium"}
              inputProps={{ maxLength: 100 }}
              error={errors && !errors.initial.name.isValid}
              helperText={errors && !errors.initial.name.isValid ? errors.initial.name.error : ""}
            />
            <DatePicker
              value={dob}
              onChange={(date) => setDOB(date)}
              format="MM/dd/yyyy"
              animateYearScrolling
              fullWidth
              size={isSmallScreen ? "small" : "medium"}
              inputVariant="outlined" // This prop makes the input field outlined
              TextFieldComponent={(props) => (
                <TextField
                  {...props}
                  variant="outlined" // makes the TextField outlined
                  label="Birthday"
                  InputLabelProps={{
                    ...props.InputLabelProps,
                    shrink: true,
                  }}
                  error={errors && !errors.initial.dob.isValid}
                  helperText={errors && !errors.initial.dob.isValid ? errors.initial.dob.error : ""}
                />
              )}
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmail}
              size={isSmallScreen ? "small" : "medium"}
              inputProps={{ maxLength: 254 }}
              error={errors && !errors.initial.email.isValid}
              helperText={errors && !errors.initial.email.isValid ? errors.initial.email.error : ""}
            />
            <TextField
              fullWidth
              label="Password"
              type={showPasswords ? "text" : "password"}
              variant="outlined"
              value={password1}
              onChange={handlePassword1}
              size={isSmallScreen ? "small" : "medium"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswords(!showPasswords)}
                      edge="end"
                      size="small"
                    >
                      <FontAwesomeIcon icon={showPasswords ? "eye-slash" : "eye"} className={classes.show_password} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors && !errors.initial.password1.isValid}
              helperText={errors && !errors.initial.password1.isValid ? errors.initial.password1.error : ""}
            />
            <TextField
              fullWidth
              label="Confirm Password"
              type={showPasswords ? "text" : "password"}
              variant="outlined"
              value={password2}
              onChange={handlePassword2}
              size={isSmallScreen ? "small" : "medium"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswords(!showPasswords)}
                      edge="end"
                      size="small"
                    >
                      <FontAwesomeIcon icon={showPasswords ? "eye-slash" : "eye"} className={classes.show_password} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors && !errors.initial.password2.isValid}
              helperText={errors && !errors.initial.password2.isValid ? errors.initial.password2.error : ""}
            />
          </div>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            size={isSmallScreen ? "medium" : "large"}
            className={classes.create_account}
            onClick={() => onCreateAccount()}
            type="submit"
          >
            Create Account
          </Button>
        </div>
      </div>
    </Root>
  );
};

BasicInfoStandalone.propTypes = {
  signup: PropTypes.func.isRequired,
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  register: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  register: state.register,
});

export default connect(mapStateToProps, {
  signup,
  hideRegisterModal,
  setSignupStage,
})(BasicInfoStandalone);
