import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Typography } from "@mui/material";

//Components
import RecipeMacrosItem from "./RecipeMacrosItem";

const RecipeMacros = ({ macros, username }) => {
  const [isGenerated, setIsGenerated] = useState(true);
  const [anyGenerated, setAnyGenerated] = useState(false);

  const checkIfGenerated = () => {
    for (let key in macros) {
      if (!macros[key]?.estimated) {
        setIsGenerated(false);
      } else {
        setAnyGenerated(true);
      }
    }
  };

  useEffect(() => {
    //Check over all macros, if all generated change source to thisPantry AI
    checkIfGenerated();
  }, [macros]);

  return (
    <div className="mb-2">
      <div className="mb-4">
        <Typography variant="h6" className="font-sans font-normal mt-2 text-primaryText leading-snug">
          Nutrition Facts
        </Typography>
        <Typography variant="subtitle2" className="font-normal text-secondaryText italic leading-tight">
          per serving
        </Typography>
      </div>
      {macros?.calories && (
        <div>
          <RecipeMacrosItem
            title={`Calories${macros.calories.estimated ? " *" : ""}`}
            item={macros.calories}
            type="primary"
          />
        </div>
      )}
      {macros?.protein && (
        <div>
          <RecipeMacrosItem
            title={`Protein${macros.protein.estimated ? " *" : ""}`}
            item={macros.protein}
            type="primary"
          />
        </div>
      )}
      {macros?.carbohydrates && (
        <div>
          <RecipeMacrosItem
            title={`Carbohydrates${macros.carbohydrates.estimated ? " *" : ""}`}
            item={macros.carbohydrates}
            type="primary"
          />
        </div>
      )}
      {macros?.fat && (
        <div>
          <RecipeMacrosItem title={`Fat${macros.fat.estimated ? " *" : ""}`} item={macros.fat} type="primary" />
          {macros?.saturatedFat && (
            <RecipeMacrosItem
              title={`Saturated Fat${macros.saturatedFat.estimated ? " *" : ""}`}
              item={macros.saturatedFat}
              type="secondary"
            />
          )}
          {macros?.unsaturatedFat && (
            <RecipeMacrosItem
              title={`Unsaturated Fat${macros.unsaturatedFat.estimated ? " *" : ""}`}
              item={macros.unsaturatedFat}
              type="secondary"
            />
          )}
          {macros?.transFat && (
            <RecipeMacrosItem
              title={`Trans Fat${macros.transFat.estimated ? " *" : ""}`}
              item={macros.transFat}
              type="secondary"
            />
          )}
        </div>
      )}
      {macros?.sugars && (
        <div>
          <RecipeMacrosItem
            title={`Sugars${macros.sugars.estimated ? " *" : ""}`}
            item={macros.sugars}
            type="primary"
          />
          {macros?.naturalSugars && (
            <RecipeMacrosItem
              title={`Natural Sugars${macros.naturalSugars.estimated ? " *" : ""}`}
              item={macros.naturalSugars}
              type="secondary"
            />
          )}
        </div>
      )}
      {macros?.sodium && (
        <div>
          <RecipeMacrosItem
            title={`Sodium${macros.sodium.estimated ? " *" : ""}`}
            item={macros.sodium}
            type="primary"
          />
        </div>
      )}
      {macros?.fiber && (
        <div>
          <RecipeMacrosItem title={`Fiber${macros.fiber.estimated ? " *" : ""}`} item={macros.fiber} type="primary" />
        </div>
      )}
      {macros?.cholesterol && (
        <div>
          <RecipeMacrosItem
            title={`Cholesterol${macros.cholesterol.estimated ? " *" : ""}`}
            item={macros.cholesterol}
            type="primary"
          />
        </div>
      )}
      {isGenerated ? (
        <div className="mt-2">
          <Link to={`/${username}`}>
            <Typography variant="caption" className="font-normal text-secondaryText italic">
              Source: Estimated by thisPantry AI
            </Typography>
          </Link>

          <Typography variant="caption" className="font-normal text-secondaryText italic">
            * Value estimated for this recipe by thisPantry AI
          </Typography>
        </div>
      ) : (
        <div className="mt-2">
          <Link to={`/${username}`}>
            <Typography variant="caption" className="font-normal text-secondaryText italic">
              {`Source: Added by @${username}`}
            </Typography>
          </Link>
          {anyGenerated && (
            <Typography variant="caption" className="font-normal text-secondaryText italic">
              * Value estimated for this recipe by thisPantry AI
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default RecipeMacros;
