//Import redux types
import {
  GET_COOKBOOK_RECIPES,
  COOKBOOK_RECIPES_ERROR,
  RESET_COOKBOOK_FILTERS,
  GET_SEARCH_RECIPES,
  SET_SEARCH_RECIPES,
  SEARCH_RECIPES_ERROR,
  SEARCH_RECIPES_RESET,
  SET_COOKBOOK_QUERY,
  RESET_COOKBOOK_QUERY,
  SET_ALLERGEN_FILTERS,
  SET_DIETARY_FILTERS,
  SET_INCLUDE_FILTERS,
  SET_EXCLUDE_FILTERS,
  GET_DEFAULT_RECIPE_FILTERS,
} from "../actions/types";

//Initial state object
const initialState = {
  search: {
    query: "",
    preferences: {
      allergies: [],
      diets: [],
      dislikes: [],
    },
    filters: {
      allergies: [],
      diets: [],
      include: [],
      exclude: [],
    },
  },
  results: {
    search: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },
    onHand: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },
    recentlyCooked: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },
    myRecipes: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },
    bookmarked: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },
    lovedByFriends: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },
    trending: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },
    favorites: {
      recipes: [],
      loading: true,
      error: false,
      isEndOfFeed: false,
      offset: 0,
    },

    loading: true,
    error: false,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case GET_COOKBOOK_RECIPES:
      return {
        ...state,
        search: {
          ...state.search,
          preferences: {
            ...state.search.preferences,
            allergies: payload.preferences.dietary.allergies,
            diets: payload.preferences.dietary.diets,
            dislikes: payload.preferences.dietary.dislikes,
          },
          filters: {
            ...state.search.filters,
            allergies: payload.preferences.dietary.allergies,
            diets: payload.preferences.dietary.diets,
            exclude: payload.preferences.dietary.dislikes,
          },
        },
        results: {
          ...state.results,
          onHand: {
            recipes: payload.onHand.recipes,
            loading: false,
            error: false,
            numPosts: payload.onHand.numPosts,
            isEndOfFeed: payload.onHand.isEndOfFeed,
            offset: payload.onHand.offset,
          },
          recentlyCooked: {
            recipes: payload.recentlyCooked.recipes,
            loading: false,
            error: false,
            numPosts: payload.recentlyCooked.numPosts,
            isEndOfFeed: payload.recentlyCooked.isEndOfFeed,
            offset: payload.recentlyCooked.offset,
          },
          myRecipes: {
            recipes: payload.myRecipes.recipes,
            loading: false,
            error: false,
            numPosts: payload.myRecipes.numPosts,
            isEndOfFeed: payload.myRecipes.isEndOfFeed,
            offset: payload.myRecipes.offset,
          },
          bookmarked: {
            recipes: payload.bookmarked.recipes,
            loading: false,
            error: false,
            numPosts: payload.bookmarked.numPosts,
            isEndOfFeed: payload.bookmarked.isEndOfFeed,
            offset: payload.bookmarked.offset,
          },
          lovedByFriends: {
            recipes: payload.lovedByFriends.recipes,
            loading: false,
            error: false,
            numPosts: payload.lovedByFriends.numPosts,
            isEndOfFeed: payload.lovedByFriends.isEndOfFeed,
            offset: payload.lovedByFriends.offset,
          },
          trending: {
            recipes: payload.trending.recipes,
            loading: false,
            error: false,
            numPosts: payload.trending.numPosts,
            isEndOfFeed: payload.trending.isEndOfFeed,
            offset: payload.trending.offset,
          },
          favorites: {
            recipes: payload.favorites.recipes,
            loading: false,
            error: false,
            numPosts: payload.favorites.numPosts,
            isEndOfFeed: payload.favorites.isEndOfFeed,
            offset: payload.favorites.offset,
          },
          loading: false,
          error: false,
        },
      };
    case COOKBOOK_RECIPES_ERROR: {
      return {
        ...state,
        results: {
          ...state.results,
          onHand: {
            recipes: [],
            loading: false,
            error: true,
          },
          recentlyCooked: {
            recipes: [],
            loading: false,
            error: true,
          },
          myRecipes: {
            recipes: [],
            loading: false,
            error: true,
          },
          bookmarked: {
            recipes: [],
            loading: false,
            error: true,
          },
          lovedByFriends: {
            recipes: [],
            loading: false,
            error: true,
          },
          trending: {
            recipes: [],
            loading: false,
            error: true,
          },
          favorites: {
            recipes: [],
            loading: false,
            error: true,
          },

          loading: false,
          error: true,
        },
      };
    }

    case RESET_COOKBOOK_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          filters: {
            ...state.search.filters,
            allergies: [],
            diets: [],
            include: [],
            exclude: [],
          },
        },
      };
    }

    case GET_SEARCH_RECIPES: {
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            loading: true,
            error: false,
          },
        },
      };
    }
    case SET_SEARCH_RECIPES: {
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            recipes: [...state.results[payload.key].recipes, ...payload.recipes],
            loading: false,
            error: false,
            numPosts: payload.numPosts,
            isEndOfFeed: payload.isEndOfFeed,
            offset: payload.offset,
          },
        },
      };
    }
    case SEARCH_RECIPES_ERROR: {
      return {
        ...state,
        results: {
          ...state.results,
          search: {
            recipes: [],
            loading: false,
            error: true,
            numPosts: 0,
            isEndOfFeed: true,
            offset: 0,
          },
        },
      };
    }
    case SEARCH_RECIPES_RESET: {
      return {
        ...state,
        search: {
          ...state.search,
          query: "",
          preferences: {
            allergies: [],
            diets: [],
            dislikes: [],
          },
          filters: {
            allergies: [],
            diets: [],
            include: [],
            exclude: [],
          },
        },
        results: {
          ...state.results,
          search: {
            recipes: [],
            loading: true,
            error: false,
            isEndOfFeed: false,
            offset: 0,
          },
        },
      };
    }
    case SET_COOKBOOK_QUERY: {
      return {
        ...state,
        search: {
          ...state.search,
          query: payload,
        },
        results: {
          ...state.results,
          search: {
            recipes: [],
            loading: true,
            error: false,
            isEndOfFeed: false,
            offset: 0,
          },
        },
      };
    }

    case RESET_COOKBOOK_QUERY: {
      return {
        ...state,
        search: {
          ...state.search,
          query: "",
        },
      };
    }

    case SET_ALLERGEN_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          filters: {
            ...state.search.filters,
            allergies: payload,
          },
        },
      };
    }

    case SET_DIETARY_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          filters: {
            ...state.search.filters,
            diets: payload,
          },
        },
      };
    }

    case SET_INCLUDE_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          filters: {
            ...state.search.filters,
            include: payload,
          },
        },
      };
    }

    case SET_EXCLUDE_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          filters: {
            ...state.search.filters,
            exclude: payload,
          },
        },
      };
    }

    case GET_DEFAULT_RECIPE_FILTERS: {
      return {
        ...state,
        search: {
          ...state.search,
          preferences: {
            ...state.search.preferences,
            allergies: payload.allergies,
            diets: payload.diets,
            dislikes: payload.dislikes,
          },
          filters: {
            ...state.search.filters,
            allergies: payload.allergies,
            diets: payload.diets,
            exclude: payload.dislikes,
          },
        },
      };
    }
    default:
      return state;
  }
}
