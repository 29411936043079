import React from "react";
import { Typography } from "@mui/material";
import { Badge } from "../../../../ui/badge";

const RecipeDiets = ({ diets }) => {
  //To DO:
  //- allergen disclaimer, always check product labels and verify ingredients for allergens
  //- if list empty, say "none listed"

  return (
    <div>
      <div>
        <Typography variant="h6" className="text-primaryText font-sans font-normal leading-tight">
          Friendly Diets
        </Typography>
      </div>
      <div className="my-2">
        {diets.map((diet, i) => {
          return (
            <Badge
              variant="primary"
              className="font-normal mr-[0.25rem] mb-[0.25rem] py-1 px-3 shadow-sm cursor-pointer"
            >
              <Typography variant="body2" className="text-xs">
                {diet.text}
              </Typography>
            </Badge>
          );
        })}
      </div>
      <div className="mb-2">
        <Typography variant="caption" className="font-normal text-secondaryText italic">
          * Always check product labels and verify ingredients meet your personal dietary restrictions
        </Typography>
      </div>
    </div>
  );
};

export default RecipeDiets;
