import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";

//Actions
import { login, hideLoginModal } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";
//MUI Styling
import { useMediaQuery } from "@mui/material";

//MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

//Logo
import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as GoogleIcon } from "../res/google.svg";

const PREFIX = 'LoginContent';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  content: `${PREFIX}-content`,
  button_container: `${PREFIX}-button_container`,
  agree: `${PREFIX}-agree`,
  iconEdgeButton: `${PREFIX}-iconEdgeButton`,
  startIconCustom: `${PREFIX}-startIconCustom`,
  google: `${PREFIX}-google`,
  subtitle: `${PREFIX}-subtitle`,
  exit: `${PREFIX}-exit`,
  link: `${PREFIX}-link`,
  signin_prompt: `${PREFIX}-signin_prompt`,
  or_container: `${PREFIX}-or_container`,
  or_line: `${PREFIX}-or_line`,
  show_password: `${PREFIX}-show_password`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    height: "100%",
    width: "100%",
    marginTop: theme.spacing(6),
  },

  [`& .${classes.logo}`]: {
    height: "5rem",
    "-webkit-user-select": "none", // Safari
    "-ms-user-select": "none", // IE 10 and IE 11
    userSelect: "none", // Standard syntax
    fill: theme.palette.mainGreen,
    [theme.breakpoints.down('md')]: {
      height: "4rem",
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    textAlign: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
      paddingTop: theme.spacing(2),
    },
  },

  [`& .${classes.button_container}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4, 10),
    gap: "0.75rem",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
      margin: 0,
    },
  },

  [`& .${classes.agree}`]: {
    color: theme.palette.textOnWhite + "cc", //80% = cc
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },

  [`& .${classes.iconEdgeButton}`]: {
    position: "relative",
    textTransform: "none",
    paddingLeft: 40, // Adjust this to control spacing between the icon and the text
  },

  [`& .${classes.startIconCustom}`]: {
    position: "absolute",
    left: "1rem", // This determines the gap between the left edge and the icon
  },

  [`& .${classes.google}`]: {
    height: "1.5rem",
    width: "1.5rem",
  },

  [`& .${classes.subtitle}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.exit}`]: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(4),
    color: theme.palette.textOnWhite,
  },

  [`& .${classes.link}`]: {
    color: theme.palette.mainGreen,
  },

  [`& .${classes.signin_prompt}`]: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.or_container}`]: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  [`& .${classes.or_line}`]: {
    height: "1px",
    backgroundColor: theme.palette.textOnWhite + "40", //40 = 25%
    width: "35%",
  },

  [`& .${classes.show_password}`]: {
    color: theme.palette.textOnWhite + "66", // 66 = 40%
  }
}));

const Initial = ({
  login,
  setSignupStage,
  hideLoginModal,
  auth: {
    errors: { loginErrors },
  },
}) => {

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [password, setPassword] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [showPasswords, setShowPasswords] = useState(false);

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleIdentifier = (e) => {
    setIdentifier(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    login({ identifier, password });
  };

  /* 3PA !IMPORTANT CHANGE LINKS AND ICONS
 <Button
          variant="outlined"
          fullWidth
          startIcon={
            <i className="fa-brands fa-x-twitter" style={{ color: "#000" }}></i>
          }
          classes={{
            root: classes.iconEdgeButton,
            startIcon: classes.startIconCustom,
          }}
          size={isSmallScreen ? 'medium' : 'large'}
          component={Link}
          to={{ pathname: "http://localhost:5000/api/auth/twitter" }} target="_blank"
        >
          Log in with Twitter
        </Button>
        <Button
          variant="outlined"
          fullWidth
          startIcon={
            <i
              className="fa-brands fa-facebook"
              style={{ color: "#0866ff" }}
            ></i>
          }
          classes={{
            root: classes.iconEdgeButton,
            startIcon: classes.startIconCustom,
          }}
          size={isSmallScreen ? 'medium' : 'large'}
          component={Link}
          to={{ pathname: "http://localhost:5000/api/auth/facebook" }} target="_blank"
        >
          Log in with Facebook
        </Button>
        <Button
          variant="outlined"
          fullWidth
          startIcon={
            <i className="fa-brands fa-apple" style={{ color: "#000" }}></i>
          }
          classes={{
            root: classes.iconEdgeButton,
            startIcon: classes.startIconCustom,
          }}
          size={isSmallScreen ? 'medium' : 'large'}
          component={Link}
          to={{ pathname: "http://localhost:5000/api/auth/apple" }} target="_blank"
        >
          Log in with Apple
        </Button>
    */

  return (
    <Root className={classes.root}>
      <div className={classes.content}>
        <Logo className={classes.logo} />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className={classes.button_container}>
            <TextField
              fullWidth
              label="Username/Email"
              type="text"
              variant="outlined"
              value={identifier}
              onChange={handleIdentifier}
              size={isSmallScreen ? "small" : "medium"}
              error={
                loginErrors &&
                loginErrors.username &&
                !loginErrors.username.isValid
              }
              helperText={
                loginErrors &&
                loginErrors.username &&
                !loginErrors.username.isValid
                  ? loginErrors.username.error
                  : null
              }
            />
            <TextField
              fullWidth
              label="Password"
              type={showPasswords ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={handlePassword}
              size={isSmallScreen ? "small" : "medium"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswords(!showPasswords)}
                      edge="end"
                      size="small"
                    >
                      <FontAwesomeIcon
                        icon={showPasswords ? "eye-slash" : "eye"}
                        className={classes.show_password}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={
                loginErrors &&
                loginErrors.password &&
                !loginErrors.password.isValid
              }
              helperText={
                loginErrors &&
                loginErrors.password &&
                !loginErrors.password.isValid
                  ? loginErrors.password.error
                  : null
              }
            />
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disableElevation
              style={{ textTransform: "none" }}
              type="submit"
            >
              Log in
            </Button>
            <div className={classes.or_container}>
              <div className={classes.or_line}></div>
              <div className={classes.or_textdiv}>
                <Typography variant="subtitle1" className={classes.or_text}>
                  or
                </Typography>
              </div>
              <div className={classes.or_line}></div>
            </div>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<GoogleIcon className={classes.google} />}
              classes={{
                root: classes.iconEdgeButton,
                startIcon: classes.startIconCustom,
              }}
              size={isSmallScreen ? "medium" : "large"}
              component={Link}
              to={"/api/auth/google"}
              target="_blank"
            >
              Log in with Google
            </Button>

            <Typography variant="subtitle2" className={classes.signin_prompt}>
              <span style={{ fontWeight: 400 }}>
                thisPantry is currently in a private beta.<br></br>
                <br></br>
              </span>
              Don't have an account?{" "}
              <Link
                to="/signup"
                className={classes.link}
                onClick={() => hideLoginModal()}
              >
                Sign Up
              </Link>
            </Typography>
          </div>
        </form>
      </div>
    </Root>
  );
};

Initial.propTypes = {
  login: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
  hideLoginModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  login,
  setSignupStage,
  hideLoginModal,
})(Initial);
