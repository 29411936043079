import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, TextField, Checkbox, Button, FormControlLabel } from "@mui/material";
import { Rating } from "@mui/material";

//Actions
import { createRecipeReview } from "../../../actions/review";
import { setAlert } from "../../../actions/alert";

const ReviewModal = ({ recipe, createRecipeReview, setAlert, handleClose }) => {
  const [isPublic, setIsPublic] = useState(true);
  const [rating, setRating] = useState(4);
  const [comment, setComment] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleCheck = (e) => {
    setIsPublic(e.target.checked);
  };

  const handleCommentChange = (e) => {
    const text = e.target.value;

    if (text.length < 512) {
      setComment(text);
    }
  };
  console.log(recipe);

  const determineRatingText = (rating) => {
    switch (rating) {
      case 5:
        return "Outstanding!";
      case 4:
        return "Excellent";
      case 3:
        return "Good";
      case 2:
        return "Okay";
      case 1:
        return "Poor";
      default:
        return "";
    }
  };

  const onRatingChange = (newValue) => {
    if (Number.isInteger(newValue) && newValue >= 1 && newValue <= 5) {
      setRating(newValue);
    } else {
      //Fixes sometimes selecting 0 stars on mobile
      setRating(4);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    //Check comment is less than 512 characters
    let trimmedComment = comment.trim();

    const created = await createRecipeReview({
      id: recipe._id,
      rating,
      comment: trimmedComment,
      public: isPublic,
    });

    if (created) {
      //Handle close
      setAlert(`Created your review for ${recipe.name}`, "success");
      setSubmitLoading(false);
      handleClose();
    } else {
      //Handle error
      setAlert("An error occurred while creating your review. Please refresh and try again.", "error");
      setSubmitLoading(false);
    }
  };

  //TO DO:
  //Style modal
  //Verify data before passing to backend
  //Setup for editing a review as well
  //Setup for deleting a review as well
  //Add ability to add images to a review
  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="mb-4">
        <div>
          <Typography variant="h5">{recipe.name}</Typography>
          <Typography variant="subtitle1" className="text-secondaryText italic">
            by <span className="text-mainGreen italic">{`@${recipe.user.username}`}</span>
          </Typography>
        </div>
      </div>
      <div className="flex flex-row items-center mb-4">
        <Rating
          name="rating"
          value={rating}
          onChange={(e, newValue) => onRatingChange(newValue)}
          precision={1}
          size="large"
        />
        <Typography variant="subtitle1" className="mx-4 text-secondaryText italic">
          {determineRatingText(rating)}
        </Typography>
      </div>
      <div className="mb-4">
        <TextField
          variant="outlined"
          multiline
          minRows={5}
          maxRows={5}
          label="Add a comment"
          fullWidth
          value={comment}
          onChange={(e) => handleCommentChange(e)}
        ></TextField>
      </div>

      {comment.trim().length > 0 && (
        <div className="mb-4">
          <div>
            <FormControlLabel
              control={<Checkbox checked={isPublic} onChange={handleCheck} name="public" color="primary" />}
              label="Public Review"
            />
          </div>

          <Typography variant="caption" className="text-secondaryText italic">
            Ratings can be public or private and help us better tailor our recipe suggestions to you
          </Typography>
        </div>
      )}

      <div>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleSubmit()}
          disabled={submitLoading}
        >
          Create Review
        </Button>
      </div>
    </div>
  );
};

ReviewModal.propTypes = {
  recipe: PropTypes.object.isRequired,
  createRecipeReview: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { createRecipeReview, setAlert })(ReviewModal);
