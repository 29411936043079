import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Typography, IconButton } from "@mui/material";
//DO NEXT:
//SEARCH PAGE FOR YOU TO 2X2 GRID WITH RECIPE CARDS AND USER SEARCH COMPONENTS

//Actions
import { getRecipeFromURL, resetStandaloneRecipe } from "../../../actions/recipe";

//Components
import Spinner from "../../layout/Spinner";
import RecipeStandalone from "./components/RecipeStandalone";
import CannotFind from "../../static/CannotFind";
import Footer from "../../layout/Footer";

const RecipePage = ({ recipe: { standalone }, auth: { isAuthenticated }, getRecipeFromURL, resetStandaloneRecipe }) => {
  const { username, recipeURL } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    getRecipeFromURL(username, recipeURL);

    //Reset recipe state when navigating away
    return () => {
      resetStandaloneRecipe();
    };
  }, []);
  return (
    <>
      <div
        className={`flex flex-col items-center ${
          isAuthenticated ? "pt-[4rem] md:pt-0 md:pl-[4rem] md:mx-4 min-h-[100vh] " : ""
        }`}
      >
        <div className="w-full mx-auto">
          {standalone.loading ? (
            <Spinner />
          ) : standalone.error ? (
            <CannotFind />
          ) : (
            <RecipeStandalone recipe={standalone.recipe} margin="" />
          )}
        </div>
      </div>
      {!isAuthenticated && (
        <div>
          <Footer />
        </div>
      )}
    </>
  );
};

RecipePage.propTypes = {
  getRecipeFromURL: PropTypes.func.isRequired,
  recipe: PropTypes.object.isRequired,
  resetStandaloneRecipe: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
  auth: state.auth,
});

export default connect(mapStateToProps, { getRecipeFromURL, resetStandaloneRecipe })(RecipePage);
