import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//API
import api from "../../../api/api";

//Actions
import { setAlert } from "../../../actions/alert";
import { setPostInteractions, getCommentsForPost, deleteComment } from "../../../actions/feed";

//Icons
import { ReactComponent as ReportIcon } from "../../../assets/icons/svg/report.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/svg/trash.svg";
import { Button, Typography } from "@mui/material";

const CommentOptionsModal = ({
  comment,
  post,
  postid,
  auth: { user, isAuthenticated },
  setAlert,
  handleClose,
  getCommentsForPost,
  deleteComment,
}) => {
  const isMyComment = isAuthenticated ? comment.user._id === user._id : false;

  const [deleteAYS, setDeleteAYS] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  //TO DO:
  /*
    - My comment
        - Delete comment

    - Not My comment
        - Report comment
        - Report 
  */

  const handleReport = () => {
    //TO DO: Report post
    setAlert("Comment reported", "success");
    handleClose();
  };

  const handleDelete = async () => {
    setDeleteLoading(true);
    //TO DO: Re-fetch comments for post
    try {
      await deleteComment(postid, comment._id);
      //await getCommentsForPost(postid);
      setAlert("Comment deleted", "success");
      setDeleteLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
      setDeleteAYS(false);
      setAlert("Error deleting comment, please refresh and try again.", "error");
    }
  };

  const myComment = (
    <div className="w-full border-t border-separator">
      {deleteAYS ? (
        <Button
          className=" w-full h-[3rem] rounded-none justify-start pl-4"
          startIcon={
            <div className="w-8 flex justify-start">
              <TrashIcon className="h-[1rem] fill-red" />
            </div>
          }
          onClick={handleDelete}
          disabled={deleteLoading}
        >
          <Typography variant="subtitl1" className=" font-normal">
            Yes, Delete Comment
          </Typography>
        </Button>
      ) : (
        <Button
          className=" w-full h-[3rem] rounded-none justify-start pl-4"
          startIcon={
            <div className="w-8 flex justify-start">
              <TrashIcon className="h-[1rem] fill-red" />
            </div>
          }
          onClick={() => setDeleteAYS(true)}
        >
          <Typography variant="subtitl1" className=" font-normal">
            Delete Comment
          </Typography>
        </Button>
      )}
    </div>
  );

  const notMyComment = (
    <>
      {isAuthenticated && (
        <div className="w-full border-t border-separator">
          <Button
            className=" w-full h-[3rem] rounded-none justify-start pl-4"
            startIcon={
              <div className="w-8 flex justify-start">
                <ReportIcon className="h-[1rem] fill-red" />
              </div>
            }
            onClick={handleReport}
          >
            <Typography variant="subtitl1" className=" font-normal">
              Report
            </Typography>
          </Button>
        </div>
      )}
    </>
  );

  const determineOptions = () => {
    return isMyComment ? myComment : notMyComment;
  };

  return (
    <div className="flex flex-col items-center justify-center border-t border-separator first:border-t-0 last:border-none">
      {determineOptions()}
    </div>
  );
};
//post.user.id !== user._id
CommentOptionsModal.propTypes = {
  post: PropTypes.object.isRequired,
  postid: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  getCommentsForPost: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, getCommentsForPost, deleteComment })(CommentOptionsModal);
