import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

//Components
import Footer from "../layout/Footer";
import ImageDropzone from "../create/components/images/ImageDropzone";
import ImageReorderPreview from "../create/components/images/ImageReorderPreview";

import { useNavigate } from "react-router-dom";
import { setAlert } from "../../actions/alert";

//API
import api from "../../api/api";

//Icons
import { ReactComponent as BugIcon } from "../../assets/icons/svg/bug-slash-light.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/svg/discord.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/svg/envelope-light.svg";
import { ReactComponent as SuggestFeatureIcon } from "../../assets/icons/svg/sparkles-light.svg";
import { ReactComponent as PenIcon } from "../../assets/icons/svg/pen-line-light.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/svg/chevron-right.svg";

//Utils
import { getCroppedImg } from "../../utils/image";

/*
  Name* To reference them in future messages
  Username
  Email
  Body -> Feature request, general contact

  --- 

  
*/
const Contact = ({ auth: { user, isAuthenticated }, profile: { currentProfile }, media, setAlert }) => {
  const navigate = useNavigate();
  const [contactType, setContactType] = useState("initial");
  const [userAgent, setUserAgent] = useState("");
  const [platform, setPlatform] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDeviceInfo = () => {
      const userAgent = navigator.userAgent;
      const platform = navigator.platform;
      setUserAgent(userAgent ? userAgent : "");
      setPlatform(platform ? platform : "");
    };

    getDeviceInfo();
  }, []);

  const handleBugReportSubmit = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      //Append cropped images

      const images = media.images;

      const reqBody = {
        name: formData.name,
        email: formData.email,
        notes: formData.bugNotes,
        happened: formData.bugHappened,
        expected: formData.bugExpected,
        reproduce: formData.bugReproduce,
        userAgent: userAgent,
        platform: platform,
      };

      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          const image = images[i];

          console.log(image);

          const croppedImage = await getCroppedImg(image.previewUrl, image.cropped.croppedAreaPixels);

          //Convert dataURL to Blob
          const response = await fetch(croppedImage);
          const blob = await response.blob();
          formData.append("images", blob, `image-${i}.jpg`);
        }
      }

      //Append recipe data as JSON object
      formData.append("data", JSON.stringify(reqBody));

      console.log(formData);
      return;
      // Send the form data to your backend route
      try {
        const response = await api.post("/post/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setAlert("Post created", "success");
        return true;
      } catch (error) {
        // Handle the error
        console.error(error);

        //dispatch(setAlert("Error creating post. Refresh and try again.", "error"));
        return false;
      }

      console.log(formData);

      const req = await api.post("/contact/bugreport", reqBody);

      setLoading(false);
      setAlert("Bug Reported! Thank you!", "success");
      navigate("/");
    } catch (error) {
      setLoading(false);
      setAlert("An error occurred. Please refresh the page and try again.", "error");
    }
  };

  const handleGeneralContactSubmit = async () => {
    try {
      setLoading(true);
      const reqBody = {
        name: formData.name,
        email: formData.email,
        body: formData.general,
      };

      const req = await api.post("/contact/general", reqBody);

      setLoading(false);
      setAlert("Message sent!", "success");
      navigate("/");
    } catch (error) {
      setLoading(false);
      setAlert("An error occurred. Please refresh the page and try again.", "error");
    }
  };

  const handleFeatureContactSubmit = async () => {
    try {
      setLoading(true);
      const reqBody = {
        name: formData.name,
        email: formData.email,
        body: formData.general,
      };

      const req = await api.post("/contact/feature", reqBody);

      setLoading(false);
      setAlert("Message sent!", "success");
      navigate("/");
    } catch (error) {
      setLoading(false);
      setAlert("An error occurred. Please refresh the page and try again.", "error");
    }
  };

  const [formData, setFormData] = useState({
    name: currentProfile && currentProfile.name ? currentProfile.name : "",
    email: user && user.email ? user.email : "",
    general: "",
    bugHappened: "",
    bugExpected: "",
    bugReproduce: "",
    bugNotes: "",
  });

  const handleNameChange = (value) => {
    if (value.length < 128) {
      setFormData({ ...formData, name: value });
    }
  };

  const handleEmailChange = (value) => {
    if (value.length < 128) {
      setFormData({ ...formData, email: value });
    }
  };

  const handleGeneralChange = (value) => {
    if (value.length < 8196) {
      setFormData({ ...formData, general: value });
    }
  };

  const handleBugHappenedChange = (value) => {
    if (value.length < 8196) {
      setFormData({ ...formData, bugHappened: value });
    }
  };

  const handleBugExpectedChange = (value) => {
    if (value.length < 8196) {
      setFormData({ ...formData, bugExpected: value });
    }
  };

  const handleBugReproduceChange = (value) => {
    if (value.length < 8196) {
      setFormData({ ...formData, bugReproduce: value });
    }
  };

  const handleBugNotesChange = (value) => {
    if (value.length < 8196) {
      setFormData({ ...formData, bugNotes: value });
    }
  };

  const handleContactTypeSelect = (type) => {
    window.scrollTo(0, 0);

    //Reset states when changing
    setFormData({
      name: currentProfile && currentProfile.name ? currentProfile.name : "",
      email: user && user.email ? user.email : "",
      general: "",
      bugHappened: "",
      bugExpected: "",
      bugReproduce: "",
      bugNotes: "",
    });
    setContactType(type);
  };

  const initialHTML = (
    <div className="py-8 px-4 md:px-8 md:py-6">
      <div className="flex flex-row">
        <div className="mr-4">
          <MailIcon className="w-[1.25rem] fill-primaryText"></MailIcon>
        </div>
        <div className="mb-4">
          <Typography variant="h6" className="font-normal leading-none mb-1">
            Contact Us
          </Typography>
          <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
            Choose an option below to reach out to us
          </Typography>
        </div>
      </div>

      <Divider />
      <div className="mt-2 flex flex-col gap-2">
        <Button
          className="flex items-start text-start px-0 py-2 hover:bg-transparent"
          fullWidth
          onClick={() => handleContactTypeSelect("general")}
        >
          <div className="flex flex-row justify-between items-center w-full">
            <div className="w-full flex flex-row items-center">
              <div className="mr-4">
                <PenIcon className="w-[1.25rem] fill-secondaryText"></PenIcon>
              </div>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Get in touch
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="font-normal leading-tight text-secondaryText hidden md:inline-block"
                >
                  We'd love to hear from you. Don't hesitate to reach out!
                </Typography>
              </div>
            </div>
            <div className="ml-4">
              <ChevronRight className="h-[1rem] fill-primaryText" />
            </div>
          </div>
        </Button>
        <Divider />
        <Button
          className="flex items-start text-start px-0 py-2 hover:bg-transparent"
          fullWidth
          onClick={() => handleContactTypeSelect("bug")}
        >
          <div className="flex flex-row justify-between items-center w-full">
            <div className="w-full flex flex-row items-center">
              <div className="mr-4">
                <BugIcon className="w-[1.25rem] fill-secondaryText"></BugIcon>
              </div>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Report a bug
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="font-normal leading-tight text-secondaryText hidden md:inline-block"
                >
                  Something break or not look quite right? Let us know!
                </Typography>
              </div>
            </div>
            <div className="ml-4">
              <ChevronRight className="h-[1rem] fill-primaryText" />
            </div>
          </div>
        </Button>
        <Divider />
        <Button
          className="flex items-start text-start px-0 py-2 hover:bg-transparent"
          fullWidth
          onClick={() => handleContactTypeSelect("feature")}
        >
          <div className="flex flex-row justify-between items-center w-full">
            <div className="w-full flex flex-row items-center">
              <div className="mr-4">
                <SuggestFeatureIcon className="w-[1.25rem] fill-secondaryText"></SuggestFeatureIcon>
              </div>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Suggest a Feature
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="font-normal leading-tight text-secondaryText hidden md:inline-block"
                >
                  Something we should add or improve? We'd love to hear about it!
                </Typography>
              </div>
            </div>
            <div className="ml-4">
              <ChevronRight className="h-[1rem] fill-primaryText" />
            </div>
          </div>
        </Button>
        <Divider />
        <Button className="flex items-start text-start px-0 py-2 hover:bg-transparent" fullWidth>
          <div className="flex flex-row justify-between items-center w-full">
            <div className="w-full flex flex-row items-center">
              <div className="mr-4">
                <DiscordIcon className="w-[1.25rem] fill-none stroke-discord stroke-[24] stroke-dash stroke-linejoin-round"></DiscordIcon>
              </div>
              <div>
                <Typography variant="subtitle1" className="font-normal">
                  Join our Discord
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="font-normal leading-tight text-secondaryText hidden md:inline-block"
                >
                  Get help with anything quickly or share your creations in our Discord community!
                </Typography>
              </div>
            </div>
            <div className="ml-4">
              <ChevronRight className="h-[1rem] fill-primaryText" />
            </div>
          </div>
        </Button>
      </div>
    </div>
  );

  const generalHTML = (
    <div className="py-8 px-4 md:px-8 md:py-6">
      <div className="flex flex-col">
        <div>
          <Button
            className="hover:bg-transparent text-secondaryText p-0 justify-start mb-4"
            onClick={() => handleContactTypeSelect("initial")}
          >
            <Typography className="body1">{`< Go Back`}</Typography>
          </Button>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">
            <PenIcon className="h-[1.25rem] fill-primaryText"></PenIcon>
          </div>
          <div className="mb-4">
            <Typography variant="h6" className="font-normal leading-none mb-1">
              Get in Touch
            </Typography>
            <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
              We'd love to hear from you
            </Typography>
          </div>
        </div>
      </div>

      <Divider />

      <div className="py-4 mt-4">
        <div className="flex flex-col gap-4">
          <TextField
            label="Name"
            fullWidth
            helperText="What should we call you?"
            value={formData.name}
            onChange={(e) => handleNameChange(e.target.value)}
          ></TextField>

          <TextField
            label="Email"
            fullWidth
            helperText="How should we reach out?"
            value={formData.email}
            onChange={(e) => handleEmailChange(e.target.value)}
          ></TextField>
          <TextField
            label="Let us know"
            fullWidth
            multiline
            minRows={5}
            maxRows={10}
            helperText="Tell us what's on your mind!"
            value={formData.general}
            onChange={(e) => handleGeneralChange(e.target.value)}
          ></TextField>
        </div>
        <div className="mt-2 flex flex-row w-full justify-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={() => handleGeneralContactSubmit()}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );

  const bugHTML = (
    <div className="py-8 px-4 md:px-8 md:py-6">
      <div className="flex flex-col">
        <div>
          <Button
            className="hover:bg-transparent text-secondaryText p-0 justify-start mb-4"
            onClick={() => handleContactTypeSelect("initial")}
          >
            <Typography className="body1">{`< Go Back`}</Typography>
          </Button>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">
            <BugIcon className="h-[1.25rem] fill-primaryText"></BugIcon>
          </div>
          <div className="mb-4">
            <Typography variant="h6" className="font-normal leading-none mb-1">
              Report a Bug
            </Typography>
            <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
              Something break or not look quite right? Let us know!
            </Typography>
          </div>
        </div>
      </div>

      <Divider />

      <div className="py-4 mt-4">
        <div className="flex flex-col gap-4">
          <TextField
            label="Name"
            fullWidth
            helperText="What should we call you?"
            value={formData.name}
            onChange={(e) => handleNameChange(e.target.value)}
          ></TextField>

          <TextField
            label="Email"
            fullWidth
            helperText="How should we reach out?"
            value={formData.email}
            onChange={(e) => handleEmailChange(e.target.value)}
          ></TextField>
          <TextField
            label="What happened?"
            fullWidth
            multiline
            minRows={3}
            maxRows={10}
            helperText="Explain the bug"
            value={formData.bugHappened}
            onChange={(e) => handleBugHappenedChange(e.target.value)}
          ></TextField>
          <TextField
            label="What did you expect to happen?"
            fullWidth
            multiline
            minRows={3}
            maxRows={10}
            helperText="What should have happened?"
            value={formData.bugExpected}
            onChange={(e) => handleBugExpectedChange(e.target.value)}
          ></TextField>
          <TextField
            label="What were you doing before?"
            fullWidth
            multiline
            minRows={3}
            maxRows={10}
            helperText="Steps to help us reproduce the bug"
            value={formData.bugReproduce}
            onChange={(e) => handleBugReproduceChange(e.target.value)}
          ></TextField>
          <TextField
            label="Additional notes"
            fullWidth
            multiline
            minRows={3}
            maxRows={10}
            helperText="Any extra info that might help us fix the issue"
            value={formData.bugNotes}
            onChange={(e) => handleBugNotesChange(e.target.value)}
          ></TextField>
          <ImageDropzone />
          <ImageReorderPreview />
        </div>
        <div className="mt-2 flex flex-row w-full justify-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={() => handleBugReportSubmit()}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );

  const featureHTML = (
    <div className="py-8 px-4 md:px-8 md:py-6">
      <div className="flex flex-col">
        <div>
          <Button
            className="hover:bg-transparent text-secondaryText p-0 justify-start mb-4"
            onClick={() => handleContactTypeSelect("initial")}
          >
            <Typography className="body1">{`< Go Back`}</Typography>
          </Button>
        </div>
        <div className="flex flex-row">
          <div className="mr-4">
            <SuggestFeatureIcon className="h-[1.25rem] fill-primaryText"></SuggestFeatureIcon>
          </div>
          <div className="mb-4">
            <Typography variant="h6" className="font-normal leading-none mb-1">
              Suggest a Feature
            </Typography>
            <Typography variant="subtitle2" className="text-secondaryText leading-tight font-normal">
              Something we should add or improve? We'd love to hear about it!
            </Typography>
          </div>
        </div>
      </div>

      <Divider />

      <div className="py-4 mt-4">
        <div className="flex flex-col gap-4">
          <TextField
            label="Name"
            fullWidth
            helperText="What should we call you?"
            value={formData.name}
            onChange={(e) => handleNameChange(e.target.value)}
          ></TextField>
          <TextField
            label="Email"
            fullWidth
            helperText="How should we reach out?"
            value={formData.email}
            onChange={(e) => handleEmailChange(e.target.value)}
          ></TextField>
          <TextField
            label="Let us know"
            fullWidth
            multiline
            minRows={5}
            maxRows={10}
            helperText="Tell us what's on your mind!"
            value={formData.general}
            onChange={(e) => handleGeneralChange(e.target.value)}
          ></TextField>
        </div>
        <div className="mt-2 flex flex-row w-full justify-end">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading}
            onClick={() => handleFeatureContactSubmit()}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full md:max-w-[600px] md:m-auto md:my-8 md:border md:border-separator md:rounded-[16px]">
      {contactType === "initial" && initialHTML}
      {contactType === "general" && generalHTML}
      {contactType === "bug" && bugHTML}
      {contactType === "feature" && featureHTML}
    </div>
  );
};

Contact.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { setAlert })(Contact);
