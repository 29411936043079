import React, { useEffect } from "react";

const InFeedAd = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key="-6t+ed+2i-1n-4w"
      data-ad-client="ca-pub-8198430119025128"
      data-ad-slot="3308041530"
    ></ins>
  );
};

export default InFeedAd;
