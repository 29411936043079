import React, { useRef, useState, useEffect } from "react";

import { styled } from "@mui/material/styles";

import useEmblaCarousel from "embla-carousel-react";

import defaultImage from "../../../../assets/default/gallery.png";

//Future improvement, if post has been seen before and more than one image, default to next image
const ImageCarousel = ({
  data,
  rootStyles = null,
  showDots = true,
  dotsTop = false,
  dotsAlign = "center",
  showEmpty = false,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  useEffect(() => {
    if (emblaApi) {
      setScrollSnaps(emblaApi.scrollSnapList());
      emblaApi.on("select", () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  //TO DO: 0 images = default image
  //1 image = static image, no carousel
  //Toggle dots

  if (showEmpty && data.length === 0) {
    return (
      <div
        className={`max-w-full h-full mx-auto overflow-hidden relative w-full pb-full${
          rootStyles ? " " + rootStyles : ""
        }`}
      >
        <img src={defaultImage} alt="" className="absolute top-0 left-0 w-full h-full z-[1]" />
      </div>
    );
  }

  if (data.length === 1) {
    return (
      <div
        className={`max-w-full h-full mx-auto overflow-hidden w-full relative pb-full${
          rootStyles ? " " + rootStyles : ""
        }`}
      >
        <img src={data[0].url} alt="" className="absolute top-0 left-0 w-full h-full z-[1]" />
      </div>
    );
  }
  return (
    <div className={`max-w-full h-full mx-auto overflow-hidden relative${rootStyles ? " " + rootStyles : ""}`}>
      <div className="overflow-hidden w-full h-full" ref={emblaRef}>
        <div className="flex w-full h-full">
          {data.map((image, index) => (
            <div className="flex-none w-full h-full pt-full relative" key={index}>
              <img src={image.url} alt="" className="absolute top-0 left-0 w-full h-full object-cover z-[1]" />
            </div>
          ))}
        </div>
        {showDots && (
          <div
            className={
              dotsTop
                ? `absolute top-4 w-full flex justify-[${({ dotsAlign }) =>
                    dotsAlign === "left" ? "start" : dotsAlign === "right" ? "end" : "center"}] items-center mx-4 z-3`
                : `absolute bottom-4 w-full flex justify-[${({ dotsAlign }) =>
                    dotsAlign === "left" ? "start" : dotsAlign === "right" ? "end" : "center"}] items-center mx-4 z-3`
            }
          >
            <div className="flex justify-center items-center">
              {scrollSnaps.map((snap, index) => (
                <div className="inline-flex justify-center items-center">
                  <button
                    key={index}
                    className={`w-2 !important h-2 !important rounded-full m-1 cursor-pointer p-0  ${
                      index === selectedIndex ? "bg-white" : "bg-white/30 border border-black/40"
                    }`}
                    onClick={() => emblaApi.scrollTo(index)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ImageCarousel;
