import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography } from "@mui/material";

//Components
import RecipeSection from "./RecipeSection";

const RecipeSectionManager = ({ recipe: { create }, setShowAddSection, setInitialAddType }) => {
  const handleAddSectionClick = () => {
    setInitialAddType("add");
    setShowAddSection(true);
  };
  return (
    <div className="my-4">
      {create && create.sections && create.sections.length === 0 ? (
        <div>
          <div onClick={() => handleAddSectionClick()}>
            <Typography variant="subtitle1" className="font-normal">
              Add a section to get started
            </Typography>
            <Typography variant="subtitle2" className="text-secondaryText font-normal mr-4 md:mr-0 md:max-w-[50%]">
              Sections are used to group ingredients and steps, such as the icing on a cake or the side of a meal.
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          {create.sections.map((section, sectionIndex) => (
            <RecipeSection section={section} sectionIndex={sectionIndex} key={sectionIndex} />
          ))}
        </div>
      )}
    </div>
  );
};

RecipeSectionManager.propTypes = {
  recipe: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  recipe: state.recipe,
});

export default connect(mapStateToProps, {})(RecipeSectionManager);
