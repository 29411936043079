import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
//UI
import { Typography, TextField, IconButton, InputAdornment, Divider } from "@mui/material";

//Infinite scroll
import InfiniteScroll from "react-infinite-scroll-component";

//Components
import Spinner from "../../../layout/Spinner";
import RecipeCard from "../../../recipes/card/RecipeCard";

//Actions
import { getCookbook, setCookbookQuery } from "../../../../actions/cookbook";

//Icons
import { ReactComponent as TitleIcon } from "../../../../assets/icons/svg/fire.svg";
import { ReactComponent as SearchFiltersIcon } from "../../../../assets/icons/svg/sliders-light.svg";

//Modals
import CustomModal from "../../../modal/CustomModal";
import FiltersModal from "../modal/FiltersModal";

const Trending = ({
  sectionData: { recipes, loading, error },
  search: { query, preferences, filters },
  getCookbook,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const recipeSearch = () => {
    return null;
  };

  const isEndOfFeed = false;

  const toggleFilterModal = (state) => {
    setShowFilterModal(state);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    //Remove later, just fetching data for now on load to test
    if (loading) {
      getCookbook();
    }
  }, []);

  const fetchMoreData = () => {
    console.log("fetchMoreData");
  };

  const refreshFeed = () => {};
  const getFilterCount = () => {
    return filters?.allergies.length + filters?.diets.length + filters?.include.length + filters?.exclude.length;
  };
  const handleQueryChange = (e) => {
    if (e.target.value.length > 512) {
      return;
    }

    recipeSearch(e.target.value, filters, 0);
    setCookbookQuery(e.target.value);
  };
  return (
    <div className="py-[4rem] md:py-0 md:pl-[5rem]">
      <CustomModal open={showFilterModal} handleClose={() => toggleFilterModal(false)}>
        <FiltersModal handleClose={() => toggleFilterModal(false)} />
      </CustomModal>
      <div className="p-4 md:p-8">
        <div>
          <Link to="/cookbook">
            <div className="mb-2">
              <Typography variant="subtitle2" className="text-secondaryText font-normal">{`< Go Back`}</Typography>
            </div>
          </Link>

          <div className="flex flex-row gap-4 items-center mb-4">
            <TitleIcon className="h-[1.25rem] fill-secondaryText" />
            <Typography variant="h6" className="font-normal leading-none">
              Trending
            </Typography>
          </div>
        </div>
        <div className="w-full mb-4 mt-8">
          <TextField
            fullWidth
            label="Search..."
            helperText={`${getFilterCount()} filters applied`}
            size="small"
            value={query}
            onChange={handleQueryChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => toggleFilterModal(true)}>
                    <SearchFiltersIcon className="fill-primaryText h-[1rem]" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="mt-4">
            <Divider />
          </div>
        </div>
        <div>
          <InfiniteScroll
            dataLength={recipes ? recipes.length : 0}
            next={fetchMoreData}
            hasMore={!isEndOfFeed}
            loader={loading && <Spinner />}
            pullDownToRefresh
            refreshFunction={refreshFeed}
            endMessage={
              <div className="m-8">
                <Typography variant="subtitle2" className="font-normal text-secondaryGray">
                  You've reached the end of the feed
                </Typography>
              </div>
            }
            className="overflow-hidden w-full"
          >
            <div className="grid gap-2 auto-fill-minmax-200 md:auto-fill-minmax-300 w-full">
              {recipes &&
                recipes.map((recipe) => <RecipeCard key={recipe.id} recipeid={recipe.id} errorType="deleted" />)}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

Trending.propTypes = {
  sectionData: PropTypes.object.isRequired,
  getCookbook: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  setCookbookQuery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sectionData: state.cookbook.results.trending,
  search: state.cookbook.search,
});

export default connect(mapStateToProps, { getCookbook, setCookbookQuery })(Trending);
