import React from "react";
import Typography from "@mui/material/Typography";

const RecipeMacrosItem = ({ title, item, type }) => {
  return (
    <div>
      {type === "primary" ? (
        <div className="flex flex-row justify-between items-center">
          <Typography variant="subtitle2" className="font-normal">
            {title}
          </Typography>
          <div className="flex-1 border-b border-separator mx-2"></div>
          <div className="flex flex-row">
            <Typography variant="body2">{item.value}</Typography>
            <Typography variant="body2" className="ml-2">
              {item.unit}
            </Typography>
          </div>
        </div>
      ) : (
        <div className="flex flex-row justify-between items-center ml-4">
          <Typography variant="subtitle2" className="italic font-normal text-secondaryText">
            {title}
          </Typography>
          <div className="flex-1 border-b border-separator mx-2"></div>
          <div className="flex flex-row">
            <Typography variant="body2">{item.value}</Typography>
            <Typography variant="body2" className="ml-2">
              {item.unit}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipeMacrosItem;
