import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

//MUI
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

//Icons
import { ReactComponent as PlusIcon } from "../../assets/icons/svg/plus.svg";

//Infinite scroll
import InfiniteScroll from "react-infinite-scroll-component";

//Components
import Spinner from "../layout/Spinner";
import PostManager from "./PostManager";
import UpdateManager from "../home/UpdateManager";

//Ads
import InFeedAd from "../ads/feed/InFeedAd";

//Actions
import { getHomeFeed } from "../../actions/feed";
import { toggleCreateModal } from "../../actions/navigation";

const FeedManager = ({ getHomeFeed, feed: { posts, loading, isEndOfFeed }, toggleCreateModal }) => {
  useEffect(() => {
    getHomeFeed();
  }, []);

  const fetchMoreData = () => {
    if (!loading) {
      getHomeFeed();
    }
  };

  const refreshFeed = () => {
    getHomeFeed();
  };

  /*
  const handleRefresh = () => {
    console.log("ur ");
  };
*/
  return (
    <div className="w-full flex flex-col items-center justify-center md:pl-[5rem]">
      <UpdateManager />
      <div className="w-full max-w-[768px] md:max-w-[600px] flex flex-col items-center [&_>_div:first-child]:w-full [&_>_div:first-child]:overflow-x-hidden my-[4rem] md:my-0 [&_>_div]:w-full ">
        <div className="flex flex-row justify-between md:rounded-lg border-t md:border border-separator shadow-sm">
          <div className="w-1/2 p-4">
            <Link to="/create/post">
              <div className="flex flex-row gap-2 items-center">
                <PlusIcon className="h-[0.75rem] fill-mainGreen" />
                <Typography variant="subtitle1" className="font-normal text-primaryText leading-snug">
                  Create Post
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText hidden md:inline-block">
                Share your culinary creations
              </Typography>
            </Link>
          </div>

          <div className="w-1/2 border-l border-separator p-4">
            <Button
              onClick={() => toggleCreateModal(true)}
              disableRipple
              className="p-0 flex flex-col items-start justify-between h-full hover:bg-transparent"
            >
              <div className="flex flex-row gap-2 items-center">
                <PlusIcon className="h-[0.75rem] fill-mainGreen" />
                <Typography variant="subtitle1" className="font-normal text-primaryText leading-snug">
                  Add Recipe
                </Typography>
              </div>

              <Typography variant="caption" className="text-secondaryText hidden md:inline-block">
                Create, Generate, or Import
              </Typography>
            </Button>
          </div>
        </div>
        <InfiniteScroll
          dataLength={posts ? posts.length : 0}
          next={fetchMoreData}
          hasMore={!isEndOfFeed}
          loader={loading && <Spinner />}
          pullDownToRefresh
          refreshFunction={refreshFeed}
          endMessage={
            <div className="m-8">
              <Typography variant="subtitle2" className="font-normal text-secondaryGray">
                You've reached the end of the feed
              </Typography>
            </div>
          }
          className="overflow-hidden w-full [&_>_div]:md:mb-8"
        >
          {posts &&
            posts.map((post, i) => (
              <>
                <PostManager index={i} key={i} />
                {(i === 4 || (i > 4 && (i - 4) % 3 === 0)) && <InFeedAd key={`ad-${i}`} />}
              </>
            ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

FeedManager.propTypes = {
  // isAuthenticated: PropTypes.bool,
  getHomeFeed: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  toggleCreateModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.auth.isAuthenticated,
  feed: state.feed,
});

export default connect(mapStateToProps, { getHomeFeed, toggleCreateModal })(FeedManager);
