//Import redux types
import {
  GET_MY_INGREDIENTS,
  MY_INGREDIENTS_ERROR,
  SEARCH_SINGLE_INGREDIENT,
  SEARCH_SINGLE_INGREDIENT_ERROR,
  RESET_SINGLE_INGREDIENT_SEARCH,
  SELECT_SINGLE_INGREDIENT_TO_ADD,
  START_SINGLE_INGREDIENT_SEARCH,
  PANTRY_SELECT_ITEM_BY_ID,
  PANTRY_CLOSE_ITEM_MODAL,
  PANTRY_OPEN_ITEM_MODAL,
  PANTRY_ITEM_MODAL_ERROR,
  RESET_ADD_PANTRY,
} from "../actions/types";

//Initial state object
const initialState = {
  items: {
    fridge: [],
    freezer: [],
    pantry: [],
    spicerack: [],
    loading: false,
    error: false,
    numSelected: 0,
  },
  itemModal: {
    show: false,
    loading: true,
    error: false,
    data: null,
  },
  addToPantry: {
    searchOptions: [],
    searchLoading: false,
    searchError: false,
    selected: null,
  },
};

export default function (state = initialState, action) {
  //Destructure action
  const { type, payload } = action;

  switch (type) {
    case GET_MY_INGREDIENTS:
      return {
        ...state,
        items: {
          ...state.items,
          fridge: payload.fridge,
          freezer: payload.freezer,
          pantry: payload.pantry,
          spicerack: payload.spicerack,
          loading: false,
          error: false,
        },
      };
    case MY_INGREDIENTS_ERROR:
      return {
        ...state,
        items: {
          fridge: [],
          freezer: [],
          pantry: [],
          spicerack: [],
          loading: false,
          error: true,
        },
      };
    case SEARCH_SINGLE_INGREDIENT:
      return {
        ...state,
        addToPantry: {
          ...state.addToPantry,
          searchOptions: payload,
          searchError: false,
          searchLoading: false,
        },
      };
    case SEARCH_SINGLE_INGREDIENT_ERROR:
      return {
        ...state,
        addToPantry: {
          ...state.addToPantry,
          searchOptions: [],
          searchError: true,
          searchLoading: false,
        },
      };
    case RESET_SINGLE_INGREDIENT_SEARCH:
      return {
        ...state,
        addToPantry: {
          ...state.addToPantry,
          searchOptions: [],
          searchError: false,
          searchLoading: false,
        },
      };
    case START_SINGLE_INGREDIENT_SEARCH:
      return {
        ...state,
        addToPantry: {
          ...state.addToPantry,
          searchLoading: true,
          searchError: false,
        },
      };
    case SELECT_SINGLE_INGREDIENT_TO_ADD:
      return {
        ...state,
        addToPantry: {
          ...state.addToPantry,
          selected: payload,
        },
      };

    case PANTRY_SELECT_ITEM_BY_ID:
      return {
        ...state,
        items: payload,
      };
    case RESET_ADD_PANTRY:
      return {
        ...state,
        addToPantry: {
          searchOptions: [],
          searchLoading: false,
          searchError: false,
          selected: null,
        },
      };
    default:
      return state;
  }
}
