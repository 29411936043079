import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//Sections
import ForYou from "./sections/foryou/ForYou";
import ProfileSearch from "./sections/profiles/ProfileSearch";

//TO DO:
//Maintain bottom/desktop nav bar when in search to allow for easy navigation away from search
//If typing, switch to users search by default
//Clicking on recipes takes to cookbook and prefills that search
const Search = ({ search: { query, response } }) => {
  return (
    <div className="w-full">
      <div className="m-2 mb-4 md:m-4">{query.type === "trending" && <ForYou />}</div>
      <div className="m-2 mb-4 md:m-4">{query.type === "profiles" && <ProfileSearch query={query} />}</div>
    </div>
  );
};

Search.propTypes = {
  search: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  search: state.search,
});

export default connect(mapStateToProps, {})(Search);
