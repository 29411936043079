import React from "react";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Actions
import { hideRegisterModal } from "../../../actions/auth";
import { setSignupStage } from "../../../actions/register";
//MUI Styling
import { useMediaQuery } from "@mui/material";

//MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

//Logo
import { ReactComponent as Logo } from "../../../assets/logos/full_logo.svg";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as GoogleIcon } from "../res/google.svg";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const PREFIX = 'Initial';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  content: `${PREFIX}-content`,
  button_container: `${PREFIX}-button_container`,
  agree: `${PREFIX}-agree`,
  iconEdgeButton: `${PREFIX}-iconEdgeButton`,
  startIconCustom: `${PREFIX}-startIconCustom`,
  google: `${PREFIX}-google`,
  subtitle: `${PREFIX}-subtitle`,
  exit: `${PREFIX}-exit`,
  link: `${PREFIX}-link`,
  signin_prompt: `${PREFIX}-signin_prompt`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    height: "100%",
    width: "100%",
    marginTop: theme.spacing(6),
  },

  [`& .${classes.logo}`]: {
    height: "5rem",
    "-webkit-user-select": "none", // Safari
    "-ms-user-select": "none", // IE 10 and IE 11
    userSelect: "none", // Standard syntax
    fill: theme.palette.mainGreen,
    [theme.breakpoints.down('md')]: {
      height: "4rem",
    },
  },

  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    textAlign: "center",
    padding: theme.spacing(4),
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
      paddingTop: theme.spacing(2),
    },
  },

  [`& .${classes.button_container}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 10),
    margin: theme.spacing(8, 0),
    marginTop: theme.spacing(4),
    gap: "0.75rem",
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
      paddingBottom: theme.spacing(2),
      margin: 0,
    },
  },

  [`& .${classes.agree}`]: {
    color: theme.palette.textOnWhite + "cc", //80% = cc
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },

  [`& .${classes.iconEdgeButton}`]: {
    position: "relative",
    textTransform: "none",
    paddingLeft: 40, // Adjust this to control spacing between the icon and the text
  },

  [`& .${classes.startIconCustom}`]: {
    position: "absolute",
    left: "1rem", // This determines the gap between the left edge and the icon
  },

  [`& .${classes.google}`]: {
    height: "1.5rem",
    width: "1.5rem",
  },

  [`& .${classes.subtitle}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.exit}`]: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(4),
    color: theme.palette.textOnWhite,
  },

  [`& .${classes.link}`]: {
    color: theme.palette.mainGreen,
  },

  [`& .${classes.signin_prompt}`]: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  }
}));

const Initial = ({ hideRegisterModal, setSignupStage }) => {

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  /* 3PA PLACE INBETWEEN GOOGLE AND EMAIL & CHANGE LINK TO CORRECT URL
 <Button
          variant="outlined"
          fullWidth
          startIcon={
            <i className="fa-brands fa-x-twitter" style={{ color: "#000" }}></i>
          }
          classes={{
            root: classes.iconEdgeButton,
            startIcon: classes.startIconCustom,
          }}
          size={isSmallScreen ? 'medium' : 'large'}
          component={Link}
          to={{ pathname: "http://localhost:5000/api/auth/twitter" }} target="_blank"
        >
          Sign Up with Twitter
        </Button>
        <Button
          variant="outlined"
          fullWidth
          startIcon={
            <i
              className="fa-brands fa-facebook"
              style={{ color: "#0866ff" }}
            ></i>
          }
          classes={{
            root: classes.iconEdgeButton,
            startIcon: classes.startIconCustom,
          }}
          size={isSmallScreen ? 'medium' : 'large'}
          component={Link}
          to={{ pathname: "http://localhost:5000/api/auth/facebook" }} target="_blank"
        >
          Sign Up with Facebook
        </Button>
        <Button
          variant="outlined"
          fullWidth
          startIcon={
            <i className="fa-brands fa-apple" style={{ color: "#000" }}></i>
          }
          classes={{
            root: classes.iconEdgeButton,
            startIcon: classes.startIconCustom,
          }}
          size={isSmallScreen ? 'medium' : 'large'}
          component={Link}
          to={{ pathname: "http://localhost:5000/api/auth/apple" }} target="_blank"
        >
          Sign Up with Apple
        </Button>

    */
  return (
    <Root className={classes.root}>
      <div className={classes.content}>
        <Logo className={classes.logo} />
        <Typography
          variant={isSmallScreen ? "subtitle1" : "h6"}
          className={classes.subtitle}
        >
          Get in early.<br></br>Sign Up for the Private Beta.
        </Typography>
        <div className={classes.button_container}>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<GoogleIcon className={classes.google} />}
            classes={{
              root: classes.iconEdgeButton,
              startIcon: classes.startIconCustom,
            }}
            size={isSmallScreen ? "medium" : "large"}
            component={Link}
            to={"/api/auth/google"}
            target="_blank"
          >
            Sign Up with Google
          </Button>

          <Button
            variant="outlined"
            fullWidth
            startIcon={<FontAwesomeIcon icon={faEnvelope} />}
            classes={{
              root: classes.iconEdgeButton,
              startIcon: classes.startIconCustom,
            }}
            size={isSmallScreen ? "medium" : "large"}
            onClick={() => setSignupStage(0)}
          >
            Sign Up with Email
          </Button>
          <Typography variant="subtitle2" className={classes.signin_prompt}>
            Already have an account?{" "}
            <Link
              to="/login"
              className={classes.link}
              onClick={() => hideRegisterModal()}
            >
              Sign In
            </Link>
          </Typography>
          <Typography
            variant={isSmallScreen ? "caption" : "body2"}
            className={classes.agree}
          >
            Click "Sign Up" to agree to thisPantry's{" "}
            <Link to="/terms" className={classes.link}>
              Terms of Service
            </Link>{" "}
            and acknowledge that thisPantry's{" "}
            <Link to="/privacy" className={classes.link}>
              Privacy Policy
            </Link>{" "}
            applies to you.
          </Typography>
        </div>
      </div>
    </Root>
  );
};

Initial.propTypes = {
  hideRegisterModal: PropTypes.func.isRequired,
  setSignupStage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { hideRegisterModal, setSignupStage })(
  Initial
);
