import * as React from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart, CartesianGrid, LabelList, Line, LineChart, XAxis } from "recharts";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ChartContainer, ChartConfig, ChartTooltip, ChartTooltipContent } from "../ui/chart";

import { Typography, IconButton } from "@mui/material";

//Icons
import { ReactComponent as ChevronLeft } from "../../assets/icons/svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/svg/chevron-right.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/svg/link.svg";
import { ReactComponent as TitleIcon } from "../../assets/icons/svg/chart-line.svg";

//Charts
import RadialChart from "../charts/RadialChart";
import HorizontalBarChart from "../charts/HorizontalBarChart";
import MultiAreaChart from "../charts/MultiAreaChart";

export const InsightsPage = () => {
  //Page structure
  //Quick facts for macros, expenses, food waste/ingredients
  //

  return (
    <div className="py-[4rem] md:py-0 md:pl-[5rem]">
      <div className="p-4 md:p-8">
        <div className="mb-8">
          <div className="flex flex-row gap-2 items-center mb-1">
            <TitleIcon className="h-[1.25rem] fill-secondaryText" />
            <Typography variant="h5" className="font-normal leading-none">
              Insights
            </Typography>
          </div>

          <Typography variant="subtitle2" className="text-secondaryText font-normal">
            Your kitchen at a glance. Tap on a section to dive deeper.
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-y-4 md:flex-row md:gap-4">
          <div className="w-full aspect-square p-4 flex flex-col justify-between border border-separator rounded-lg shadow-sm">
            <div>
              <div className="flex flex-row gap-2 items-center">
                <Typography variant="h6" className="leading-none font-normal">
                  Macronutrients
                </Typography>
                <LinkIcon className="h-[0.75rem] fill-secondaryText" />
              </div>

              <Typography variant="subtitle2" className="text-secondaryText font-normal">
                Today so far
              </Typography>
            </div>
            <div className="py-4">
              <RadialChart />
            </div>
            <div className="p-0">
              <div className="w-full flex flex-row justify-between items-center">
                <IconButton>
                  <ChevronLeft className="h-[1rem] fill-secondaryText" />
                </IconButton>
                <Typography>Calories</Typography>
                <IconButton>
                  <ChevronRight className="h-[1rem] fill-secondaryText" />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="w-full aspect-square p-4 flex flex-col justify-between border border-separator rounded-lg shadow-sm">
            <div>
              <div className="flex flex-row gap-2 items-center">
                <Typography variant="h6" className="leading-none font-normal">
                  Kitchen Efficiency
                </Typography>
                <LinkIcon className="h-[0.75rem] fill-secondaryText" />
              </div>

              <Typography variant="subtitle2" className="text-secondaryText font-normal">
                How much you're getting out of your groceries
              </Typography>
            </div>
            <div className="py-4">
              <HorizontalBarChart />
            </div>
            <div className="p-0">
              <div className="w-full flex flex-row justify-between items-center">
                <IconButton>
                  <ChevronLeft className="h-[1rem] fill-secondaryText" />
                </IconButton>
                <Typography>Efficiency (%)</Typography>
                <IconButton>
                  <ChevronRight className="h-[1rem] fill-secondaryText" />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="w-full aspect-square p-4 flex flex-col justify-between border border-separator rounded-lg shadow-sm">
            <div className="mb-4">
              <div className="flex flex-row gap-2 items-center">
                <Typography variant="h6" className="leading-none font-normal">
                  Expenses
                </Typography>
                <LinkIcon className="h-[0.75rem] fill-secondaryText" />
              </div>

              <Typography variant="subtitle2" className="text-secondaryText font-normal">
                This month
              </Typography>
            </div>
            <div className="py-4">
              <MultiAreaChart />
            </div>
            <div className="p-0">
              <div className="w-full flex flex-row justify-between items-center">
                <IconButton>
                  <ChevronLeft className="h-[1rem] fill-secondaryText" />
                </IconButton>
                <Typography>Food Expenses</Typography>
                <IconButton>
                  <ChevronRight className="h-[1rem] fill-secondaryText" />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InsightsPage.propTypes = {
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    data: state.data,
  };
};

export default connect(mapStateToProps)(InsightsPage);
