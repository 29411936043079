import React, { useState, useEffect, useRef, Fragment } from "react";

import { motion, useInView, AnimatePresence } from "framer-motion";

//Animation images
//Pantry 1
import pantry_1_3 from "../../img/pantry/1/receipt_scan.png";
import pantry_1_2 from "../../img/pantry/1/pantry.png";
import pantry_1_1 from "../../img/pantry/1/item.png";
//Pantry 2
import pantry_2_1 from "../../img/pantry/2/notification.png";
import pantry_2_2 from "../../img/pantry/2/ui_2.png";
import pantry_2_3 from "../../img/pantry/2/ui.png";
//Pantry 3
import pantry_3_1 from "../../img/pantry/3/pantry.png";
import pantry_3_2 from "../../img/pantry/3/recipe.png";
//Social
import social_1_1 from "../../img/social/post.png";
import social_1_2 from "../../img/social/messages.png";
import social_1_3 from "../../img/social/comment.png";

const FramerAnimationManager = ({ animation, fadeSrc, visible }) => {
  const popIn = {
    hidden: { scale: 0 },
    visible: {
      scale: [0, 1.2, 1],
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
        duration: 0.3,
      },
    },
  };

  //Animation HTML
  const pantry1 = (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
      className="w-auto h-full max-w-[calc(100vw-2rem)] overflow-visible aspect-square flex items-center top-0 left-0 right-0 bottom-0 relative"
    >
      <motion.img
        src={pantry_1_1}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: 0,
          },
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[30%] absolute left-[5%] rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
      <motion.img
        src={pantry_1_2}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: 0,
          },
        }}
        transition={{
          duration: 0.25,
          ease: "easeOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[37.5%] absolute left-0 right-0 m-auto z-10 rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
      <motion.img
        src={pantry_1_3}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: 0,
          },
        }}
        transition={{
          duration: 0.25,
          ease: "easeOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[30%] absolute right-[5%] rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
    </motion.div>
  );

  const pantry2 = (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.25,
          },
        },
      }}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
      className="w-auto h-full max-w-[calc(100vw-2rem)] overflow-visible aspect-square flex items-center top-0 left-0 right-0 bottom-0 relative"
    >
      <motion.img
        src={pantry_2_1}
        variants={popIn}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        className="h-auto w-[55%] absolute top-[7%] left-0 rounded-xl drop-shadow-md cursor-pointer z-20"
      ></motion.img>
      <motion.img
        src={pantry_2_3}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: "-47.5%",
          },
        }}
        transition={{
          duration: 0.3,

          ease: "easeOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[42.5%] absolute left-0 right-0 m-auto z-10 rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
      <motion.img
        src={pantry_2_2}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: "47.5%",
          },
        }}
        transition={{
          duration: 0.3,
          ease: "easeOut",
        }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[40%] absolute left-0 right-0 m-auto rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
    </motion.div>
  );

  const pantry3 = (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.25,
          },
        },
      }}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
      className="w-auto h-full max-w-[calc(100vw-2rem)] overflow-visible aspect-square flex items-center top-0 left-0 right-0 bottom-0 relative"
    >
      <motion.img
        src={pantry_3_2}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: "-47.5%",
          },
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[42.5%] absolute left-0 right-0 m-auto z-10 rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
      <motion.img
        src={pantry_3_1}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: "47.5%",
          },
        }}
        transition={{
          duration: 0.3,
          ease: "easeOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[40%] absolute left-0 right-0 m-auto rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
    </motion.div>
  );

  const social1 = (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.25,
          },
        },
      }}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
      className="w-auto h-full max-w-[calc(100vw-2rem)] overflow-visible aspect-square flex items-center top-0 left-0 right-0 bottom-0 relative"
    >
      <motion.img
        src={social_1_1}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: 0,
          },
        }}
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[50%] absolute left-0 right-0 m-auto rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
      <motion.img
        src={social_1_2}
        variants={{
          hidden: {
            opacity: 0,
            x: "100%",
          },
          visible: {
            opacity: 1,
            x: 0,
          },
        }}
        transition={{
          duration: 0.3,
          ease: "easeOut",
        }}
        whileHover={{ scale: 1.025 }}
        className="h-auto w-[35%] absolute right-0 bottom-[10%] m-auto z-10 rounded-xl drop-shadow-md cursor-pointer"
      ></motion.img>
      <motion.img
        src={social_1_3}
        variants={popIn}
        transition={{
          duration: 0.3,
          ease: "easeOut",
        }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        className="h-auto w-[55%] absolute left-0 top-0 rounded-xl drop-shadow-md cursor-pointer z-20"
      ></motion.img>
    </motion.div>
  );

  const charity = (
    <div className="w-auto h-full max-w-[calc(100vw-2rem)] overflow-visible aspect-square flex items-center justify-center">
      <div className="w-fit h-fit p-4 md:p-8 bg-white rounded-[16px] drop-shadow-md ">
        <img src={fadeSrc} className="max-w-[500px] h-auto" />
      </div>
    </div>
  );

  return (
    <div className="w-full h-full relative overflow-visible flex items-center justify-center max-h-[50vh] md:max-h-[70vh]">
      {animation === "fade" ? (
        <AnimatePresence>
          <div className="w-auto h-full max-w-[calc(100vw-2rem)] overflow-visible aspect-square flex items-center justify-center">
            <motion.img
              src={fadeSrc}
              variants={{
                hidden: {
                  opacity: 0,
                },
                visible: {
                  opacity: 1,
                },
              }}
              animate={visible ? "visible" : "hidden"}
              transition={{
                duration: 0.3,
                delay: 0.25,
                ease: "easeOut",
              }}
              exit={{ opacity: 0, transition: { duration: 0.25 } }}
              whileHover={{ scale: 1.025 }}
            />
          </div>
        </AnimatePresence>
      ) : (
        <AnimatePresence>
          {animation === "pantry1" && pantry1}
          {animation === "pantry2" && pantry2}
          {animation === "pantry3" && pantry3}
          {animation === "social1" && social1}
          {animation === "charity" && charity}
        </AnimatePresence>
      )}
    </div>
  );
};

export default FramerAnimationManager;
